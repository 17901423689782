import { FANBEE_LINK_STATUS } from '@epic-front/common/constants'
import { Instance, types } from 'mobx-state-tree'

export const FanbeeLinkBasic = types
  .model({
    uuid: types.maybeNull(types.string),
    shortUrl: types.maybeNull(types.string),
    longUrl: types.maybeNull(types.string),
    customUrl: types.maybeNull(types.string),
    userUuid: types.maybeNull(types.string),
    releaseUuid: types.maybeNull(types.string),
    redirectTo: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    artist: types.maybeNull(types.string),
    imageUrl: types.maybeNull(types.string),
    releaseFormat: types.maybeNull(types.string),
    status: types.maybeNull(types.enumeration(Object.values(FANBEE_LINK_STATUS))),
  })
  .actions(self => ({
    applyStatus(status: FANBEE_LINK_STATUS) {
      self.status = status
    },
  }))

export type IFanbeeLinkBasic = Instance<typeof FanbeeLinkBasic>
