import clientWrapper from '../../clientWrapper'
import { IGenericResponse } from '../../api.types'

import { SERVICE_NAME, FANBEE_SOCIAL_PLATFORMS } from '../../../constants'

export type ISocialPlatformsResponse = IGenericResponse<'socialPlatforms', FANBEE_SOCIAL_PLATFORMS[]>

export async function socialPlatforms(): Promise<ISocialPlatformsResponse> {
  const query = `query socialPlatforms {
    socialPlatforms
  }`

  return (await clientWrapper(SERVICE_NAME.USERS, query, {})) as ISocialPlatformsResponse
}
