import { Instance, types } from 'mobx-state-tree'

import { STORES } from '../../../constants'

export const StreamingLink = types.model({
  link: types.string,
  store: types.enumeration(Object.values(STORES)),
})

export type IStreamingLink = Instance<typeof StreamingLink>
