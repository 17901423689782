import { PERMISSIONS } from 'e2e-utils'
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { commonPublicRoutes } from '../commonPublicRoutes'

import { commonPrivateRoutes } from '../commonPrivateRoutes'
import { commonPublicAccountRoutes } from '../commonPublicAccountRoutes'
import { ISiteRouteList, RouteType } from '../types'

/**
 * LAZY LOAD ALL THE PAGES
 */
const DistributionEarningsPage = lazy(() => import('../../pages/release/distribution/DistributionEarningsPage'))
const MyAccountPage = lazy(() => import('../../pages/profile/MyAccountPage'))
const ListReleasesPage = lazy(() => import('../../pages/release/listRelease/ReleaseListPage'))
const ViewReleasePage = lazy(() => import('../../pages/release/viewRelease/ViewReleasePage'))
const AudioUploadToolPage = lazy(() => import('../../pages/aut/AutPage'))
const ManageAudios = lazy(() => import('../../pages/assets/audio/ManageAudiosPage'))
const Dashboard = lazy(() => import('../../pages/overview/DashboardEEPage'))
const PaymentSelect = lazy(() => import('../../pages/payments/PaymentSelect/PaymentSelectPage'))
const ChannelPage = lazy(() => import('../../pages/contentId/ChannelPage'))
const AssetListPage = lazy(() => import('../../pages/contentId/AssetListPage'))
const AssetPage = lazy(() => import('../../pages/contentId/AssetPage'))
const YoutubeEarningsPage = lazy(() => import('../../pages/contentId/YoutubeEarningsPage'))
const DistributionAnalyticsPage = lazy(() => import('../../pages/analytics/DistributionAnalyticsPage'))
const YoutubeAnalyticsPage = lazy(() => import('../../pages/analytics/YoutubeAnalyticsPage'))
const ChannelVideoListPage = lazy(() => import('../../pages/channelVideos/ChannelVideoListPage'))
const MonthlyReportPage = lazy(() => import('../../pages/payments/MonthlyReportPage'))

const RegisterInvitation = lazy(() => import('../../pages/account/RegisterInvitation'))
const TaxFormPage = lazy(() => import('../../pages/payments/TaxFormPage/TaxFormPage'))
const ClaimListClientPage = lazy(() => import('../../pages/contentId/claims/ClaimListClientPage'))
const DisputedClaimsClientPage = lazy(() => import('../../pages/contentId/claims/DisputedClaimsClientPage'))
const ChannelListPage = lazy(() => import('../../pages/contentId/ChannelListPage'))
const ManageChannelWhitelistingPage = lazy(
  () => import('../../pages/channelWhitelisting/ManageChannelWhitelistingPage')
)

const ClientManagerSelectPage = lazy(() => import('../../pages/users/ClientManager/ClientManagerSelectPage'))

/* test pages */
// const DesignPage = lazy(() => import('../../pages/design/DesignPage'))
const AudioUploadPage = lazy(() => import('../../pages/assets/audio/AudioUploadPage'))

/* test pages */
const DesignPage = lazy(() => import('../../pages/fanbee/DesignPage'))
const FanbeeWelcome = lazy(() => import('../../pages/fanbee/FanbeeWelcomePage'))
const FanbeeProfile = lazy(() => import('../../pages/fanbee/FanbeeProfilePage'))
const FanbeeLinkPage = lazy(() => import('../../pages/fanbee/FanbeeLinkPage'))
const FanbeeLinkListPage = lazy(() => import('../../pages/fanbee/FanbeeLinkListPage'))

// root routes
const routes: ISiteRouteList = {
  /* test pages */
  DESIGN: {
    url: '/design',
    name: 'Design Components',
    key: 'DESIGN',
    element: <DesignPage />,
    routeType: RouteType.AUTH_PROTECTED,
  },

  // root route
  ROOT: {
    url: '/',
    name: 'Dashboard',
    exact: true,
    element: <Navigate to="/dashboard" />,
    routeType: RouteType.AUTH_PROTECTED,
    key: 'ROOT',
  },
  REGISTER_INVITE: {
    key: 'REGISTER_INVITE',
    url: '/account/register-invite',
    name: 'Register',
    element: <RegisterInvitation />,
    routeType: RouteType.PUBLIC,
  },
  CLIENT_MANAGER_SELECT: {
    key: 'CLIENT_MANAGER_SELECT',
    url: '/client-select',
    name: 'Client Select',
    element: <ClientManagerSelectPage />,
    routeType: RouteType.AUTH_PROTECTED_BLANK,
  },
  // auth protected routes
  DISTRIBUTION_EARNINGS: {
    url: '/distribution-earnings',
    name: 'Earnings',
    key: 'DISTRIBUTION_EARNINGS',
    element: <DistributionEarningsPage />,
    permissionsAnd: [PERMISSIONS.ASSET.DISTRIBUTION_EARNING_LIST_SELF],
    routeType: RouteType.AUTH_PROTECTED,
  },
  RELEASES: {
    url: '/release/list',
    name: 'Releases',
    key: 'RELEASES',
    element: <ListReleasesPage />,
    routeType: RouteType.AUTH_PROTECTED,
    permissionsAnd: [PERMISSIONS.ASSET.LIST_SELF, PERMISSIONS.CLIENT_FEATURE.LIST_RELEASES],
  },
  AUT_CREATE: {
    url: '/aut',
    name: 'Audio Upload Tool',
    element: <AudioUploadToolPage />,
    permissionsAnd: [
      PERMISSIONS.ASSET.LIST_SELF,
      PERMISSIONS.CLIENT_FEATURE.MANAGE_RELEASES,
      PERMISSIONS.CLIENT_FEATURE.CREATE_AUDIO_RELEASES,
    ],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'AUT_CREATE',
  },
  AUT_EDIT: {
    url: '/aut/:id',
    name: 'Audio Upload Tool',
    element: <AudioUploadToolPage />,
    permissionsAnd: [
      PERMISSIONS.ASSET.LIST_SELF,
      PERMISSIONS.CLIENT_FEATURE.MANAGE_RELEASES,
      PERMISSIONS.CLIENT_FEATURE.CREATE_AUDIO_RELEASES,
    ],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'UPDATE_RELEASE',
  },

  VIEW_RELEASE: {
    url: '/release/view/:id',
    name: 'View Release',
    element: <ViewReleasePage />,
    permissionsAnd: [PERMISSIONS.ASSET.LIST_SELF, PERMISSIONS.CLIENT_FEATURE.LIST_RELEASES],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'VIEW_RELEASE',
  },
  MY_ACCOUNT: {
    url: '/account/my-account',
    name: 'My Account',
    key: 'MY_ACCOUNT',
    element: <MyAccountPage />,
    routeType: RouteType.AUTH_PROTECTED,
  },
  FANBEE_WELCOME: {
    url: '/fanbee/welcome',
    name: 'Welcome',
    key: 'FANBEE_WELCOME',
    element: <FanbeeWelcome />,
    routeType: RouteType.AUTH_PROTECTED,
    permissionsOr: [PERMISSIONS.FANBEE.LIST_SELF],
  },
  /* test pages */
  // DESIGN: {
  //   url: '/design',
  //   name: 'Design Components',
  //   key: 'DESIGN',
  //   element: <DesignPage />,
  //   routeType: RouteType.AUTH_PROTECTED,
  // },
  FANBEE_PROFILE: {
    url: '/fanbee/profile',
    name: 'Profile',
    key: 'FANBEE_PROFILE',
    element: <FanbeeProfile />,
    routeType: RouteType.AUTH_PROTECTED,
    permissionsOr: [PERMISSIONS.FANBEE.LIST_SELF],
  },
  FANBEE_RELEASES: {
    url: '/fanbee/releases',
    name: 'Links',
    key: 'FANBEE_RELEASES',
    element: <FanbeeLinkListPage />,
    routeType: RouteType.AUTH_PROTECTED,
    permissionsOr: [PERMISSIONS.FANBEE.LIST_SELF],
  },
  FANBEE_RELEASE: {
    url: '/fanbee/release/:id',
    name: 'Fanbee Release',
    key: 'FANBEE_RELEASE',
    element: <FanbeeLinkPage />,
    routeType: RouteType.AUTH_PROTECTED,
    permissionsOr: [PERMISSIONS.FANBEE.LIST_SELF],
  },

  DASHBOARD: {
    key: 'DASHBOARD',
    url: '/dashboard',
    name: 'Dashboard',
    element: <Dashboard />,
    routeType: RouteType.AUTH_PROTECTED,
  },
  CHANNEL: {
    key: 'CHANNEL',
    url: '/channel/:id',
    name: 'Channel',
    element: <ChannelPage />,
    permissionsOr: [PERMISSIONS.CHANNEL.LIST_SELF],
    routeType: RouteType.AUTH_PROTECTED,
  },

  ASSETS_CONTENT_ID: {
    key: 'ASSETS_CONTENT_ID',
    url: '/content-id/assets',
    name: 'Assets',
    element: <AssetListPage />,
    permissionsOr: [PERMISSIONS.ASSET.LIST_SELF, PERMISSIONS.ASSET.MANAGE_SELF],
    routeType: RouteType.AUTH_PROTECTED,
  },
  ASSET_CONTENT_ID: {
    key: 'ASSETS_CONTENT_ID',
    url: '/content-id/asset/:id',
    name: 'Asset',
    element: <AssetPage />,
    permissionsOr: [PERMISSIONS.ASSET.LIST_SELF, PERMISSIONS.ASSET.MANAGE_SELF],
    routeType: RouteType.AUTH_PROTECTED,
  },
  AUDIO_UPLOAD_CONTENT_ID: {
    key: 'AUDIO_UPLOAD',
    url: '/content-id/add',
    name: 'Add Assets',
    element: <AudioUploadPage />,
    permissionsOr: [PERMISSIONS.ASSET.LIST_SELF, PERMISSIONS.ASSET.MANAGE_SELF],
    routeType: RouteType.AUTH_PROTECTED,
  },
  AUDIO_LIST: {
    key: 'AUDIO_LIST',
    url: '/content-id/manage-added-assets',
    name: 'Manage Added Assets',
    element: <ManageAudios />,
    routeType: RouteType.AUTH_PROTECTED,
  },
  CHANNEL_LIST: {
    url: '/channels',
    name: 'Channels',
    element: <ChannelListPage />,
    permissionsAnd: [PERMISSIONS.CHANNEL.LIST_SELF],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'CHANNEL_LIST',
  },
  ANALYTICS_CONTENT_ID: {
    key: 'ANALYTICS_CONTENT_ID',
    url: '/content-id/analytics',
    name: 'Analytics',
    element: <YoutubeAnalyticsPage />,
    permissionsAnd: [PERMISSIONS.ASSET.LIST_SELF],
    routeType: RouteType.AUTH_PROTECTED,
  },
  CLAIMS_CONTENT_ID: {
    key: 'CLAIMS_CONTENT_ID',
    url: '/content-id/claims',
    name: 'Claims',
    element: <ClaimListClientPage />,
    permissionsOr: [PERMISSIONS.CLAIM.LIST_SELF, PERMISSIONS.CLAIM.RELEASE_SELF],
    routeType: RouteType.AUTH_PROTECTED,
  },
  EARNINGS_CONTENT_ID: {
    key: 'EARNINGS_CONTENT_ID',
    url: '/content-id/earnings',
    name: 'Earnings',
    element: <YoutubeEarningsPage />,
    routeType: RouteType.AUTH_PROTECTED,
  },

  DISPUTED_CLAIMS_CONTENT_ID: {
    url: '/content-id/disputed-claims',
    name: 'Disputed Claims',
    element: <DisputedClaimsClientPage />,
    permissionsAnd: [PERMISSIONS.CLAIM.LIST_SELF, PERMISSIONS.CLAIM.RELEASE_SELF],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'DISPUTED_CLAIMS_CONTENT_ID',
  },
  VIDEOS: {
    key: 'VIDEOS',
    url: '/channel-videos/',
    name: 'Channel Videos',
    element: <ChannelVideoListPage />,
    permissionsOr: [PERMISSIONS.ASSET.LIST_SELF, PERMISSIONS.ASSET.MANAGE_SELF],
    routeType: RouteType.AUTH_PROTECTED,
  },
  MONTHLY_REPORT: {
    key: 'MONTHLY_REPORT',
    url: '/payments/monthly-report',
    name: 'Payment Reports',
    element: <MonthlyReportPage />,
    permissionsOr: [PERMISSIONS.EARNING.REPORT_EXPORT, PERMISSIONS.EARNING.LIST_SELF],
    routeType: RouteType.AUTH_PROTECTED,
  },
  CHANNEL_WHITELISTING: {
    key: 'CHANNEL_WHITELISTING',
    url: '/channel-whitelisting',
    name: 'Channel Whitelisting',
    element: <ManageChannelWhitelistingPage />,
    permissionsOr: [PERMISSIONS.CHANNEL_WHITELISTING.LIST_SELF, PERMISSIONS.CHANNEL_WHITELISTING.MANAGE_SELF],
    routeType: RouteType.AUTH_PROTECTED,
  },
  ANALYTICS_DISTRIBUTION: {
    key: 'ANALYTICS_DISTRIBUTION',
    url: '/distribution-analytics',
    name: 'Analytics',
    element: <DistributionAnalyticsPage />,
    permissionsAnd: [PERMISSIONS.CLIENT_FEATURE.LIST_RELEASES],
    routeType: RouteType.AUTH_PROTECTED,
  },

  PAYMENT_SELECT: {
    key: 'PAYMENT_SELECT',
    url: '/payment-select',
    name: 'Payment Select',
    element: <PaymentSelect />,
    routeType: RouteType.AUTH_PROTECTED,
  },
  TAX_FORMS: {
    key: 'TAX_FORMS',
    url: '/tax-forms',
    name: 'Payment Setup',
    element: <TaxFormPage />,
    routeType: RouteType.AUTH_PROTECTED,
  },
}

export const SONGMATE_ROUTES = {
  ...commonPublicRoutes,
  ...routes,
  ...commonPrivateRoutes(routes),
  ...commonPublicAccountRoutes,
}
