import { FANBEE_LINK_STATUS, SERVICE_NAME } from '../../../../constants'
import { IFanbeeLinkBasic } from '../../../../models'
import { IGenericResponse } from '../../../api.types'
import clientWrapper from '../../../clientWrapper'
import { fanbeeLinkFragment } from '../../fragmentsAssets'

export type IUpdateFanbeeLinkStatusResponse = IGenericResponse<'updateFanbeeLinkStatus', IFanbeeLinkBasic>

export type IUpdateFanbeeLinkStatusProps = {
  uuid: string
  status: FANBEE_LINK_STATUS
}

export async function updateFanbeeLinkStatus(
  input: IUpdateFanbeeLinkStatusProps,
  fragment: string = fanbeeLinkFragment
): Promise<IUpdateFanbeeLinkStatusResponse> {
  const query = `
    mutation updateFanbeeLinkStatus($input: FanbeeLinkUpdateStatusInput!) {
      updateFanbeeLinkStatus(input: $input){
        ${fragment}
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, { input })) as IUpdateFanbeeLinkStatusResponse
}
