import axios from 'axios'
import moment from 'moment'
import { toast } from 'react-toastify'
import { URL_ASSETS_API } from '@epic-front/common/constants'
import { downloadCSV, getLocalToken } from '../../../helpers/utils'
import { IGetClaimsProps } from '../contentId/getClaims'

const downloadClaimsCSV = async (claimsProps: IGetClaimsProps, title?: string): Promise<boolean> => {
  const download = async () => {
    try {
      const token = getLocalToken()
      const SITE_TOKEN = process.env.REACT_APP_SITE_TOKEN

      const res = await axios({
        url: `${URL_ASSETS_API}/claimsIssuesList/csv`,
        method: 'get',
        headers: { sitetoken: SITE_TOKEN || '', authorization: token || undefined },
        params: { ...claimsProps.filters },
      })

      const start = claimsProps.filters?.from
        ? moment(claimsProps.filters?.from).startOf('month').format('YYYY-MM')
        : ''
      const end = claimsProps.filters?.to ? moment(claimsProps.filters?.to).startOf('month').format('YYYY-MM') : ''

      const filename = `${title ?? 'Claims'}: ${start !== end ? `${start} - ${end}` : `${start}`}`

      downloadCSV(res.data, filename)
    } catch (err) {
      toast.error('There was a problem while exporting Disputed Claims list.')
    }

    return true
  }

  const response = await download()

  return response
}

export default downloadClaimsCSV
