import { SERVICE_NAME } from '../../../constants'
import { IFanbeeUrl } from '../../../models/fanbee/FanbeeUrl.model'

import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type IDeleteFanbeeUrlResponse = IGenericResponse<'deleteFanbeeUrl', IFanbeeUrl>

export async function deleteFanbeeUrl(urlUuid: string): Promise<IDeleteFanbeeUrlResponse> {
  const query = `
    mutation DeleteFanbeeUrl($urlUuid: String!) {
      deleteFanbeeUrl(urlUuid: $urlUuid) {
        uuid
        url
        userUuid
        platformSlug
      }
    }
  `
  return clientWrapper(SERVICE_NAME.USERS, query, { urlUuid }) as Promise<IDeleteFanbeeUrlResponse>
}
