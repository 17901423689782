import { Instance, cast, flow, types } from 'mobx-state-tree'
import { toast } from 'react-toastify'
import { assignClaimManager } from '../../api/assets-api/contentId/claimManager/assignClaimManager'
import {
  IDeleteClaimManagerResponse,
  deleteClaimManager,
} from '../../api/assets-api/contentId/claimManager/deleteClaimManager'
import {
  IGetClaimManagersResponse,
  getClaimManagers,
} from '../../api/assets-api/contentId/claimManager/getClaimManagers'
import { setDefaultClaimManager } from '../../api/assets-api/contentId/claimManager/setDefaultClaimManager'
import { ClaimManager } from './ClaimManager.model'

export const ClaimManagerList = types
  .model({
    list: types.array(ClaimManager),
  })
  .volatile(() => ({
    loading: false,
  }))
  .actions(self => ({
    getByClientUuid(uuid: string | null | undefined) {
      if (uuid) {
        return self.list.find(user => user.userUuid === uuid)
      }
      return null
    },
    getDefaultClaimManager() {
      return self.list.find(item => item.default === true)
    },
  }))
  .actions(self => ({
    load: flow(function* () {
      try {
        self.loading = true
        const resp: IGetClaimManagersResponse = yield getClaimManagers()

        if (resp && resp.data.data && resp.data.data.claimManagers) {
          self.list = cast(resp.data.data.claimManagers)
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),
  }))
  .actions(self => ({
    deleteClaimManager: flow(function* ({ uuid }: { uuid: string }) {
      try {
        self.loading = true
        const response: IDeleteClaimManagerResponse = yield deleteClaimManager({ uuid })

        if (response?.data.data?.deleteClaimManager) {
          toast.success('The claims manager has been successfully deleted!')
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),
  }))
  .actions(self => ({
    setDefaultClaimManager: flow(function* ({ managerUuid }: { managerUuid: string }) {
      try {
        self.loading = true
        yield setDefaultClaimManager({ managerUuid })

        toast.success('The default claims manager has been set successfully!')
      } catch (err) {
        console.error(err)
        toast.error('There was an error while trying to set the default claim manager')
      } finally {
        self.loading = false
      }
    }),
  }))
  .actions(self => ({
    assignClaimManager: flow(function* ({ managerUuid, userUuids }: { managerUuid: string; userUuids: string[] }) {
      try {
        self.loading = true
        yield assignClaimManager({ managerUuid, userUuids })

        toast.success('Claims manager successfully assigned!')
      } catch (err) {
        console.error(err)
        toast.error('There was an error while trying to set the Claim Managers')
      } finally {
        self.loading = false
      }
    }),
  }))

export type IClaimManagerList = Instance<typeof ClaimManagerList>
