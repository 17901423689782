import clientWrapper from '../../clientWrapper'
import { IGenericResponse } from '../../api.types'
import { SERVICE_NAME } from '../../../constants'

import { IPaymentMethod } from '../../../models/payment/PaymentMethod.model'

import { paymentMethodBasicFragment } from './fragments'

export type IEditPaymentMethodThresholdProps = {
  paymentMethodUuid: string
  inputThreshold: number
}

export type IEditPaymentMethodThresholdResponse = IGenericResponse<'editPaymentMethodThreshold', IPaymentMethod>

export async function editPaymentMethodThreshold(
  props: IEditPaymentMethodThresholdProps,
  fragment: string = paymentMethodBasicFragment
): Promise<IEditPaymentMethodThresholdResponse> {
  const query = `
  mutation editPaymentMethodThreshold($paymentMethodUuid: String!, $inputThreshold: Float!) {
    editPaymentMethodThreshold(
        paymentMethodUuid: $paymentMethodUuid
        inputThreshold: $inputThreshold
      ) {
        ${fragment}
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.PAYMENTS, query, props)) as IEditPaymentMethodThresholdResponse
}
