import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

const loading = () => <div />

const DefaultLayout = (): JSX.Element => {
  return (
    <>
      <Suspense fallback={loading()}>
        <Outlet />
      </Suspense>
    </>
  )
}
export default DefaultLayout
