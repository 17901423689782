import { Instance, cast, clone, flow, types } from 'mobx-state-tree'
import { ClaimManagerTable, IClaimManagerTable } from './ClaimManagerTable.model'
import {
  IGetClaimManagersResponse,
  getClaimManagers,
} from '../../api/assets-api/contentId/claimManager/getClaimManagers'
import { Pagination } from '../general/Pagination.model'
import { getClaimsIssuesCount, IGetClaimsIssuesCountResponse } from '../../api/assets-api/getClaimsIssuesCount'
import { SortDirection } from '../../constants'
import { IUser } from '../users/User.model'
import { IClaimManager } from './ClaimManager.model'

export const ClaimManagerTableList = types
  .model({
    list: types.array(ClaimManagerTable),
  })
  .volatile(() => ({
    loading: false,
    pagination: Pagination.create({ totalItems: 0 }),
    managerUuidFilter: '',
    assignedManagerFilter: null as boolean | null,
    multifieldFilter: '',
    sitesFilter: [] as string[],
  }))
  .views(self => ({
    get filtered() {
      const multifieldFilter = (item: IClaimManagerTable) => {
        if (!self.multifieldFilter) return true

        return (
          item.user?.uuid.toLowerCase().includes(self.multifieldFilter) ||
          item.user?.firstLastName.toLowerCase().includes(self.multifieldFilter) ||
          (item.user?.displayName ? item.user?.displayName.toLowerCase().includes(self.multifieldFilter) : false) ||
          (item.user?.email ? item.user?.email.toLowerCase().includes(self.multifieldFilter) : false) // ||
        )
      }

      const sitesFilter = (item: IClaimManagerTable) => {
        if (self.sitesFilter.length === 0) return true

        return self.sitesFilter.some(siteFilter => item.user?.userSites.some(site => site.siteUuid === siteFilter))
      }

      const managerUuidFilter = (item: IClaimManagerTable) => {
        if (self.managerUuidFilter.length === 0) return true

        return item.claimManager?.managerUuid === self.managerUuidFilter
      }

      const managerAssignedStatusFilter = (item: IClaimManagerTable) => {
        if (self.assignedManagerFilter === null) return true

        if (self.assignedManagerFilter === false) return Boolean(!item.claimManager)

        return Boolean(item.claimManager)
      }

      const filteredUsers = self.list
        .filter(multifieldFilter)
        .filter(sitesFilter)
        .filter(managerUuidFilter)
        .filter(managerAssignedStatusFilter)

      // sort
      if (self.pagination.sort) {
        filteredUsers.sort((a, b) => {
          if (self.pagination.sort === 'firstLastName') {
            if ((a.user?.firstLastName.toLowerCase() || '') < (b.user?.firstLastName.toLowerCase() || '')) {
              return self.pagination.sortDirection === SortDirection.DESC ? -1 : 1
            }
            if ((a.user?.firstLastName.toLowerCase() || '') > (b.user?.firstLastName.toLowerCase() || '')) {
              return self.pagination.sortDirection === SortDirection.DESC ? 1 : -1
            }
          }

          if (self.pagination.sort === 'displayName') {
            if ((a.user?.displayName?.toLowerCase() || '') < (b.user?.displayName?.toLowerCase() || '')) {
              return self.pagination.sortDirection === SortDirection.DESC ? -1 : 1
            }
            if ((a.user?.displayName?.toLowerCase() || '') > (b.user?.displayName?.toLowerCase() || '')) {
              return self.pagination.sortDirection === SortDirection.DESC ? 1 : -1
            }
          }

          if (self.pagination.sort === 'claimManager') {
            if (
              (a.claimManager?.managerUuid?.toLowerCase() || '') < (b.claimManager?.managerUuid?.toLowerCase() || '')
            ) {
              return self.pagination.sortDirection === SortDirection.DESC ? -1 : 1
            }
            if (
              (a.claimManager?.managerUuid?.toLowerCase() || '') > (b.claimManager?.managerUuid?.toLowerCase() || '')
            ) {
              return self.pagination.sortDirection === SortDirection.DESC ? 1 : -1
            }
          }

          if (self.pagination.sort === 'appealedClaims') {
            if (self.pagination.sortDirection === SortDirection.ASC) {
              return (a.claimCount?.appealedClaims ?? 0) - (b.claimCount?.appealedClaims ?? 0)
            }

            return (b.claimCount?.appealedClaims ?? 0) - (a.claimCount?.appealedClaims ?? 0)
          }

          if (self.pagination.sort === 'disputedClaims') {
            if (self.pagination.sortDirection === SortDirection.ASC) {
              return (a.claimCount?.disputedClaims ?? 0) - (b.claimCount?.disputedClaims ?? 0)
            }

            return (b.claimCount?.disputedClaims ?? 0) - (a.claimCount?.disputedClaims ?? 0)
          }

          if (self.pagination.sort === 'potentialClaims') {
            if (self.pagination.sortDirection === SortDirection.ASC) {
              return (a.claimCount?.potentialClaims ?? 0) - (b.claimCount?.potentialClaims ?? 0)
            }

            return (b.claimCount?.potentialClaims ?? 0) - (a.claimCount?.potentialClaims ?? 0)
          }

          return 0
        })
      }

      return filteredUsers
    },
  }))
  .views(self => ({
    get filteredCount() {
      return self.filtered.length
    },
    get pagedFiltered() {
      return self.filtered.slice(
        (self.pagination.page - 1) * self.pagination.perPage,
        self.pagination.page * self.pagination.perPage
      )
    },
  }))
  .actions(self => ({
    setManagerUuidFilter(uuids: string) {
      self.managerUuidFilter = uuids
      self.pagination.setTotalItems(self.filteredCount)
      self.pagination.setPage(1)
    },
    setAssignedManagerFilter(isAssigned: boolean | null) {
      self.assignedManagerFilter = isAssigned
      self.pagination.setTotalItems(self.filteredCount)
      self.pagination.setPage(1)
    },
    setMultifieldFilter(text: string) {
      self.multifieldFilter = text.toLowerCase()
      self.pagination.setTotalItems(self.filteredCount)
      self.pagination.setPage(1)
    },
    setSitesFilter(sites: string[]) {
      self.sitesFilter = sites
      self.pagination.setTotalItems(self.filteredCount)
      self.pagination.setPage(1)
    },
  }))
  .actions(self => ({
    setUsers: (users: IUser[]) => {
      const userList: IClaimManagerTable[] = users.map(user => ({
        userUuid: user.uuid,
        user: clone(user),
        claimManager: null,
        claimCount: null,
      }))

      self.list = cast(userList)
      self.pagination.setTotalItems(self.list.length)
    },
    setClaimManagers: (claimManagers: IClaimManager[]) => {
      self.list.slice().forEach((claimManager, index) => {
        const claimManagerData = claimManagers.find(
          claimManagerInstance => claimManagerInstance.userUuid === claimManager.userUuid
        )

        self.list[index].claimManager = claimManagerData ? clone(claimManagerData) : null
      })
    },
  }))

  .actions(self => ({
    loadClaimManagers: flow(function* () {
      try {
        self.loading = true
        const resp: IGetClaimManagersResponse = yield getClaimManagers()

        if (resp && resp.data.data && resp.data.data.claimManagers) {
          const claimManagersList = resp.data.data.claimManagers

          self.list.slice().forEach((claimManager, index) => {
            const claimManagerData = claimManagersList.find(
              claimManagerInstance => claimManagerInstance.userUuid === claimManager.userUuid
            )

            self.list[index].claimManager = claimManagerData ?? null
          })
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),
    loadClaimsCount: flow(function* () {
      try {
        self.loading = true
        const resp: IGetClaimsIssuesCountResponse = yield getClaimsIssuesCount()

        if (resp && resp.data.data && resp.data.data.claimsIssuesCount) {
          const claimsIssuesCount = resp.data.data.claimsIssuesCount

          self.list.slice().forEach((claimManager, index) => {
            const claimsIssuesCountData = claimsIssuesCount.find(
              claimIssueCount => claimIssueCount.userUuid === claimManager.userUuid
            )

            self.list[index].claimCount = claimsIssuesCountData ?? null
          })
        }
      } catch (e) {
        console.error(e)
      } finally {
        self.loading = false
      }
    }),
  }))

export type IClaimManagerTableList = Instance<typeof ClaimManagerTableList>
