import { useEffect, useState } from 'react'
import { Button, Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { observer } from 'mobx-react-lite'
import { Trash } from '@vectopus/atlas-icons-react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import Select from 'react-select'

import { FANBEE_DOMAIN, FANBEE_LINK_STATUS, RELEASE_FORMATS } from '@epic-front/common'
import { FanbeeLinkList, IFanbeeLinkBasic, IFanbeeLinkList } from '@epic-front/common/models'
import { DeleteModal, ITableColumn, Modal, Table, TableContentType } from '@blocks'
import { getReleaseTypeIcon } from '@helpers'
import WidgetTitle from '../titles/WidgetTitle'

const FanbeeUrlsTable = ({ userUuid }: { userUuid: string | null }): JSX.Element => {
  const [links] = useState<IFanbeeLinkList>(FanbeeLinkList.create())

  const [selectedLinkDeleteModal, setSelectedLinkDeleteModal] = useState<IFanbeeLinkBasic | null>(null)
  const [selectedUpdateStatusModal, setSelectedUpdateStatusModal] = useState<IFanbeeLinkBasic | null>(null)

  useEffect(() => {
    if (!userUuid) return

    if (!links.releaseFormatFilter) links.setReleaseFormatFilter(RELEASE_FORMATS.ALBUM)
    links.setUserUuidFilter(userUuid)
    links.load()
  }, [userUuid, links.pagination.page, links.pagination.perPage])

  const handleRemoveFanbeeLink = async () => {
    await links.removeLink(selectedLinkDeleteModal?.uuid ?? '')

    setSelectedLinkDeleteModal(null)
  }

  const handleUpdateFanbeeLinkStatus = async () => {
    if (!selectedUpdateStatusModal?.uuid) return

    await links.updateFanbeeLinkStatus({
      uuid: selectedUpdateStatusModal?.uuid,
      status:
        selectedUpdateStatusModal?.status === FANBEE_LINK_STATUS.ACTIVE
          ? FANBEE_LINK_STATUS.INACTIVE
          : FANBEE_LINK_STATUS.ACTIVE,
    })

    setSelectedUpdateStatusModal(null)
  }

  const handleOnDrop = (rowUuid: string, newPosition: number) => {
    links.updateFanbeeLinkOrder(rowUuid, newPosition, links.releaseFormatFilter as RELEASE_FORMATS, userUuid ?? '')
  }

  const columns: ITableColumn<IFanbeeLinkBasic>[] = [
    {
      Header: 'Uuid',
      accessor: 'uuid',
      cellClasses: 'text-nowrap',
      hidden: true,
    },
    {
      Header: 'Release Title & Artist',
      accessor: 'title',
      cellClasses: 'mincol',
      Cell: row => (
        <TableContentType
          type="release"
          title={row.title}
          subtitle={row.artist}
          imgUrl={row.imageUrl || undefined}
          href={`/fanbee/release/${row.releaseUuid}`}
        />
      ),
    },
    {
      Header: 'Release type',
      accessor: 'releaseFormat',
      headerCellClasses: 'text-nowrap',
      cellClasses: 'mincol text-center',
      Cell: row => (
        <>
          {row.releaseFormat && (
            <>
              <div className="svg_image px-2 mx-auto mb-1">
                <img src={getReleaseTypeIcon(row.releaseFormat)} alt={row.releaseFormat} width={40} />
              </div>
              <h4 className="small text-uppercase m-0 text-gray-500">{row.releaseFormat}</h4>
            </>
          )}
        </>
      ),
    },
    {
      Header: 'URLs',
      accessor: '-',
      headerCellClasses: 'text-nowrap text-start',
      cellClasses: 'text-start mincol',
      Cell: row => (
        <div className="d-flex gap-1 flex-column">
          <OverlayTrigger placement="top" overlay={<Tooltip id={`short-url-${row.shortUrl}`}>Short URL</Tooltip>}>
            <Link target="_blank" className="btn btn-sm btn-light" to={`${FANBEE_DOMAIN}/${row.shortUrl}`}>
              {FANBEE_DOMAIN}/{row.shortUrl}
              <i className="uil uil-arrow-up-right" />
            </Link>
          </OverlayTrigger>

          {row.longUrl && row.longUrl !== '-' && (
            <OverlayTrigger placement="top" overlay={<Tooltip id={`long-url-${row.longUrl}`}>Long URL</Tooltip>}>
              <Link target="_blank" className="btn btn-sm btn-light" to={`${FANBEE_DOMAIN}/${row.longUrl}`}>
                {FANBEE_DOMAIN}/{row.longUrl}
                <i className="uil uil-arrow-up-right" />
              </Link>
            </OverlayTrigger>
          )}

          {row.customUrl && (
            <OverlayTrigger placement="top" overlay={<Tooltip id={`custom-url-${row.longUrl}`}>Custom URL</Tooltip>}>
              <Link target="_blank" className="btn btn-sm btn-light" to={`${FANBEE_DOMAIN}/${row.customUrl}`}>
                {FANBEE_DOMAIN}/{row.customUrl}
                <i className="uil uil-arrow-up-right" />
              </Link>
            </OverlayTrigger>
          )}
        </div>
      ),
    },
    {
      Header: 'Actions',
      accessor: 'url',
      cellClasses: 'actions-column',
      headerCellClasses: 'text-nowrap actions-column text-end',
      Cell: row => (
        <div className="d-flex justify-content-end gap-1">
          <Link to={`/fanbee/release/${row.releaseUuid}`} type="button" className="btn btn-primary">
            Show Link Details
          </Link>

          <OverlayTrigger placement="top" overlay={<Tooltip id={`edit-role-${row.uuid}`}>Toggle Status</Tooltip>}>
            <span className="d-inline-block">
              <button
                type="button"
                className={classNames('btn', row.status === FANBEE_LINK_STATUS.ACTIVE ? 'btn-success' : 'btn-light')}
                onClick={() => {
                  setSelectedUpdateStatusModal(row)
                }}
              >
                <i
                  className={classNames(row.status === FANBEE_LINK_STATUS.ACTIVE ? 'uil-toggle-on' : 'uil-toggle-off')}
                />
              </button>
            </span>
          </OverlayTrigger>

          <OverlayTrigger placement="top" overlay={<Tooltip id={`delete-link-${row.uuid}`}>Delete Link</Tooltip>}>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                setSelectedLinkDeleteModal(row)
              }}
            >
              <Trash />
            </button>
          </OverlayTrigger>
        </div>
      ),
    },
  ]

  return (
    <>
      <Card>
        <Card.Header>
          <WidgetTitle title="Fanbee Links" reverseOrder />
        </Card.Header>
        <Card.Body className="p-0">
          <Row className="g-2 p-3">
            <Col xs="12" lg="4">
              <Select
                classNamePrefix="react-select"
                isDisabled={links.loading}
                placeholder="Select Release Type"
                value={
                  links.releaseFormatFilter
                    ? { label: links.releaseFormatFilter, value: links.releaseFormatFilter }
                    : undefined
                }
                onChange={input => {
                  links.setReleaseFormatFilter(input ? input.value : '')
                  links.pagination.setPage(1)
                  links.load()
                }}
                options={Object.values(RELEASE_FORMATS).map(format => ({ label: format, value: format }))}
              />
            </Col>
          </Row>
          <Table<IFanbeeLinkBasic>
            tableHeadClasses="table-dark"
            columns={columns}
            tableClasses="table-striped"
            data={links.list}
            isLoading={links.loading}
            rowIdentifier={row => row.uuid || ''}
            isPaginated
            externalPagination={links.pagination}
            hasAdvancedView
            showRowNumber
            isDraggable
            handleOnDrop={handleOnDrop}
          />
        </Card.Body>

        {/* Modal: Delete Link */}
        <DeleteModal
          title="Delete Fanbee Link(s)"
          open={!!selectedLinkDeleteModal}
          closeModal={() => {
            setSelectedLinkDeleteModal(null)
          }}
          submit={handleRemoveFanbeeLink}
          disabled={links.loading}
        >
          <p>
            Are you sure you want to delete the following link(s) for <b>{selectedLinkDeleteModal?.title ?? ''}</b>?
          </p>
        </DeleteModal>

        {/* Update Fanbee Link Status Modal */}
        <Modal
          onHide={() => {
            setSelectedUpdateStatusModal(null)
          }}
          title="Update Status"
          isOpen={!!selectedUpdateStatusModal}
          size="lg"
          buttons={
            <>
              <Button
                variant="gray"
                onClick={() => {
                  setSelectedUpdateStatusModal(null)
                }}
              >
                <i className="uil-times" />
                Cancel
              </Button>
              <Button variant="primary" disabled={links.loading} onClick={handleUpdateFanbeeLinkStatus}>
                <i className="uil-check" />
                Update
              </Button>
            </>
          }
        >
          <>
            {' '}
            Are you sure you want to update the status of Fanbee Link {selectedUpdateStatusModal?.title ?? ''} to{' '}
            {selectedUpdateStatusModal?.status === FANBEE_LINK_STATUS.ACTIVE
              ? FANBEE_LINK_STATUS.INACTIVE
              : FANBEE_LINK_STATUS.ACTIVE}{' '}
            ?
          </>
        </Modal>
      </Card>
    </>
  )
}

export default observer(FanbeeUrlsTable)
