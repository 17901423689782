import { useEffect, useState } from 'react'
import { ERROR_BOUNDARY_REFRESH_TIMEOUT } from '@epic-front/common'
import { getSiteLogoLight } from '../../helpers/images'

export const PAGE_FORCE_REFRESH_KEY = 'page-force-refreshed-timestamp'

const ErrorBoundaryElement = (): JSX.Element => {
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    // get lastForcedRefreshTimestamp from local storage and compare with current time  and if it is more than 5 minutes then refresh the page
    const lastForcedRefreshTimestamp = JSON.parse(window.localStorage.getItem(PAGE_FORCE_REFRESH_KEY) || 'null') as
      | number
      | null
    // if lastForcedRefreshTimestamp is null or more than 5 minutes then refresh the page and set the new timestamp
    if (
      !lastForcedRefreshTimestamp ||
      new Date().getTime() - lastForcedRefreshTimestamp > ERROR_BOUNDARY_REFRESH_TIMEOUT
    ) {
      window.localStorage.setItem(PAGE_FORCE_REFRESH_KEY, new Date().getTime().toString())
      window.location.reload()
    } else {
      setShowError(true)
    }
  }, [])

  if (!showError) {
    return <></>
  }

  return (
    <>
      <div
        className="d-flex align-items-center flex-column justify-content-center vh-100 position-relative"
        style={{ zIndex: 2 }}
      >
        <img src={getSiteLogoLight()} alt="" height="112" />

        <div className="text-center text-white my-3">
          <h1 className="fw-bold display-3 mb-3">Oops, something went wrong! </h1>
          <p className="lead display-6">Try again later.</p>
          <a className="btn btn-primary btn-lg mt-3" href="/">
            Return Home
          </a>
        </div>
      </div>
      <div className="login-background">
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
    </>
  )
}

export default ErrorBoundaryElement
