import {
  ADMIN,
  ELITE_ALLIANCE,
  ELITE_ALLIANCE_MUSIC,
  EPIC_ELITE,
  PLATFORM,
  SONGMATE,
  SONGPITCH,
} from '@epic-front/common/constants'
import { observer } from 'mobx-react-lite'
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'

import DefaultLayout from '../layouts/DefaultLayout'
import VerticalLayout from '../layouts/VerticalLayout'
import ErrorPageNotFound from '../pages/errors/ErrorPageNotFound'

import { AccountLayout } from '../layouts'
import ErrorBoundaryElement from '../pages/errors/ErrorBoundaryElement'
import PrivateRoute from './PrivateRoute'
import { ADMIN_ROUTES } from './platformRoutes/adminRoutes'
import { ELITE_ALLIANCE_MUSIC_ROUTES } from './platformRoutes/eliteAllianceMusicRoutes'
import { ELITE_ALLIANCE_ROUTES } from './platformRoutes/eliteAllianceRoutes'
import { EPIC_ELITE_ROUTES } from './platformRoutes/epicEliteRoutes'
import { SONGMATE_ROUTES } from './platformRoutes/songmateRoutes'
import { SONGPITCH_ROUTES } from './platformRoutes/songpitchRoutes'
import { ISiteRouteList, RouteType } from './types'

let routesHolder: ISiteRouteList = {}

switch (PLATFORM) {
  case ADMIN:
    routesHolder = { ...ADMIN_ROUTES }
    break
  case SONGMATE:
    routesHolder = { ...SONGMATE_ROUTES }
    break
  case EPIC_ELITE:
    routesHolder = { ...EPIC_ELITE_ROUTES }
    break
  case ELITE_ALLIANCE:
    routesHolder = { ...ELITE_ALLIANCE_ROUTES }
    break
  case ELITE_ALLIANCE_MUSIC:
    routesHolder = { ...ELITE_ALLIANCE_MUSIC_ROUTES }
    break
  case SONGPITCH:
    routesHolder = { ...SONGPITCH_ROUTES }
    break
  default:
    break
}

const publicAccountRoutes = Object.values(routesHolder).filter(r => r.routeType === RouteType.PUBLIC_ACCOUNT_PAGES)
const publicRoutes = Object.values(routesHolder).filter(r => r.routeType === RouteType.PUBLIC)
const privateRoutes = Object.values(routesHolder).filter(r => r.routeType === RouteType.AUTH_PROTECTED)
const privateBlankRoutes = Object.values(routesHolder).filter(r => r.routeType === RouteType.AUTH_PROTECTED_BLANK)
const rootPage = privateRoutes.filter(r => r.key === 'ROOT_HOME' || r.key === 'ERROR_404_AUTH')
const otherPrivateRoutes = privateRoutes.filter(r => r.key !== 'ROOT_HOME' && r.key !== 'ERROR_404_AUTH')

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route key={RouteType.PUBLIC_ACCOUNT_PAGES} errorElement={<ErrorBoundaryElement />} element={<AccountLayout />}>
        {publicAccountRoutes.map(route => (
          <Route key={route.url} path={route.url} element={route.element} />
        ))}
      </Route>

      <Route key={RouteType.PUBLIC} errorElement={<ErrorBoundaryElement />} element={<DefaultLayout />}>
        {publicRoutes.map(route => (
          <Route key={route.url} path={route.url} element={route.element} />
        ))}
      </Route>

      <Route key={RouteType.AUTH_PROTECTED_BLANK} errorElement={<ErrorBoundaryElement />} element={<AccountLayout />}>
        {privateBlankRoutes.map(route => (
          <Route
            key={route.key}
            errorElement={<ErrorBoundaryElement />}
            element={<PrivateRoute shouldHaveRoute={route?.shouldHaveRoute || undefined} />}
          >
            <Route key={route.url} path={route.url} element={route.element} />
          </Route>
        ))}
      </Route>

      <Route key={RouteType.AUTH_PROTECTED} errorElement={<ErrorBoundaryElement />} element={<VerticalLayout />}>
        {rootPage.map(route => (
          <Route key={route.url} path={route.url} element={route.element} />
        ))}

        {otherPrivateRoutes.map(route => (
          <Route
            key={route.key}
            errorElement={<ErrorBoundaryElement />}
            element={<PrivateRoute shouldHaveRoute={route?.shouldHaveRoute || undefined} />}
          >
            <Route key={route.url} path={route.url} element={route.element} />
          </Route>
        ))}
      </Route>

      <Route path="*" element={<ErrorPageNotFound />} errorElement={<ErrorBoundaryElement />} />
    </>
  )
)

const Routes = (): JSX.Element => {
  return <RouterProvider router={router} />
}

export default observer(Routes)
