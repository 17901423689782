import { Instance, types } from 'mobx-state-tree'

export const PaymentMethod = types.model('paymentMethod', {
  uuid: types.identifier,
  name: types.string,
  slug: types.string,
  threshold: types.number,
})

export type IPaymentMethod = Instance<typeof PaymentMethod>
