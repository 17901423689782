import { IUser } from '@epic-front/common/models/users/User.model'
import { ReactNode } from 'react'

export enum RouteType {
  'PUBLIC',
  'AUTH_PROTECTED',
  'AUTH_PROTECTED_BLANK',
  'PUBLIC_ACCOUNT_PAGES',
}
export interface IRoute {
  url: string
  name: string
  icon?: string
  header?: string
  exact?: boolean
  element: ReactNode
  permissionsAnd?: string[]
  permissionsOr?: string[]
  shouldHaveRoute?: (user?: IUser) => boolean
  key: string
  routeType?: RouteType
}

export interface ISiteRouteList {
  [key: string]: IRoute
}
