import { manageBodyClass } from '@epic-front/common/helpers/utils'
import { Instance, types } from 'mobx-state-tree'

export const Layout = types
  .model({
    showRightSidebar: false,
  })

  .actions(self => ({
    toggleRightSidebar(shouldShow?: boolean) {
      if (typeof shouldShow !== 'undefined') {
        self.showRightSidebar = shouldShow
        if (shouldShow) {
          manageBodyClass('end-bar-enabled', 'add')
          return
        }
        manageBodyClass('end-bar-enabled', 'remove')
        return
      }
      self.showRightSidebar = !self.showRightSidebar
      manageBodyClass('end-bar-enabled', 'toggle')
    },
  }))

export type ILayout = Instance<typeof Layout>
