import { SERVICE_NAME } from '../../../constants'
import { IUserBasic } from '../../../models/users/UserBasic.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type IToggleManagerForUserProps = {
  userUuid: string
  managerUuid: string | null
}
export type IToggleManagerForUserResponse = IGenericResponse<'toggleManagerForUser', IUserBasic[]>

export async function toggleManagerForUser({
  userUuid,
  managerUuid,
}: IToggleManagerForUserProps): Promise<IToggleManagerForUserResponse> {
  const query = `
    mutation toggleManagerForUser ($userUuid: String!, $managerUuid: String) {
      toggleManagerForUser (managerUuid: $managerUuid, userUuid: $userUuid) {
        uuid
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.USERS, query, { userUuid, managerUuid })) as IToggleManagerForUserResponse
}
