import { Instance, types } from 'mobx-state-tree'

import { APPROVAL_STATES, RELEASE_FORMATS } from '../../../constants'
import { AssetUser } from '../AssetUser.model'

import { Image } from '../../general/Image.model'
import { DeliveryPlatform } from '../DeliveryPlatformModel'
import { MicrolicensingPropsConfig } from '../MicrolicensingPropsConfig.model'
import { ParticipantWithRole } from '../ParticipantWithRole.model'
import { Store } from '../StoreModel'
import { ArtTrackBasic } from '../artTracks/ArtTrackBasic.model'

export const ReleaseBasic = types.model({
  uuid: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  deletedAt: types.maybeNull(types.string),
  upc: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  users: types.array(AssetUser),
  artist: types.maybeNull(types.string),
  streamingLinks: types.maybeNull(
    types.array(
      types.model({
        link: types.string,
        store: types.string,
      })
    )
  ),
  participants: types.maybeNull(types.array(ParticipantWithRole)),
  genreUuids: types.maybeNull(types.array(types.string)),
  cInfo: types.maybeNull(types.string),
  cYear: types.maybeNull(types.number),
  pInfo: types.maybeNull(types.string),
  pYear: types.maybeNull(types.number),
  rightsHolders: types.maybeNull(types.string),
  label: types.maybeNull(types.string),
  sites: types.array(types.string),
  importSource: types.maybeNull(types.string),
  assetState: types.maybeNull(types.enumeration(Object.values(APPROVAL_STATES))),
  clientStatus: types.maybeNull(types.string),
  approvalComment: types.maybeNull(types.string),
  approvedAt: types.maybeNull(types.string),
  rejectedAt: types.maybeNull(types.string),
  approvalEmailSentAt: types.maybeNull(types.string),
  titleVersion: types.maybeNull(types.string),
  releaseFormat: types.maybeNull(types.enumeration(Object.values(RELEASE_FORMATS))),
  recordingLocation: types.maybeNull(types.string),
  originalReleaseDate: types.maybeNull(types.string),
  preorderDate: types.maybeNull(types.string),
  releaseDate: types.maybeNull(types.string),
  metadataLanguage: types.maybeNull(types.string),
  displayImagePath: types.maybeNull(types.string),
  generalInfoStepPassed: types.maybeNull(types.boolean),
  trackInfoStepPassed: types.maybeNull(types.boolean),
  targetPlatformStepPassed: types.maybeNull(types.boolean),
  availabilityStepPassed: types.maybeNull(types.boolean),
  audioLanguage: types.maybeNull(types.string),
  tracks: types.maybeNull(types.array(ArtTrackBasic)),
  genre: types.maybeNull(types.string),
  isCover: types.maybeNull(types.boolean),
  images: types.optional(types.array(Image), []),
  trackOrder: types.optional(types.array(types.string), []),
  downloadUrl: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  advisory: types.maybeNull(types.string),
  validationErrors: types.maybeNull(
    types.array(
      types.model({
        field: types.string,
        message: types.string,
      })
    )
  ),
  ddexTakedown: types.maybeNull(types.boolean),
  platforms: types.maybeNull(types.array(DeliveryPlatform)),
  srCmsDestination: types.maybeNull(types.string),
  stores: types.maybeNull(types.array(Store)),
  asJson: types.maybeNull(types.string),
  microlicensingProps: types.maybeNull(MicrolicensingPropsConfig),
  onlyForTrackGuest: types.maybeNull(types.boolean),
})

export type IReleaseBasic = Instance<typeof ReleaseBasic>
