import clientWrapper from '../../clientWrapper'
import { IGenericResponse } from '../../api.types'

import { SERVICE_NAME } from '../../../constants'

import { IFanbeeProfileBasic } from '../../../models/fanbee/FanbeeProfileBasic.model'

import { fanbeeProfileFragment } from '../fragments'

export type IUpdateFanbeeProfileProps = {
  uuid: string
  userUuid: string
  slug: string
  name: string
  bio?: string
  profileImage: string | null
  bannerImage: string | null
}

export type IUpdateFanbeeProfileResponse = IGenericResponse<'updateFanbeeProfile', IFanbeeProfileBasic>

export async function updateFanbeeProfile(
  data: IUpdateFanbeeProfileProps,
  fragment: string = fanbeeProfileFragment
): Promise<IUpdateFanbeeProfileResponse> {
  const query = `
  mutation updateFanbeeProfile($data: FanbeeProfileInput!) {
      updateFanbeeProfile(data: $data) {
          ${fragment}
      }
    }
  `

  return (await clientWrapper(SERVICE_NAME.USERS, query, { data })) as IUpdateFanbeeProfileResponse
}
