import axios, { AxiosProgressEvent } from 'axios'
import { applySnapshot, flow, getSnapshot, Instance } from 'mobx-state-tree'
import { toast } from 'react-toastify'

import { changeAssetsState } from '../../api/assets-api/assets/changeAssetsState'
import { createOrUpdateAsset, ICreateUpdateAssetResponse } from '../../api/assets-api/assets/createOrUpdateAsset'
import { deleteAsset, IDeleteAssetResponse } from '../../api/assets-api/assets/deleteAsset'
import { findAssetByUuid, IFindAssetByUuidResponse } from '../../api/assets-api/findAssetByUuid'
import {
  getAssetLifetimeEarnings,
  IGetAssetLifetimeEarningsResponse,
} from '../../api/assets-api/getAssetLifetimeEarnings'
import { IGetUploadUrlObjectResponse } from '../../api/assets-api/getUploadUrl'
import { IChangeAssetStatesResponse } from '../../api/types'
import {
  ADMIN,
  APPROVAL_STATES,
  ASSET_TYPE,
  PLATFORM,
  PLATFORM_STATES,
  TASFileStructContent,
  UPLOAD_FILE_STATUS,
} from '../../constants'
import { getLabelFromAssetState, getUploadDetails } from '../../helpers/assets'
import { IAssetFormData } from '../../types/common'
import { AssetBasic } from './AssetBasic.model'
import { IAssetUser } from './AssetUser.model'

export const Asset = AssetBasic.volatile(() => ({
  loading: false,
  loadingPatchUpdate: false,
  uploadingFile: false,
  loadingPlatformUpdate: false,
  fileUploadProgress: null as number | null,
  file: null as TASFileStructContent | null,
  deliveredDdex: false,
  totalRevenue: null as number | null,
  lifetimeEarnings: null as number | null,
  distributionLinks: [] as string[],
}))
  .views(self => ({
    get mainGenre() {
      if (self.genreUuids && self.genreUuids.length > 0) {
        return self.genreUuids[0]
      }
      return null
    },
    get secondaryGenre() {
      if (self.genreUuids && self.genreUuids.length > 1) {
        return self.genreUuids[1]
      }
      return null
    },
    get filterReference() {
      return `${self.uuid} ${self.title} ${self.artist} ${self.album} ${self.isrc} ${self.rightsHolders} ${self.fileName}`.toLowerCase()
    },
    trackIsValid(): boolean {
      return !!self.validationErrors && self.validationErrors.length === 0
    },

    getOwnerUuid() {
      return self.users.find(usr => usr.isOwner)?.userUuid
    },

    get fileUploadStatus() {
      if (self.uploadFinished) {
        return UPLOAD_FILE_STATUS.UPLOADED
      }

      if (self.fileName && self.fileUploadProgress === 100) {
        return UPLOAD_FILE_STATUS.UPLOADED
      }

      if (self.uploadingFile || self.loading) {
        return UPLOAD_FILE_STATUS.UPLOADING
      }

      return UPLOAD_FILE_STATUS.MISSING
    },
  }))

  .views(self => ({
    get hasEditDisabled() {
      // on admin we want to be less restrictive
      if (PLATFORM === ADMIN) {
        return self.clientStatus === PLATFORM_STATES.LIVE || self.clientStatus === PLATFORM_STATES.UPCOMING
      }
      return (
        [
          PLATFORM_STATES.LIVE as string,
          PLATFORM_STATES.UPCOMING as string,
          APPROVAL_STATES.PENDING_APPROVAL as string,
          PLATFORM_STATES.TAKEN_DOWN as string,
          APPROVAL_STATES.APPROVED as string,
        ].indexOf(self.clientStatus || '') > -1
      )
    },
  }))

  .actions(self => ({
    updateAssetWithUsers(users: IAssetUser[]) {
      applySnapshot(self, { ...self, users })
    },
    applyChanges: (info: IAssetFormData) => {
      const selfCopy = { ...getSnapshot(self) }

      applySnapshot(self, {
        ...selfCopy,
        ...{ ...info },
      })
    },

    removeSplit: (splitUuid: string) => {
      const removedSplitIndex = self.users.findIndex(assetUser => assetUser.revSplitUuid === splitUuid)
      const removeSplit = self.users.splice(removedSplitIndex, 1)[0]

      const ownerIndex = self.users.findIndex(assetUser => !assetUser.revSplitUuid)
      self.users[ownerIndex].percentage = (self.users[ownerIndex].percentage || 100) + (removeSplit.percentage || 0)
    },

    setAssetState(state: APPROVAL_STATES) {
      self.assetState = state
    },

    setUuid(uuid: string) {
      self.uuid = uuid
    },
    setMainGenre(newValue: string | null) {
      if (newValue && self.genreUuids) self.genreUuids[0] = newValue ?? undefined
    },
    setSecondaryGenre(newValue: string | null) {
      if (newValue && self.genreUuids) self.genreUuids[1] = newValue ?? undefined
    },

    setTotalRevenue(revenue: number) {
      self.totalRevenue = revenue
    },

    setForceAssignIsrc(check: boolean) {
      self.forceAssignIsrc = check
    },

    setFileUploadProgress(progress: number | null) {
      self.fileUploadProgress = progress
    },
    changeAssetFileUrl: (newUrl: string) => {
      self.storagePath = newUrl
    },
    changeAssetFileName: (newFileName: string) => {
      self.fileName = newFileName
    },
    changeAssetFileSize: (newSize: number) => {
      self.fileSizeInBytes = newSize
    },

    setDownloadUrl(newUrl: string | null) {
      self.downloadUrl = newUrl
    },

    prepareRequest(formData: IAssetFormData) {
      const reqBody = { ...formData }

      if (reqBody.releaseFormat === null) {
        delete reqBody.releaseFormat
      }

      const patch = {
        ...reqBody,
        cYear: reqBody.cYear ? parseInt(reqBody.cYear || '', 10) : undefined,
        pYear: reqBody.pYear ? parseInt(reqBody.pYear || '', 10) : undefined,
      }

      if (reqBody.releaseDate === '') {
        patch.releaseDate = undefined
      }
      if (reqBody.originalReleaseDate === '') {
        patch.originalReleaseDate = undefined
      }
      if (reqBody.preorderDate === '') {
        patch.preorderDate = undefined
      }

      return patch
    },
  }))
  .actions(self => ({
    load: flow(function* (fragment?: string) {
      try {
        self.loading = true
        if (self.uuid) {
          const resp: IFindAssetByUuidResponse = yield findAssetByUuid(self.uuid, fragment)
          if (resp && resp.data.data?.findAssetByUuid) {
            const assetResp = resp.data.data?.findAssetByUuid
            const preparedAsset = { ...assetResp }

            applySnapshot(self, preparedAsset)
          }
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),
    loadLifetimeEarnings: flow(function* () {
      try {
        self.loadingPatchUpdate = true
        if (self.uuid) {
          const resp: IGetAssetLifetimeEarningsResponse = yield getAssetLifetimeEarnings(self.uuid)
          if (resp && resp.data.data?.assetLifetimeEarnings) {
            self.lifetimeEarnings = resp.data.data?.assetLifetimeEarnings
          }
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loadingPatchUpdate = false
      }
    }),

    deleteAsset: flow(function* () {
      try {
        if (self.uuid) {
          const resp: IDeleteAssetResponse = yield deleteAsset({ uuid: self.uuid })
          if (resp && resp.data.data?.deleteAsset) {
            toast.success(`Asset deleted.`)
          }
        }
      } catch (err) {
        console.error(err)
      }
    }),

    changeAssetState: flow(function* ({
      targetState,
      comment,
      successMsg,
    }: {
      targetState: APPROVAL_STATES
      comment?: string
      successMsg?: string
    }) {
      try {
        if (self.uuid) {
          self.loadingPlatformUpdate = true
          const resp: IChangeAssetStatesResponse = yield changeAssetsState([
            {
              itemId: self.uuid,
              targetState,
              comment,
            },
          ])
          if (resp && resp.data.data?.changeAssetsState[0]?.assetState) {
            self.setAssetState(resp.data.data.changeAssetsState[0].assetState)
            toast.success(successMsg || `Asset state set to ${getLabelFromAssetState(targetState)}`)
          }
          self.loadingPlatformUpdate = false
        }
      } catch (err) {
        self.loadingPlatformUpdate = false
        console.error(err)
      }
      return ''
    }),

    createUpdate: flow(function* ({
      info,
      successMessage = '',
      fragment,
    }: {
      info: IAssetFormData
      successMessage?: string
      fragment?: string
    }) {
      try {
        self.loadingPatchUpdate = true
        const patch = self.prepareRequest({ ...info })

        const resp: ICreateUpdateAssetResponse = yield createOrUpdateAsset(
          {
            ...patch,
            uuid: self.uuid || undefined,
          },
          fragment
        )

        if (resp && resp.data.data?.createOrUpdateAsset.uuid) {
          if (successMessage) {
            toast.success(successMessage)
          }
          self.uuid = resp.data.data?.createOrUpdateAsset.uuid
        }
        self.loadingPatchUpdate = false
        return resp?.data.data?.createOrUpdateAsset.uuid || ''
      } catch (err) {
        self.loadingPatchUpdate = false
        console.error(err)
        return ''
      }
    }),
  }))
  .actions(self => ({
    uploadFile: flow(function* (file: TASFileStructContent, successMessage?: string) {
      try {
        // add the dropped object to asset as a volatile prop
        self.file = file

        // get upload url
        self.uploadingFile = true
        const uploadDetails: IGetUploadUrlObjectResponse = yield getUploadDetails({
          assetType: self.assetTypes ? self.assetTypes[0] : ASSET_TYPE.SOUND_RECORDING,
          assetFileName: self.file?.file?.name || '',
          assetUuid: self.uuid || '',
        })

        self.file.uploadUrl = uploadDetails?.uploadUrl || ''
        // start upload

        try {
          yield axios.put(self.file?.uploadUrl || '', self.file?.file, {
            headers: {
              'Content-Type': self.file?.file.type,
            },

            onUploadProgress: (e: AxiosProgressEvent) => {
              if (e.total) {
                const percentage = Math.round((100 * e.loaded) / e.total)
                self.setFileUploadProgress(percentage)
              }
            },
          })

          const resp: ICreateUpdateAssetResponse = yield createOrUpdateAsset({
            fileName: self.file?.file?.name || '',
            fileSizeInBytes: self.file?.file?.size,
            uploadFinished: true,
            uuid: self.uuid || '',
          })

          applySnapshot(self, resp?.data.data?.createOrUpdateAsset)

          self.uploadingFile = false
          toast.success(successMessage)
        } catch (error) {
          self.uploadingFile = false
          toast.error('File Upload Failed')
          console.error(error)
        }
      } catch (err) {
        console.error(err)
      }
    }),
  }))

export type IAsset = Instance<typeof Asset>
