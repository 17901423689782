import { SERVICE_NAME } from '../../../../constants'
import { IGenericResponse } from '../../../api.types'
import clientWrapper from '../../../clientWrapper'

export interface IAssignUserToCompositionsProps {
  userUuid: string
  uuids: string[]
}

export type IAssignUserToCompositionsResponse = IGenericResponse<'assignUserToCompositions', boolean>

export async function assignUserToCompositions(
  props: IAssignUserToCompositionsProps
): Promise<IAssignUserToCompositionsResponse> {
  const query = `
    mutation AssignUserToCompositions($userUuid: String!, $uuids: [String!]!) {
        assignUserToCompositions(userUuid: $userUuid, uuids: $uuids)
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, props) as Promise<IAssignUserToCompositionsResponse>
}
