import {
  AudioWave,
  CogwheelSettingsAccount,
  DashboardPerformance,
  DollarClipboardFile,
  DollarSignCircle,
  FileShield,
  GraphChart,
  MusicAlbum,
  VideoPlayerWebsite,
  WebsiteClip,
  XmarkShield,
} from '@vectopus/atlas-icons-react'
import { PERMISSIONS } from 'e2e-utils'
import { EPIC_ELITE_ROUTES } from '../../routes/platformRoutes/epicEliteRoutes'
import { IMenuItem } from './menu.types'

const MENU_ITEMS_EPIC_ELITE: IMenuItem[] = [
  { key: 'your-dashboard', name: 'Your  Dashboard', isTitle: true },
  {
    IconElement: <DashboardPerformance size={20} className="me-2" />,
    ...EPIC_ELITE_ROUTES.DASHBOARD,
  },

  {
    key: 'epic-elite-dashboard',
    name: 'Distribution',
    isTitle: true,
    permissionsOr: [...(EPIC_ELITE_ROUTES.ANALYTICS_DISTRIBUTION.permissionsOr || [])],
    permissionsAnd: [...(EPIC_ELITE_ROUTES.ANALYTICS_DISTRIBUTION.permissionsAnd || [])],
  },

  {
    IconElement: <GraphChart size={20} className="me-2" />,
    ...EPIC_ELITE_ROUTES.ANALYTICS_DISTRIBUTION,
  },

  {
    IconElement: <MusicAlbum size={20} className="me-2" />,
    ...EPIC_ELITE_ROUTES.RELEASES,
  },

  {
    IconElement: <AudioWave size={20} className="me-2" />,
    ...EPIC_ELITE_ROUTES.AUT_CREATE,
  },

  {
    IconElement: <DollarSignCircle size={20} className="me-2" />,
    ...EPIC_ELITE_ROUTES.DISTRIBUTION_EARNINGS,
  },

  { key: 'youtube-title', name: 'Youtube', isTitle: true },

  {
    IconElement: <GraphChart size={20} className="me-2" />,
    ...EPIC_ELITE_ROUTES.ANALYTICS_CONTENT_ID,
  },

  {
    IconElement: <XmarkShield size={20} className="me-2" />,
    ...EPIC_ELITE_ROUTES.CLAIMS_CONTENT_ID,
  },

  {
    IconElement: <FileShield size={20} className="me-2" />,
    ...EPIC_ELITE_ROUTES.ASSETS_CONTENT_ID,
  },
  {
    key: 'channels',
    name: 'Channels',
    IconElement: <VideoPlayerWebsite size={20} className="me-2" />,
    permissionsOr: [PERMISSIONS.CLAIM.LIST_SELF, PERMISSIONS.CLAIM.RELEASE_SELF],
    children: [EPIC_ELITE_ROUTES.CHANNEL_LIST, EPIC_ELITE_ROUTES.VIDEOS],
  },
  {
    IconElement: <WebsiteClip size={20} className="me-2" />,
    ...EPIC_ELITE_ROUTES.CHANNEL_WHITELISTING,
  },
  {
    IconElement: <DollarSignCircle size={20} className="me-2" />,
    ...EPIC_ELITE_ROUTES.EARNINGS_CONTENT_ID,
  },

  { key: 'payments-title', name: 'Payments', isTitle: true },

  {
    IconElement: <DollarClipboardFile size={20} className="me-2" />,
    ...EPIC_ELITE_ROUTES.MONTHLY_REPORT,
  },
  {
    IconElement: <CogwheelSettingsAccount size={20} className="me-2" />,
    ...EPIC_ELITE_ROUTES.TAX_FORMS,
  },
]

export default MENU_ITEMS_EPIC_ELITE
