import { PERMISSIONS } from 'e2e-utils'
import { observer } from 'mobx-react-lite'
import { Button, Card, Form, Row } from 'react-bootstrap'

import { FANBEE_DOMAIN } from '@epic-front/common'
import { IFanbeeProfile } from '@epic-front/common/models/fanbee/FanbeeProfile.model'
import { useStore } from '@hooks'

import { UpRightArrow } from '@vectopus/atlas-icons-react'
import { Link } from 'react-router-dom'
import WidgetTitle from '../titles/WidgetTitle'
import FanbeeProfileImages from './FanbeeProfileImages'
import FanbeePlatformsLinks from './FanbeeSocialPlatformsLinks'
import FanbeeUrlsTable from './FanbeeUrlsTable'

type IProps = {
  fanbeeProfile: IFanbeeProfile
}

const FanbeeProfileUpdate = ({ fanbeeProfile }: IProps): JSX.Element => {
  const { auth } = useStore()

  const isClient = auth.hasPermission(PERMISSIONS.FANBEE.MANAGE_SELF)

  return (
    <Row>
      <div className="col-12 col-xxl-8 col-xxxl-9">
        <Card>
          <Card.Header>
            <WidgetTitle
              title="Profile Details"
              supportingText="Edit your profile details: Name, Slug and Bio."
              reverseOrder
              actions={
                <Link
                  target="_blank"
                  className="btn-lg shadow-sm btn btn-light"
                  to={`${FANBEE_DOMAIN}/${fanbeeProfile.slug}`}
                >
                  Preview Profile
                  <UpRightArrow weight="bold" />
                </Link>
              }
            />
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Group controlId={`${fanbeeProfile.userUuid}-fanbee-name`} className="mb-3">
                <h5 className="mb-1">Name</h5>
                <p className="text-gray-500 mb-1">This will be the main title of your page.</p>
                <Form.Control
                  type="text"
                  disabled={fanbeeProfile.loading}
                  defaultValue={fanbeeProfile.name || ''}
                  onChange={event => fanbeeProfile.setName(event.target.value)}
                />
              </Form.Group>
              <Form.Group controlId={`${fanbeeProfile.userUuid}-fanbee-slug`} className="mb-3">
                <h5 className="mb-1">URL / Slug</h5>
                <p className="text-gray-500 mb-1">
                  The extension added to the end of {FANBEE_DOMAIN}/<b>xxxxxx</b>
                </p>
                <Form.Control
                  type="text"
                  disabled={(isClient && fanbeeProfile.slugChanged) || fanbeeProfile.loading}
                  defaultValue={fanbeeProfile.slug || ''}
                  onChange={event => fanbeeProfile.setSlug(event.target.value)}
                />
                {isClient && fanbeeProfile.slugChanged && (
                  <Form.Text className="text-gray-500">
                    <p className="my-1">Please contact our operations team for the change of the URL.</p>
                  </Form.Text>
                )}
                {isClient && !fanbeeProfile.slugChanged && (
                  <Form.Text className="text-gray-500">
                    <p className="my-1">This can only be set once!</p>
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group controlId={`${fanbeeProfile.userUuid}-fanbee-bio`} className="mb-3">
                <h5 className="mb-1">Bio / Information</h5>
                <p className="text-gray-500 mb-1">Information or bio description about your brand or yourself.</p>
                <Form.Control
                  as="textarea"
                  rows={5}
                  disabled={fanbeeProfile.loading}
                  defaultValue={fanbeeProfile.bio || ''}
                  onChange={event => fanbeeProfile.setBio(event.target.value)}
                />
              </Form.Group>
            </Form>
          </Card.Body>
          <Card.Footer>
            <Button
              size="lg"
              className="btn"
              variant="success"
              disabled={fanbeeProfile.loading}
              onClick={() => fanbeeProfile.update()}
            >
              <i className="uil-check" />
              Update details
            </Button>
          </Card.Footer>
        </Card>
        <FanbeePlatformsLinks fanbeeProfile={fanbeeProfile} />
        <FanbeeUrlsTable userUuid={fanbeeProfile.userUuid} />
      </div>

      <FanbeeProfileImages fanbeeProfile={fanbeeProfile} />
    </Row>
  )
}

export default observer(FanbeeProfileUpdate)
