import { applySnapshot, cast, flow, Instance } from 'mobx-state-tree'
import { toast } from 'react-toastify'

import {
  IUpdateFanbeePlatformOrderResponse,
  updateFanbeePlatformOrder,
} from '@epic-front/common/api/users-api/fanbee/updateFanbeePlatformOrder'
import { IFanbeeProfileResponse, fanbeeProfile } from '../../api/users-api/fanbee/fanbeeProfile'
import { IUpdateFanbeeProfileResponse, updateFanbeeProfile } from '../../api/users-api/fanbee/updateFanbeeProfile'

import { createFanbeeUrl, ICreateFanbeeUrlResponse } from '../../api/users-api/fanbee/createFanbeeUrl'
import { IImageBasic } from '../general/ImageBasic.model'
import { FanbeeProfileBasic } from './FanbeeProfileBasic.model'

import { deleteImage, IDeleteImageResponse } from '../../api/users-api/other/deleteImage'
import { IMAGE_TYPE } from '../../constants'
import { deleteFanbeeUrl, IDeleteFanbeeUrlResponse } from '../../api/users-api/fanbee/deleteFanbeeUrl'

export const FanbeeProfile = FanbeeProfileBasic.volatile(() => ({
  loading: false,
}))
  .volatile(() => ({
    profileImageUuid: '',
    bannerImageUuid: '',
  }))
  .views(self => ({
    getProfileUrlUuid() {
      if (self.profileImage?.uuid) {
        return self.profileImage.uuid
      }
      if (self.profileImageUuid.length > 0) {
        return self.profileImageUuid
      }
      return null
    },
    getBannerUrlUuid() {
      if (self.bannerImage?.uuid) {
        return self.bannerImage.uuid
      }
      if (self.bannerImageUuid.length > 0) {
        return self.bannerImageUuid
      }
      return null
    },
  }))
  .actions(self => ({
    setSlug(slug: string) {
      self.slug = slug
    },
    setName(name: string) {
      self.name = name
    },
    setBio(bio: string) {
      self.bio = bio
    },
    setProfileImage(img: IImageBasic | null) {
      self.profileImage = img
    },
    setBannerImage(img: IImageBasic | null) {
      self.bannerImage = img
    },
    setProfileImageUuid(profileImageUuid: string) {
      self.profileImageUuid = profileImageUuid
    },
    setBannerImageUuid(bannerImageUuid: string) {
      self.bannerImageUuid = bannerImageUuid
    },
  }))
  .actions(self => ({
    load: flow(function* (userUuid: string) {
      try {
        self.loading = true

        const resp: IFanbeeProfileResponse = yield fanbeeProfile(userUuid)
        if (resp && resp.data.data?.fanbeeProfile) applySnapshot(self, resp.data.data?.fanbeeProfile)

        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),
    update: flow(function* () {
      try {
        self.loading = true

        const resp: IUpdateFanbeeProfileResponse = yield updateFanbeeProfile({
          uuid: self.uuid || '',
          userUuid: self.userUuid || '',
          slug: self.slug || '',
          name: self.name || '',
          bio: self.bio || '',
          profileImage: self.getProfileUrlUuid(),
          bannerImage: self.getBannerUrlUuid(),
        })

        if (resp && resp.data.data?.updateFanbeeProfile) {
          applySnapshot(self, resp.data.data?.updateFanbeeProfile)
          toast.success('Profile successfully updated!')
        }

        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),
    createFanbeeUrl: flow(function* (platformSlug: string, url: string) {
      try {
        self.loading = true

        const resp: ICreateFanbeeUrlResponse = yield createFanbeeUrl({
          userUuid: self.userUuid || '',
          platformSlug,
          url,
        })

        if (resp && resp.data.data?.createFanbeeUrl) {
          const updatedUuid = resp.data.data.createFanbeeUrl.uuid
          const updatedIndex = self.platformUrls.findIndex(pltUrl => pltUrl.uuid === updatedUuid)

          const updatedSelf = self
          if (updatedIndex === -1) {
            updatedSelf.platformUrls.unshift(resp.data.data?.createFanbeeUrl)
          } else {
            updatedSelf.platformUrls.splice(updatedIndex, 1, resp.data.data.createFanbeeUrl)
          }

          applySnapshot(self, updatedSelf)
        }

        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),
    deleteFanbeeUrl: flow(function* (urlUuid) {
      try {
        self.loading = true

        const resp: IDeleteFanbeeUrlResponse = yield deleteFanbeeUrl(urlUuid)

        if (resp?.data.data?.deleteFanbeeUrl) {
          self.platformUrls = cast(self.platformUrls.filter(url => url.uuid !== urlUuid))
          toast.success('Platform link deleted successfully')
        }

        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),
    updateFanbeeUrlOrder: flow(function* (rowUuid: string, newPosition: number) {
      try {
        self.loading = true

        const updatedPlatformUrls = self.platformUrls.slice()
        const movedUrlIndex = updatedPlatformUrls.findIndex(url => url.uuid === rowUuid)

        if (movedUrlIndex !== -1) {
          const [movedUrl] = updatedPlatformUrls.splice(movedUrlIndex, 1)
          updatedPlatformUrls.splice(newPosition, 0, movedUrl)
        }

        self.platformUrls = cast(updatedPlatformUrls)

        const resp: IUpdateFanbeePlatformOrderResponse = yield updateFanbeePlatformOrder({
          platformOrder: updatedPlatformUrls.map(url => url.uuid ?? ''),
          userUuid: self.userUuid || '',
        })

        if (resp?.data.data?.updateFanbeePlatformOrder.platformUrls) {
          self.platformUrls = cast(self.platformUrls)
          toast.success('Platform order updated successfully')
        }

        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),
  }))
  .actions(self => ({
    deleteProfilePicture: flow(function* () {
      try {
        self.loading = true

        const profilePictureImageUuid = self.getProfileUrlUuid()
        if (profilePictureImageUuid) {
          const resp: IDeleteImageResponse = yield deleteImage(IMAGE_TYPE.FANBEE_PROFILE, profilePictureImageUuid)
          if (resp?.data.data?.deleteImage) {
            self.setProfileImage(null)
            self.setProfileImageUuid('')
            self.update()
          }
        }

        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),

    uploadProfileImage: flow(function* (imageData: IImageBasic) {
      try {
        self.loading = true

        self.setProfileImage(null)
        self.setProfileImageUuid(imageData.uuid ?? '')
        yield self.update()

        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),

    deleteBannerPicture: flow(function* () {
      try {
        self.loading = true

        const bannerPictureImageUuid = self.getBannerUrlUuid()

        if (bannerPictureImageUuid) {
          const resp: IDeleteImageResponse = yield deleteImage(IMAGE_TYPE.FANBEE_BANNER, bannerPictureImageUuid)
          if (resp?.data.data?.deleteImage) {
            self.setBannerImage(null)
            self.setBannerImageUuid('')
            self.update()
          }
        }

        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),

    uploadBannerPicture: flow(function* (imageData: IImageBasic) {
      try {
        self.loading = true

        self.setBannerImage(null)
        self.setBannerImageUuid(imageData.uuid ?? '')
        yield self.update()

        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),
  }))

export type IFanbeeProfile = Instance<typeof FanbeeProfile>
