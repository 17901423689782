import classNames from 'classnames'
import FeaturedIcon from '../FeaturedIcon'

type WidgetTitleProps = {
  className?: string
  title?: string
  supportingText?: string
  actions?: JSX.Element
  separatorBottom?: boolean
  reverseOrder?: boolean
  size?: 'sm' | 'lg' | 'md'
  icon?: JSX.Element
  iconColor?: 'primary' | 'light' | 'warning' | 'gray' | 'info'
}

/**
 * WidgetTitle
 */
const WidgetTitle = ({
  className,
  title,
  supportingText,
  actions,
  separatorBottom,
  reverseOrder,
  size = 'md',
  icon,
  iconColor,
}: WidgetTitleProps): JSX.Element => {
  return (
    <div className={`col-12 d-flex flex-column gap-2 ${className}`}>
      <div className="d-flex flex-row flex-wrap gap-2">
        {icon && (
          <FeaturedIcon
            size={size || 'md'}
            color={iconColor}
            icon={icon}
            className={classNames({
              'me-1': size === 'md',
              'me-2': size === 'lg',
              'me-0': size === 'sm',
            })}
          />
        )}
        <div className="d-flex flex-row gap-3 flex-wrap flex-grow-1">
          <div className="d-flex flex-row gap-2 align-items-start flex-grow-1">
            <div
              className={`d-flex flex-column justify-content-between ${reverseOrder ? 'flex-column-reverse' : ''}`}
              style={size === 'lg' ? { gap: '0.25rem' } : {}}
            >
              {supportingText && (
                <h6 className={`text-gray-500 m-0 fw-normal ${size === 'sm' ? 'font-13' : ''}`}>{supportingText}</h6>
              )}
              {title && (
                <div
                  className={classNames('m-0', {
                    h5: size === 'sm',
                    h4: size === 'md',
                    h3: size === 'lg',
                  })}
                >
                  {title}
                </div>
              )}
            </div>
          </div>
          {actions && <div className="actions d-flex flex-row justify-end flex-wrap gap-1">{actions}</div>}
        </div>
      </div>
      {separatorBottom && <hr className="m-0" />}
    </div>
  )
}

export default WidgetTitle
