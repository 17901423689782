import { Trash } from '@vectopus/atlas-icons-react'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Button, Card, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import Select from 'react-select'

import { socialPlatforms } from '@epic-front/common/api/users-api/fanbee/socialPlatforms'

import { IFanbeeProfile } from '@epic-front/common/models/fanbee/FanbeeProfile.model'

import { DeleteModal, ITableColumn, Table } from '@blocks'

import { FANBEE_SOCIAL_PLATFORMS, VALID_URL_REGEX } from '@epic-front/common'
import { IFanbeeUrl } from '@epic-front/common/models/fanbee/FanbeeUrl.model'
import WidgetTitle from '../titles/WidgetTitle'

const PLACEHOLDER_MAP = {
  [FANBEE_SOCIAL_PLATFORMS.DISCORD]: 'https://discords.com/servers/channelnumber',
  [FANBEE_SOCIAL_PLATFORMS.FACEBOOK]: 'https://www.facebook.com/pagename/',
  [FANBEE_SOCIAL_PLATFORMS.INSTAGRAM]: 'https://www.instagram.com/pagename',
  [FANBEE_SOCIAL_PLATFORMS.TWITTER]: 'https://x.com/pagename',
  [FANBEE_SOCIAL_PLATFORMS.WEBSITE]: 'https://www.pagename.com',
  [FANBEE_SOCIAL_PLATFORMS.YOUTUBE]: 'https://www.youtube.com/@ChannelName',
}

const DEFAULT_PLACEHOLDER = 'Enter platform account name'

type IProps = {
  fanbeeProfile: IFanbeeProfile
}

const FanbeeSocialPlatformsLinks = ({ fanbeeProfile }: IProps): JSX.Element => {
  const [socialPlatformSlugs, setSocialPlatformSlugs] = useState<FANBEE_SOCIAL_PLATFORMS[]>([])

  const [newLinkPlatformSlug, setNewLinkPlatformSlug] = useState<FANBEE_SOCIAL_PLATFORMS>()
  const [newPlatformUrl, setNewPlatformUrl] = useState<string>()

  const [selectedPlatformLinkDeleteModal, setSelectedPlatformLinkDeleteModal] = useState<IFanbeeUrl | null>(null)

  useEffect(() => {
    const getSocialPlatforms = async () => {
      try {
        const resp = await socialPlatforms()
        if (resp && resp?.data.data?.socialPlatforms) {
          setSocialPlatformSlugs(resp.data.data.socialPlatforms)
        }
      } catch (err) {
        console.error(err)
      }
    }

    getSocialPlatforms()
  }, [fanbeeProfile.userUuid])

  const getPlaceholder = () => {
    if (!newLinkPlatformSlug) {
      return DEFAULT_PLACEHOLDER
    }

    return PLACEHOLDER_MAP[newLinkPlatformSlug] || DEFAULT_PLACEHOLDER
  }

  const handleAddNewPlatformUrl = () => {
    if (!newPlatformUrl || !VALID_URL_REGEX.test(newPlatformUrl)) {
      return
    }

    fanbeeProfile.createFanbeeUrl(newLinkPlatformSlug || '', newPlatformUrl || '')
  }

  const handleDeletePlatformLink = async () => {
    if (!selectedPlatformLinkDeleteModal) return

    await fanbeeProfile.deleteFanbeeUrl(selectedPlatformLinkDeleteModal.uuid)
    setSelectedPlatformLinkDeleteModal(null)
  }

  const handleOnDrop = (rowUuid: string, newPosition: number) => {
    fanbeeProfile.updateFanbeeUrlOrder(rowUuid, newPosition)
  }

  const columns: ITableColumn<IFanbeeUrl>[] = [
    {
      Header: 'Platform',
      accessor: 'platformSlug',
    },
    {
      Header: 'Platform Url',
      accessor: 'url',
      headerCellClasses: '',
      cellClasses: 'text-nowrap ',
      Cell: row => (
        <a href={row.url || ''} target="_blank" rel="noreferrer">
          {row.url}
        </a>
      ),
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      headerCellClasses: 'text-end mincol',
      cellClasses: 'actions-column mincol',
      Cell: row => (
        <div className="d-flex justify-content-end gap-1">
          <OverlayTrigger placement="top" overlay={<Tooltip id="Delete release">Open Link</Tooltip>}>
            <a href={row.url || ''} target="_blank" rel="noreferrer" className="btn btn-light">
              <i className="uil-arrow-up-right" />
            </a>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip id="Delete release">Delete Link</Tooltip>}>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                setSelectedPlatformLinkDeleteModal(row)
              }}
            >
              <Trash />
            </button>
          </OverlayTrigger>
        </div>
      ),
    },
  ]

  return (
    <Card className="overflow-visible">
      <Card.Header>
        <WidgetTitle
          title="Social Platform links"
          supportingText="Add the links to your Social Networks."
          reverseOrder
        />
      </Card.Header>
      <Card.Body className="p-0">
        <Form>
          <Row className="g-2 p-3">
            <Col xs={12} sm={4}>
              <Form.Group controlId={`${fanbeeProfile.userUuid}-fanbee-platform-slug`}>
                <Form.Label>Platform name:</Form.Label>
                <Select
                  className="react-select p-0 h-100"
                  classNamePrefix="react-select"
                  placeholder="Select platform"
                  options={socialPlatformSlugs.map(platform => ({ label: platform, value: platform }))}
                  onChange={inputPlatform => setNewLinkPlatformSlug(inputPlatform?.label)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Group controlId={`${fanbeeProfile.userUuid}-fanbee-platform-url`}>
                <Form.Label>Platform Url:</Form.Label>
                <Form.Control
                  className="input"
                  type="text"
                  placeholder={getPlaceholder()}
                  disabled={fanbeeProfile.loading}
                  defaultValue={newPlatformUrl}
                  onChange={event => setNewPlatformUrl(event.target.value)}
                  isInvalid={newPlatformUrl ? !VALID_URL_REGEX.test(newPlatformUrl) : undefined}
                />
                <Form.Control.Feedback type="invalid" tooltip />
              </Form.Group>
            </Col>
            <Col xs={12} sm={2} className="d-flex justify-content-end align-items-end">
              <Button
                variant="success"
                className="w-100"
                disabled={
                  fanbeeProfile.loading ||
                  !newLinkPlatformSlug ||
                  !newPlatformUrl ||
                  !VALID_URL_REGEX.test(newPlatformUrl)
                }
                onClick={() => handleAddNewPlatformUrl()}
              >
                <i className="uil-plus" />
                Add
              </Button>
            </Col>
          </Row>
        </Form>

        <Table<IFanbeeUrl>
          tableClasses="table-striped rounded-bottom-4 overflow-hidden"
          tableHeadClasses="table-dark"
          columns={columns}
          data={fanbeeProfile.platformUrls}
          isLoading={fanbeeProfile.loading}
          rowIdentifier={row => row.uuid || ''}
          showRowNumber
          isDraggable
          handleOnDrop={handleOnDrop}
        />

        {/* Delete Link Modal */}
        <DeleteModal
          title="Delete Platform Link"
          open={!!selectedPlatformLinkDeleteModal}
          closeModal={() => {
            setSelectedPlatformLinkDeleteModal(null)
          }}
          submit={handleDeletePlatformLink}
        >
          <p>
            {' '}
            Are you sure you want to delete the following platform link for{' '}
            <b>{selectedPlatformLinkDeleteModal?.platformSlug ?? ''}</b>?
          </p>
        </DeleteModal>
      </Card.Body>
    </Card>
  )
}

export default observer(FanbeeSocialPlatformsLinks)
