import { Instance, types } from 'mobx-state-tree'

import { StreamingLink } from './StreamingLink.model'

export const TrackFanbeeBasic = types.model({
  uuid: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  artist: types.maybeNull(types.string),
  streamingLinks: types.array(StreamingLink),
})

export type ITrackFanbeeBasic = Instance<typeof TrackFanbeeBasic>
