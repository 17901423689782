import { SERVICE_NAME, STORES } from '../../../../constants'
import { IGenericResponse } from '../../../api.types'
import clientWrapper from '../../../clientWrapper'

export type IAddReleaseFanbeeStreamingLinkResponse = IGenericResponse<'addReleaseFanbeeStreamingLink', boolean>

export type IAddReleaseFanbeeStreamingLinkProps = {
  releaseUuid: string
  input: {
    link: string
    store: STORES
  }
}

export async function addReleaseFanbeeStreamingLink({
  releaseUuid,
  input,
}: IAddReleaseFanbeeStreamingLinkProps): Promise<IAddReleaseFanbeeStreamingLinkResponse> {
  const query = `
    mutation addReleaseFanbeeStreamingLink($input: StreamingLinkInput!, $releaseUuid: String!) {
        addReleaseFanbeeStreamingLink(input: $input, releaseUuid: $releaseUuid)
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, {
    releaseUuid,
    input,
  })) as IAddReleaseFanbeeStreamingLinkResponse
}
