import { cast, flow, Instance, types } from 'mobx-state-tree'
import { toast } from 'react-toastify'

import {
  assignUserToCompositions,
  IAssignUserToCompositionsProps,
} from '../../api/assets-api/assets/composition/assignUserToCompositions'
import {
  getCompositions,
  IGetCompositionsProps,
  IGetCompositionsResponse,
} from '../../api/assets-api/assets/composition/getCompositions'
import { ASSET_ID_REGEX, ASSET_UUID_REGEX, ASSIGNMENT_STATUSES } from '../../constants'
import downloadCompositionsXLSX from '../../api/assets-api/other/downloadCompositionsXLSX'
import { Composition } from './Composition.model'
import { Pagination } from '../general/Pagination.model'

export const CompositionList = types
  .model({
    list: types.array(Composition),
  })
  .volatile(() => ({
    loading: false,
    loadingExport: false,
    pagination: Pagination.create({ totalItems: 0 }),
    textFilter: '',
    userUuidFilter: '',
    siteUuidsFilter: [] as string[],
    assignmentStatusFilter: '',
    multipleIdsFilter: [] as string[],
  }))
  .views(self => ({
    byUuid(uuid: string | null | undefined) {
      if (!uuid) {
        return undefined
      }
      return self.list.find(el => el.uuid === uuid)
    },
    byUuids(uuids: string[]) {
      return self.list.filter(item => uuids.includes(item.uuid || ''))
    },
  }))
  .actions(self => ({
    getByOwnerUuid(ownerUuid: string | null | undefined) {
      if (ownerUuid) {
        return self.list.find(user => user.getOwnerUuid() === ownerUuid)
      }
      return null
    },
    getByUuids(uuids: string[]) {
      return self.list.filter(composition => (composition.uuid ? uuids.includes(composition.uuid) : false))
    },
    getTextSearchParam() {
      if (ASSET_UUID_REGEX.test(self.textFilter)) return 'uuid'
      if (ASSET_ID_REGEX.test(self.textFilter)) return 'assetId'
      return 'title'
    },
  }))

  .actions(self => ({
    setTextFilter(text: string) {
      self.textFilter = text
    },
    setUserUuidFilter(userUuid: string | null) {
      self.userUuidFilter = userUuid || ''
    },
    setSiteUuidsFilter(siteUuids: string[]) {
      self.siteUuidsFilter = siteUuids
    },
    setAssignmentFilter(assignment: string) {
      self.assignmentStatusFilter = assignment
    },
    setMultipleIdsFilter(ids: string[]) {
      self.multipleIdsFilter = ids
    },
  }))
  .actions(self => ({
    load: flow(function* () {
      try {
        self.loading = true

        const variables: IGetCompositionsProps = {
          pagination: self.pagination.allQueryParams,
          filters: {
            ...(self.textFilter && { [self.getTextSearchParam()]: self.textFilter }),
            ...(self.userUuidFilter && { userUuid: self.userUuidFilter }),
            ...(self.siteUuidsFilter.length > 0 && { siteUuids: self.siteUuidsFilter }),
            ...(self.assignmentStatusFilter && {
              isAssigned: self.assignmentStatusFilter === ASSIGNMENT_STATUSES.ASSIGNED.value,
            }),
            ...(self.multipleIdsFilter.length > 0 && { multipleIds: self.multipleIdsFilter }),
          },
        }

        const resp: IGetCompositionsResponse = yield getCompositions(variables)

        if (resp && resp.data.data && resp.data.data.compositions) {
          self.list = cast(resp.data.data.compositions.assets)
          self.pagination.setTotalItems(resp.data.data.compositions.total)
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),
  }))
  .actions(self => ({
    assignUserToCompositions: flow(function* (props: IAssignUserToCompositionsProps) {
      try {
        self.loading = true
        yield assignUserToCompositions(props)

        yield self.load()

        toast.success('User assigned successfully to compositions!')
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),
    downloadExport: flow(function* () {
      try {
        self.loadingExport = true

        const filters = {
          ...(self.textFilter && { [self.getTextSearchParam()]: self.textFilter }),
          ...(self.userUuidFilter && { userUuid: self.userUuidFilter }),
          ...(self.siteUuidsFilter.length > 0 && { siteUuids: self.siteUuidsFilter }),
          ...(self.assignmentStatusFilter && {
            isAssigned: self.assignmentStatusFilter === ASSIGNMENT_STATUSES.ASSIGNED.value,
          }),
          ...(self.multipleIdsFilter.length > 0 && { multipleIds: self.multipleIdsFilter }),
        }

        yield downloadCompositionsXLSX(filters)

        self.loadingExport = false
      } catch (e) {
        console.error(e)
      }
    }),
  }))

export type ICompositionList = Instance<typeof CompositionList>
