import { applySnapshot, cast, flow, Instance } from 'mobx-state-tree'
import { toast } from 'react-toastify'

import { STORES } from '../../../constants'
import {
  addFanbeeLink,
  IAddFanbeeLinkProps,
  IAddFanbeeLinkResponse,
} from '../../../api/assets-api/assets/fanbee/addFanbeeLink'
import {
  addReleaseFanbeeStreamingLink,
  IAddReleaseFanbeeStreamingLinkProps,
  IAddReleaseFanbeeStreamingLinkResponse,
} from '../../../api/assets-api/assets/fanbee/addReleaseFanbeeStreamingLink'
import {
  IRemoveReleaseFanbeeStreamingLinkProps,
  IRemoveReleaseFanbeeStreamingLinkResponse,
  removeReleaseFanbeeStreamingLink,
} from '../../../api/assets-api/assets/fanbee/removeReleaseFanbeeStreamingLink'
import {
  addTrackFanbeeStreamingLink,
  IAddTrackFanbeeStreamingLinkProps,
  IAddTrackFanbeeStreamingLinkResponse,
} from '../../../api/assets-api/assets/fanbee/addTrackFanbeeStreamingLink'
import {
  IRemoveTrackFanbeeStreamingLinkProps,
  IRemoveTrackFanbeeStreamingLinkResponse,
  removeTrackFanbeeStreamingLink,
} from '../../../api/assets-api/assets/fanbee/removeTrackFanbeeStreamingLink'

import { ReleaseFanbeeBasic } from './ReleaseFanbeeBasic.model'
import {
  getFanbeeLinkByReleaseUuid,
  IGetFanbeeLinkByReleaseUuidResponse,
} from '../../../api/assets-api/assets/fanbee/fanbeeLinkByReleaseUuid'

export const ReleaseFanbee = ReleaseFanbeeBasic.volatile(() => ({
  loading: false,
})).actions(self => ({
  setUuid(uuid: string) {
    self.uuid = uuid
  },
  load: flow(function* () {
    if (!self.uuid) {
      return
    }

    try {
      self.loading = true

      const resp: IGetFanbeeLinkByReleaseUuidResponse = yield getFanbeeLinkByReleaseUuid(self.uuid)
      if (resp && resp.data.data?.fanbeeLinkByReleaseUuid) {
        applySnapshot(self, resp.data.data.fanbeeLinkByReleaseUuid)
      }
    } catch (err) {
      console.error(err)
    } finally {
      self.loading = false
    }
  }),
  addLink: flow(function* (input: IAddFanbeeLinkProps) {
    try {
      self.loading = true

      const resp: IAddFanbeeLinkResponse = yield addFanbeeLink(input)
      if (resp && resp.data.data?.addFanbeeLink) {
        const updatedSelf = { ...self, fanbeeLink: resp.data.data.addFanbeeLink }

        applySnapshot(self, updatedSelf)

        toast.success('Link successfully added')
      }
    } catch (err) {
      console.error(err)
    } finally {
      self.loading = false
    }
  }),
  addStreamingLink: flow(function* (link: string, store: STORES) {
    if (!self.uuid) {
      return
    }

    try {
      self.loading = true

      const props: IAddReleaseFanbeeStreamingLinkProps = {
        releaseUuid: self.uuid,
        input: { link, store },
      }

      const resp: IAddReleaseFanbeeStreamingLinkResponse = yield addReleaseFanbeeStreamingLink(props)
      if (resp && resp.data.data?.addReleaseFanbeeStreamingLink) {
        const updatedRelease = { ...self, streamingLinks: [...self.streamingLinks, { link, store }] }
        applySnapshot(self, updatedRelease)

        toast.success('Streaming link successfully added')
      }
    } catch (err) {
      console.error(err)
    } finally {
      self.loading = false
    }
  }),
  removeStreamingLink: flow(function* (store: STORES) {
    if (!self.uuid) {
      return
    }

    try {
      self.loading = true

      const props: IRemoveReleaseFanbeeStreamingLinkProps = {
        releaseUuid: self.uuid,
        store,
      }

      const resp: IRemoveReleaseFanbeeStreamingLinkResponse = yield removeReleaseFanbeeStreamingLink(props)
      if (resp && resp.data.data?.removeReleaseFanbeeStreamingLink) {
        const updatedRelease = { ...self, streamingLinks: self.streamingLinks.filter(sl => sl.store !== store) }
        applySnapshot(self, updatedRelease)

        toast.success('Streaming link successfully removed')
      }
    } catch (err) {
      console.error(err)
    } finally {
      self.loading = false
    }
  }),
  addTrackStreamingLink: flow(function* (trackUuid: string, link: string, store: STORES) {
    if (!self.uuid) {
      return
    }

    try {
      self.loading = true

      const props: IAddTrackFanbeeStreamingLinkProps = {
        trackUuid,
        input: { link, store },
      }

      const resp: IAddTrackFanbeeStreamingLinkResponse = yield addTrackFanbeeStreamingLink(props)
      if (resp && resp.data.data?.addTrackFanbeeStreamingLink) {
        const updatedTrack = self.tracks.find(track => track.uuid === trackUuid)

        if (updatedTrack) {
          updatedTrack.streamingLinks = cast([
            ...updatedTrack.streamingLinks.filter(sl => sl.store !== store),
            { link, store },
          ])

          const updatedRelease = {
            ...self,
            tracks: [...self.tracks.filter(track => track.uuid !== trackUuid), updatedTrack],
          }

          applySnapshot(self, updatedRelease)

          toast.success('Streaming link successfully added')
        }
      }
    } catch (err) {
      console.error(err)
    } finally {
      self.loading = false
    }
  }),
  removeTrackStreamingLink: flow(function* (trackUuid: string, store: STORES) {
    if (!self.uuid) {
      return
    }

    try {
      self.loading = true

      const props: IRemoveTrackFanbeeStreamingLinkProps = {
        trackUuid,
        store,
      }

      const resp: IRemoveTrackFanbeeStreamingLinkResponse = yield removeTrackFanbeeStreamingLink(props)
      if (resp && resp.data.data?.removeTrackFanbeeStreamingLink) {
        const updatedTrack = self.tracks.find(track => track.uuid === trackUuid)

        if (updatedTrack) {
          updatedTrack.streamingLinks = cast([...updatedTrack.streamingLinks.filter(sl => sl.store !== store)])

          const updatedRelease = {
            ...self,
            tracks: [...self.tracks.filter(track => track.uuid !== trackUuid), updatedTrack],
          }

          applySnapshot(self, updatedRelease)

          toast.success('Streaming link successfully added')
        }
      }
    } catch (err) {
      console.error(err)
    } finally {
      self.loading = false
    }
  }),
}))

export type IReleaseFanbee = Instance<typeof ReleaseFanbee>
