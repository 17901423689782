import { useMemo, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'

import { APPROVAL_STATES, PLATFORM_STATES } from '@epic-front/common/constants'
import { IAsset } from '@epic-front/common/models'

interface IAssetsApprovalModal {
  mode: APPROVAL_STATES | PLATFORM_STATES
  show: boolean
  hide: () => void
  onConfirm: (comment?: string) => void
  assets?: IAsset[]
  disabled?: boolean
  children?: JSX.Element
}

export const AssetsApprovalModal = ({
  mode,
  show,
  hide,
  onConfirm,
  disabled,
  assets,
  children,
}: IAssetsApprovalModal): JSX.Element => {
  const label = useMemo(() => {
    if (mode === APPROVAL_STATES.APPROVED) {
      return 'Approve'
    }
    if (mode === APPROVAL_STATES.REJECTED) {
      return 'Reject'
    }
    if (mode === PLATFORM_STATES.LIVE) {
      return 'Publish'
    }
    return ''
  }, [mode])

  const [comment, setComment] = useState<string>('')

  const handleConfirmation = () => {
    onConfirm(comment)
    // need to clear the comment otherwise next time the modal opens it will have the previous comment
    setComment('')
  }

  return (
    <Modal show={show} onHide={hide}>
      <Modal.Header closeButton>
        <h4 className="modal-title">{`${label} asset${assets?.length ? 's' : ''}`}</h4>
      </Modal.Header>
      <Modal.Body>
        {assets?.map(item => (
          <pre key={item.uuid}>{item.title}</pre>
        ))}
        <Form.Label htmlFor="approvalComment">Comment</Form.Label>
        <Form.Control
          as="textarea"
          rows={6}
          name="approvalComment"
          value={comment}
          onChange={e => setComment(e.target.value)}
        />
        {children}
      </Modal.Body>
      <Modal.Footer className="border-top bg-light">
        <Button className="btn-gray-400" onClick={hide}>
          <i className="uil-times" />
          Cancel
        </Button>
        <Button
          disabled={disabled}
          variant={mode === APPROVAL_STATES.REJECTED ? 'danger' : 'success'}
          onClick={handleConfirmation}
        >
          {`${label} asset${assets?.length ? 's' : ''}`}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
