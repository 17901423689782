import { Instance, cast, detach, flow, types } from 'mobx-state-tree'
import { toast } from 'react-toastify'

import { ASSET_ID_REGEX, ASSET_TYPE, ASSET_UUID_REGEX, ASSIGNMENT_STATUSES, SPLIT_STATUSES } from '../../../constants'

import {
  IAssignUserToVideoAssetsProps,
  assignUserToVideoAssets,
} from '../../../api/assets-api/assets/assignUserToVideoAssets'
import {
  IGetVideoAssetsProps,
  IVideoAssetsResponse,
  getVideoAssets,
} from '../../../api/assets-api/assets/getVideoAssets'
import downloadVideoAssetsCSV from '../../../api/assets-api/other/downloadVideoAssetsCSV'
import { IPagination, Pagination } from '../../general/Pagination.model'
import { VideoAsset } from './VideoAsset.model'

export const VideoAssetList = types
  .model({
    list: types.array(VideoAsset),
  })
  .volatile(() => ({
    loading: true,
    loadingExport: false,
    loadingLatestEarnings: false,
    pagination: Pagination.create({ totalItems: 0 }),
    searchFilter: '',
    multipleIdsFilter: [] as string[],
    textFilter: '',
    userUuidFilter: '',
    siteUuidFilter: '',
    monthFilter: '',
    assignmentStatusFilter: '',
    splitStatusFilter: '',
    hasCustomIdFilter: null as null | boolean,
    assetTypesFilter: [] as ASSET_TYPE[],
    isYoutubeActiveFilter: null as null | boolean,
    releaseAfterDateFilter: '',
    releaseUuidFilter: '',
    hasActiveReferenceIdFilter: null as null | boolean,
    selectedAssets: [],
  }))
  .views(self => ({
    byUuid(uuid: string | null | undefined) {
      if (!uuid) {
        return undefined
      }
      return self.list.find(el => el.uuid === uuid)
    },
    byUuids(uuids: string[]) {
      return self.list.filter(item => uuids.includes(item.uuid || ''))
    },
  }))
  .actions(self => ({
    setLoading(state: boolean) {
      self.loading = state
    },
    reset() {
      self.list = cast([])
    },
    setPagination(pagination: IPagination) {
      self.pagination = pagination
    },
    setSearchFilter(filter: string) {
      self.searchFilter = filter
    },
    setAssetTypesFilter(assetTypes: ASSET_TYPE[]) {
      self.assetTypesFilter = assetTypes
    },
    setReleaseUuidFilter(filter: string) {
      self.releaseUuidFilter = filter
    },

    setIsYoutubeActiveFilter(isActive: boolean | null) {
      self.isYoutubeActiveFilter = isActive
    },

    setTextFilter(text: string) {
      self.textFilter = text
    },

    setMultipleIdsFilter(ids: string[]) {
      self.multipleIdsFilter = ids
    },

    setMonthFilter(month: string) {
      self.monthFilter = month
    },
    setReleaseAfterDateFilter(date: string) {
      self.releaseAfterDateFilter = date
    },
    setSiteFilter(site: string) {
      self.siteUuidFilter = site
    },
    setUserUuidFilter(userUuid: string | null) {
      self.userUuidFilter = userUuid || ''
    },
    setAssignmentFilter(assignment: string) {
      self.assignmentStatusFilter = assignment
    },
    setSplitFilter(split: string) {
      self.splitStatusFilter = split
    },

    setHasCustomIdFilter(hasCustomId: boolean | null) {
      self.hasCustomIdFilter = hasCustomId
    },

    setHasActiveReferenceIdFilter(hasReference: boolean | null) {
      self.hasActiveReferenceIdFilter = hasReference
    },

    load: flow(function* (loadFragment?: string) {
      try {
        self.loading = true

        let param = ''
        if (ASSET_UUID_REGEX.test(self.textFilter)) param = 'uuid'
        else if (ASSET_ID_REGEX.test(self.textFilter)) param = 'videoId'
        else param = 'search'

        const variables: IGetVideoAssetsProps = {
          pagination: self.pagination.allQueryParams,
          filters: {
            ...(self.textFilter && { [param]: self.textFilter }),
            ...(self.multipleIdsFilter.length > 0 && { multipleIds: self.multipleIdsFilter }),
            ...(self.userUuidFilter && { userUuid: self.userUuidFilter }),
            ...(self.siteUuidFilter && { siteUuid: self.siteUuidFilter }),
            ...(self.assignmentStatusFilter && {
              isAssigned: self.assignmentStatusFilter === ASSIGNMENT_STATUSES.ASSIGNED.value,
            }),
            ...(self.splitStatusFilter && { isSplit: self.splitStatusFilter === SPLIT_STATUSES.ASSIGNED.value }),
            ...(self.hasCustomIdFilter !== null && { hasCustomId: self.hasCustomIdFilter }),
            ...(self.hasActiveReferenceIdFilter !== null && {
              hasActiveReferenceId: self.hasActiveReferenceIdFilter,
            }),
            //  default: defaultMode,
          },
        }

        const resp: IVideoAssetsResponse = yield getVideoAssets(variables, loadFragment)

        if (resp && resp.data.data?.videos) {
          detach(self.list)
          self.list = cast(resp.data.data.videos.videos)
          self.pagination.setTotalItems(resp.data.data.videos.total)
        }
        self.loading = false
      } catch (err) {
        self.loading = false
        console.error(err)
      }
    }),

    downloadExport: flow(function* () {
      try {
        self.loadingExport = true

        let param = ''
        if (ASSET_UUID_REGEX.test(self.textFilter)) param = 'uuid'
        else if (ASSET_ID_REGEX.test(self.textFilter)) param = 'videoId'
        else param = 'search'

        const filters = {
          ...(self.textFilter && { [param]: self.textFilter }),
          ...(self.multipleIdsFilter !== null &&
            self.multipleIdsFilter.length > 0 && { multipleIds: self.multipleIdsFilter }),
          ...(self.userUuidFilter && { userUuid: self.userUuidFilter }),
          ...(self.assignmentStatusFilter && {
            isAssigned: self.assignmentStatusFilter === ASSIGNMENT_STATUSES.ASSIGNED.value,
          }),
          ...(self.splitStatusFilter && { isSplit: self.splitStatusFilter === SPLIT_STATUSES.ASSIGNED.value }),
          ...(self.siteUuidFilter && { siteUuid: self.siteUuidFilter }),

          ...(self.assetTypesFilter && { assetTypes: self.assetTypesFilter }),

          ...(self.releaseUuidFilter && { releaseUuid: self.releaseUuidFilter }),
          ...(self.hasActiveReferenceIdFilter && { hasActiveReferenceId: self.hasActiveReferenceIdFilter }),
        }

        yield downloadVideoAssetsCSV(filters)

        self.loadingExport = false
      } catch (e) {
        console.error(e)
      }
    }),
  }))
  .actions(self => ({
    assignUserToVideoAssets: flow(function* (props: IAssignUserToVideoAssetsProps) {
      try {
        self.loading = true
        yield assignUserToVideoAssets(props)
        yield self.load()

        toast.success('User assigned successfully to Video!')
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),
  }))

export type IVideoAssetList = Instance<typeof VideoAssetList>
