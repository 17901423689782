import { cast, detach, flow, Instance, types } from 'mobx-state-tree'
import { toast } from 'react-toastify'

import {
  IUpdateFanbeeLinkOrderProps,
  IUpdateFanbeeLinkOrderResponse,
  updateFanbeeLinkOrder,
} from '@epic-front/common/api/users-api/fanbee/updateFanbeeLinkOrder'
import {
  fanbeeLinks,
  IGetFanbeeLinksProps,
  IGetFanbeeLinksResponse,
} from '../../../api/assets-api/assets/fanbee/getFanbeeLinks'

import { FanbeeLinkBasic } from './FanbeeLinkBasic.model'
import {
  addFanbeeLink,
  IAddFanbeeLinkProps,
  IAddFanbeeLinkResponse,
} from '../../../api/assets-api/assets/fanbee/addFanbeeLink'

import { IPagination, Pagination } from '../../general/Pagination.model'
import { IRemoveFanbeeLinkResponse, removeFanbeeLink } from '../../../api/assets-api/assets/fanbee/removeFanbeeLink'
import { FANBEE_LINK_UUID_REGEX, RELEASE_FORMATS } from '../../../constants'
import {
  IUpdateFanbeeLinkStatusProps,
  IUpdateFanbeeLinkStatusResponse,
  updateFanbeeLinkStatus,
} from '../../../api/assets-api/assets/fanbee/updateFanbeeLinkStatus'

export const FanbeeLinkList = types
  .model({
    list: types.array(FanbeeLinkBasic),
  })
  .volatile(() => ({
    loading: false,
    userUuidFilter: '',
    textFilter: '',
    typeFilter: '',
    releaseUuidFilter: '',
    releaseFormatFilter: '',
    pagination: Pagination.create({ totalItems: 0 }),
  }))
  .views(self => ({
    getByUuid(uuid: string) {
      return self.list.find(item => item.uuid === uuid)
    },
  }))
  .actions(self => ({
    getTextSearchParam() {
      if (FANBEE_LINK_UUID_REGEX.test(self.textFilter)) return 'uuid'
      return 'releaseUuid'
    },
  }))
  .actions(self => ({
    setUserUuidFilter(userUuid: string) {
      self.userUuidFilter = userUuid
    },
    setTextFilter(text: string) {
      self.textFilter = text
    },
    setTypeFilter(type: string) {
      self.typeFilter = type
    },
    setReleaseFormatFilter(releaseFormat: string) {
      self.releaseFormatFilter = releaseFormat
    },
    setPagination(pagination: IPagination) {
      self.pagination = pagination
    },
    load: flow(function* () {
      try {
        self.loading = true

        const props: IGetFanbeeLinksProps = {
          pagination: self.pagination.allQueryParams,
          filters: {
            ...(self.textFilter && { [self.getTextSearchParam()]: self.textFilter }),
            ...(self.typeFilter && { type: self.typeFilter }),
            ...(self.userUuidFilter && { userUuid: self.userUuidFilter }),
            ...(self.releaseFormatFilter && { releaseFormat: self.releaseFormatFilter }),
          },
        }

        const resp: IGetFanbeeLinksResponse = yield fanbeeLinks(props)

        if (resp && resp.data.data?.fanbeeLinks) {
          detach(self.list)
          self.list = cast(resp.data.data.fanbeeLinks.fanbeeLinks)
          self.pagination.setTotalItems(resp.data.data.fanbeeLinks.total)
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),

    addLink: flow(function* (props: IAddFanbeeLinkProps) {
      try {
        self.loading = true

        const resp: IAddFanbeeLinkResponse = yield addFanbeeLink(props)

        if (resp?.data.data?.addFanbeeLink) {
          self.list = cast([resp.data.data.addFanbeeLink, ...self.list])

          toast.success('Link created successfully')
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),

    removeLink: flow(function* (linkUuid: string) {
      try {
        self.loading = true

        const resp: IRemoveFanbeeLinkResponse = yield removeFanbeeLink({ linkUuid })

        if (resp?.data.data?.removeFanbeeLink) {
          self.list = cast(self.list.filter(link => link.uuid !== linkUuid))

          toast.success('Link removed successfully')
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),

    updateFanbeeLinkStatus: flow(function* (input: IUpdateFanbeeLinkStatusProps) {
      try {
        self.loading = true

        const resp: IUpdateFanbeeLinkStatusResponse = yield updateFanbeeLinkStatus(input)

        if (resp?.data.data?.updateFanbeeLinkStatus) {
          const updatedStatus = resp.data.data.updateFanbeeLinkStatus.status
          const link = self.getByUuid(input.uuid)

          if (updatedStatus !== null) link?.applyStatus(updatedStatus)

          toast.success('Link updated successfully')
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),

    applyFanbeeLinkOrder(rowUuid: string, newPosition: number) {
      const updatedUrls = self.list.slice()
      const movedUrlIndex = updatedUrls.findIndex(url => url.uuid === rowUuid)

      if (movedUrlIndex !== -1) {
        const [movedUrl] = updatedUrls.splice(movedUrlIndex, 1)
        updatedUrls.splice(newPosition, 0, movedUrl)
      }

      self.list = cast(updatedUrls)

      return updatedUrls.map(url => url.uuid ?? '')
    },
  }))
  .actions(self => ({
    updateFanbeeLinkOrder: flow(function* (
      rowUuid: string,
      newPosition: number,
      releaseFormat: RELEASE_FORMATS,
      userUuid: string
    ) {
      try {
        self.loading = true

        const linkOrder = self.applyFanbeeLinkOrder(rowUuid, newPosition)

        const input: IUpdateFanbeeLinkOrderProps = {
          userUuid,
          releaseFormat,
          linkOrder,
        }

        const resp: IUpdateFanbeeLinkOrderResponse = yield updateFanbeeLinkOrder(input)

        if (resp?.data.data?.updateFanbeeLinkOrder) {
          let updatedList: string[] = []

          if (releaseFormat === RELEASE_FORMATS.ALBUM) {
            updatedList = resp.data.data.updateFanbeeLinkOrder.linkOrderAlbum
          } else if (releaseFormat === RELEASE_FORMATS.EP) {
            updatedList = resp.data.data.updateFanbeeLinkOrder.linkOrderEP
          } else if (releaseFormat === RELEASE_FORMATS.SINGLE) {
            updatedList = resp.data.data.updateFanbeeLinkOrder.linkOrderSingle
          }

          self.list = cast(
            self.list.sort((a, b) => updatedList.indexOf(a.uuid ?? '') - updatedList.indexOf(b.uuid ?? ''))
          )
          toast.success('Links order updated successfully')
        }

        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),
  }))

export type IFanbeeLinkList = Instance<typeof FanbeeLinkList>
