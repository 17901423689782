import { Suspense } from 'react'
import { Card } from 'react-bootstrap'
import { Link, Outlet } from 'react-router-dom'

import { getSiteLogoLight } from '@helpers'

const loading = () => <div className="bg-dark position-absolute w-100  h-100" />

const AccountLayout = (): JSX.Element => {
  return (
    <Suspense fallback={loading()}>
      <div className="login-page align-items-center d-flex min-vh-100 bg-login text-light px-3">
        <Card
          className="mx-auto login-card-bg border-0 w-100 position-relative"
          style={{ maxWidth: '440px', zIndex: 2 }}
        >
          <Card.Header className="text-center py-1" style={{ borderColor: 'rgba(255,255,255,0.1)' }}>
            <Link to="/" className="d-block">
              <img src={getSiteLogoLight()} alt="" height="104" />
            </Link>
          </Card.Header>
          <Card.Body className="p-4 text-white">
            <Outlet />
          </Card.Body>
        </Card>
        <div className="login-background">
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
      </div>
    </Suspense>
  )
}

export default AccountLayout
