import { applySnapshot, Instance, types } from 'mobx-state-tree'
import { AssetUser, IAssetUser } from '../assets/AssetUser.model'

export const Composition = types
  .model({
    uuid: types.maybeNull(types.string),
    users: types.array(AssetUser),
    createdBy: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    updatedBy: types.maybeNull(types.string),
    updatedByCron: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    updatedFromYoutubeAt: types.maybeNull(types.string),
    deletedAt: types.maybeNull(types.string),
    deletedBy: types.maybeNull(types.string),
    cmsUuid: types.maybeNull(types.string),
    artist: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    csAssetId: types.maybeNull(types.string),
    assetId: types.maybeNull(types.string),
    csUniqueId: types.maybeNull(types.string),
    customId: types.maybeNull(types.string),
    srAssetId: types.maybeNull(types.string),
    asJson: types.maybeNull(types.string),
    srAssetUuid: types.maybeNull(types.string),
    csCustomId: types.maybeNull(types.string),
    runUuid: types.maybeNull(types.string),
    youtubeInactive: types.maybeNull(types.boolean),
    sites: types.array(types.string),
  })
  .views(self => ({
    getOwnerUuid() {
      return self.users.find(usr => usr.isOwner)?.userUuid || ''
    },
  }))
  .actions(self => ({
    updateAssetWithUsers(users: IAssetUser[]) {
      applySnapshot(self, { ...self, users })
    },
  }))

export type IComposition = Instance<typeof Composition>
