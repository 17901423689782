import { applySnapshot, getSnapshot, Instance } from 'mobx-state-tree'

import { IAssetFormData } from '../../../types/common'
import { IAssetUser } from '../AssetUser.model'
import { VideoAssetBasic } from './VideoAssetBasic.model'

export const VideoAsset = VideoAssetBasic.volatile(() => ({
  loading: false,
  loadingPatchUpdate: false,
  totalRevenue: null as number | null,
  lifetimeEarnings: null as number | null,
}))
  .views(self => ({
    getOwnerUuid() {
      return self.users.find(usr => usr.isOwner)?.userUuid
    },
  }))

  .actions(self => ({
    updateAssetWithUsers(users: IAssetUser[]) {
      applySnapshot(self, { ...self, users })
    },
    applyChanges: (info: IAssetFormData) => {
      const selfCopy = { ...getSnapshot(self) }

      applySnapshot(self, {
        ...selfCopy,
        ...{ ...info, stores: undefined },
      })
    },

    removeSplit: (splitUuid: string) => {
      const removedSplitIndex = self.users.findIndex(assetUser => assetUser.revSplitUuid === splitUuid)
      const removeSplit = self.users.splice(removedSplitIndex, 1)[0]

      const ownerIndex = self.users.findIndex(assetUser => !assetUser.revSplitUuid)
      self.users[ownerIndex].percentage = (self.users[ownerIndex].percentage || 100) + (removeSplit.percentage || 0)
    },

    setUuid(uuid: string) {
      self.uuid = uuid
    },

    setTotalRevenue(revenue: number) {
      self.totalRevenue = revenue
    },
  }))

export type IVideoAsset = Instance<typeof VideoAsset>
