import { SERVICE_NAME } from '../../constants'
import { IClaimCount } from '../../models/claims/ClaimCount.model'
import { IGenericResponse } from '../api.types'
import clientWrapper from '../clientWrapper'

export type IGetClaimsIssuesCountResponse = IGenericResponse<'claimsIssuesCount', IClaimCount[]>

export async function getClaimsIssuesCount(): Promise<IGetClaimsIssuesCountResponse> {
  const query = `
    query claimsIssuesCount {
      claimsIssuesCount {
        userUuid
        total
        byStatus {
          status
          total
        }
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, {})) as IGetClaimsIssuesCountResponse
}
