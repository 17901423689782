import { IMAGE_TYPE, IMG_MIME_TYPES } from '@epic-front/common/constants'
import { IImage, Image as ImageModel, IRelease } from '@epic-front/common/models'
import { ArrowUpCloud } from '@vectopus/atlas-icons-react'
import axios, { AxiosProgressEvent } from 'axios'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Alert, Button, ProgressBar } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import Lightbox from 'react-image-lightbox'
import { toast } from 'react-toastify'

interface IImageDropZone {
  release: IRelease
  onRemoveImg?: (uuid: string) => void
  onAddImg?: () => void
  image?: IImage | null
  minWidth?: number
  minHeight?: number
  type?: IMAGE_TYPE
}

const ImageReleaseUpload = (props: IImageDropZone): JSX.Element => {
  const [image, setImage] = useState<IImage>(ImageModel.create({ releaseUuid: props.release.uuid, type: props.type }))
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)

  useEffect(() => {
    image.setReleaseUuid(props.release.uuid || '')
  }, [props.release.uuid])

  useEffect(() => {
    if (props.image) {
      setImage(props.image)
    }
  }, [props.image])

  const onRemoveImg = () => {
    if (image.uuid && props.onRemoveImg) {
      props.onRemoveImg(image.uuid)
    }

    setImage(ImageModel.create({ releaseUuid: props.release.uuid }))
  }

  const upload = async (file: File) => {
    try {
      image.setUploading(true)
      const uploadUrl = await image.getImageUploadUrl(file.name)

      if (uploadUrl) {
        await axios.put(uploadUrl, file, {
          headers: {
            'Content-Type': file.type,
          },
          onUploadProgress: (e: AxiosProgressEvent) => {
            if (e.total) {
              setProgress(Math.round((100 * e.loaded) / e.total))
            }
          },
        })

        image.setUploading(false)
        props.release.addImageLocally(image)
        if (props.onAddImg) {
          props.onAddImg()
        }
      }
    } catch (err) {
      image.setUploading(false)
      image.setDownloadUrl('')
      console.error(err)
    }
  }

  const onDrop = (droppedFiles: File[]) => {
    if (droppedFiles.length === 1) {
      const file = droppedFiles[0]

      let img: HTMLImageElement | null = new Image()
      img.src = URL.createObjectURL(file) || ''
      img.onload = () => {
        if (
          img &&
          ((props.minWidth && img?.width < props.minWidth) || (props.minHeight && img?.height < props.minHeight))
        ) {
          onRemoveImg()
          img = null
          toast.error("Image doesn't have the required dimensions")
          return
        }
        upload(file)
      }
    }
  }

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    accept: IMG_MIME_TYPES,
    maxFiles: 1,
    disabled: image.uploading,
  })

  return (
    <>
      {(!image.downloadUrl || image.uploading) && !props.release.hasEditDisabled && (
        <div className="dropzone" {...getRootProps()}>
          <div className="dz-message">
            <input {...getInputProps()} />
            <div className="d-flex flex-column align-items-center justify-content-center gap-1">
              <ArrowUpCloud weight="bold" size={48} />
              <div className="fs-4 fw-semibold">Drag & Drop your image</div>
              <div className="text-gray-500 pb-1">or</div>
              <div className="btn btn-light shadow">Upload from your computer</div>
            </div>
          </div>
        </div>
      )}
      {image.uploading && <ProgressBar className="mt-2 progress-bar-striped" now={progress} />}
      {!!fileRejections.length && (
        <Alert variant="danger mt-2">
          The following files have invalid file formats:{' '}
          {fileRejections.map(rejection => rejection.file.name).join(', ')}
        </Alert>
      )}
      {!image.uploading && image.downloadUrl && (
        <div className="image-card border rounded-2 primary p-2 justify-content-between bg-gray-200">
          {showPreview && <Lightbox mainSrc={image.downloadUrl} onCloseRequest={() => setShowPreview(false)} />}
          <button
            className="p-0 rounded-0 bg-transparent border-0 mb-2 w-100"
            onClick={() => setShowPreview(true)}
            type="button"
          >
            <img src={image.downloadUrl} alt="cover" />
          </button>
          <div className="d-flex flex-row gap-2">
            <Button className="btn-lg flex-grow-1" onClick={() => setShowPreview(true)}>
              <i className="uil uil-search-plus" />
              <span>Zoom</span>
            </Button>
            <Button
              variant="danger"
              className="btn-lg flex-grow-1"
              onClick={() => onRemoveImg()}
              disabled={props.release.hasEditDisabled}
            >
              <i className="uil uil-trash" />
              <span>Delete</span>
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default observer(ImageReleaseUpload)
