import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { DatePicker } from '@blocks'

export const DateRangePicker = ({
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  minDate,
  active,
}: {
  startDate: Date | null
  endDate: Date | null
  onChangeStartDate?: (date: Date | null) => void
  onChangeEndDate?: (date: Date | null) => void
  minDate?: Date
  active?: boolean
}): JSX.Element => {
  const [showPickers, setShowPickers] = useState<boolean>(false)

  return (
    <>
      <Button
        variant="outline-primary"
        className="flex-grow-1 flex-xl-grow-0"
        active={active}
        onClick={() => setShowPickers(!showPickers)}
      >
        Custom dates
      </Button>
      {showPickers && (
        <div className="row row-cols-1 row-cols-sm-2 g-2 flex-grow-1 flex-xxl-grow-0">
          <div className="col" style={{ minWidth: '160px' }}>
            <DatePicker
              dateFormat="MMM d, y"
              showFullMonthYearPicker
              placeholderText="From"
              minDate={minDate}
              maxDate={endDate || new Date()}
              value={startDate}
              onChange={date => (onChangeStartDate ? onChangeStartDate(date) : null)}
            />
          </div>
          <div className="col" style={{ minWidth: '160px' }}>
            <DatePicker
              dateFormat="MMM d, y"
              showFullMonthYearPicker
              placeholderText="Until"
              minDate={startDate ?? undefined}
              maxDate={new Date()}
              value={endDate}
              onChange={date => (onChangeEndDate ? onChangeEndDate(date) : null)}
            />
          </div>
        </div>
      )}
    </>
  )
}
