import { SERVICE_NAME, STORES } from '../../../../constants'
import { IGenericResponse } from '../../../api.types'
import clientWrapper from '../../../clientWrapper'

export type IRemoveTrackFanbeeStreamingLinkResponse = IGenericResponse<'removeTrackFanbeeStreamingLink', boolean>

export type IRemoveTrackFanbeeStreamingLinkProps = {
  trackUuid: string
  store: STORES
}

export async function removeTrackFanbeeStreamingLink({
  trackUuid,
  store,
}: IRemoveTrackFanbeeStreamingLinkProps): Promise<IRemoveTrackFanbeeStreamingLinkResponse> {
  const query = `
    mutation removeTrackFanbeeStreamingLink($store: Store!, $trackUuid: String!) {
        removeTrackFanbeeStreamingLink(store: $store, trackUuid: $trackUuid)
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, {
    trackUuid,
    store,
  })) as IRemoveTrackFanbeeStreamingLinkResponse
}
