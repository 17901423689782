import { Instance, types } from 'mobx-state-tree'

import { AssetUser } from '../AssetUser.model'

import { APPROVAL_STATES, ASSET_TYPE } from '../../../constants'

export const VideoAssetBasic = types.model({
  uuid: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  deletedAt: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  assetType: types.maybeNull(types.enumeration(Object.values(ASSET_TYPE))),
  users: types.array(AssetUser),
  artist: types.maybeNull(types.string),
  videoId: types.maybeNull(types.string),
  customId: types.maybeNull(types.string),
  activeReferenceId: types.maybeNull(types.string),
  inactiveReferenceId: types.maybeNull(types.string),
  publishDate: types.maybeNull(types.string),
  sites: types.array(types.string),
  assetState: types.maybeNull(types.enumeration(Object.values(APPROVAL_STATES))),
  approvalComment: types.maybeNull(types.string),
  approvedAt: types.maybeNull(types.string),
  rejectedAt: types.maybeNull(types.string),
  approvalEmailSentAt: types.maybeNull(types.string),
  channelId: types.maybeNull(types.string),
  totalViews: types.maybeNull(types.number),
  totalClaims: types.maybeNull(types.number),
  createdBy: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  advisory: types.maybeNull(types.string),
  asJson: types.maybeNull(types.string),
  youtubeInactive: types.maybeNull(types.boolean),
  cmsUuid: types.maybeNull(types.string),
  assetId: types.maybeNull(types.string),
})

export type IVideoAssetBasic = Instance<typeof VideoAssetBasic>
