import { SERVICE_NAME } from '../../../../constants'
import { IComposition } from '../../../../models/claims/Composition.model'
import { IPaginationParams } from '../../../../types'
import { IGenericResponse } from '../../../api.types'
import clientWrapper from '../../../clientWrapper'
import { compositionFragment } from '../../fragmentsAssets'

export type IGetCompositionsResponse = IGenericResponse<
  'compositions',
  {
    assets: IComposition[]
    total: number
  }
>

export type IGetCompositionsProps = {
  pagination?: IPaginationParams
  filters?: {
    uuid?: string
    assetId?: string
    title?: string
    userUuid?: string
    siteUuids?: string[]
    isAssigned?: boolean
    multipleIds?: string[]
  }
}

export async function getCompositions(
  { pagination, filters }: IGetCompositionsProps,
  fragment: string = compositionFragment
): Promise<IGetCompositionsResponse> {
  const query = `
    query Compositions($pagination: PaginationInput, $filters: CompositionsFilterInput) {
        compositions(pagination: $pagination, filters: $filters) {
            assets {
              ${fragment}
            }
            total
        }
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, { pagination, filters })) as IGetCompositionsResponse
}
