import clientWrapper from '../../clientWrapper'
import { IGenericResponse } from '../../api.types'

import { SERVICE_NAME } from '../../../constants'

import { IFanbeeProfileBasic } from '../../../models/fanbee/FanbeeProfileBasic.model'

import { fanbeeProfileFragment } from '../fragments'

export type IFanbeeProfileResponse = IGenericResponse<'fanbeeProfile', IFanbeeProfileBasic>

export async function fanbeeProfile(
  userUuid: string,
  fragment: string = fanbeeProfileFragment
): Promise<IFanbeeProfileResponse> {
  const query = `query fanbeeProfile($userUuid:String!){
    fanbeeProfile(userUuid:$userUuid) {
      ${fragment}
    }
  }`

  return (await clientWrapper(SERVICE_NAME.USERS, query, { userUuid })) as IFanbeeProfileResponse
}
