import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import { FanbeeProfile, IFanbeeProfile } from '@epic-front/common/models/fanbee/FanbeeProfile.model'

import { IUserBasic } from '@epic-front/common/models/users/UserBasic.model'
import FanbeeProfileUpdate from './FanbeeProfileUpdate'

type IProps = {
  user: IUserBasic
}

const FanbeeCard = ({ user }: IProps): JSX.Element => {
  const [fanbeeProfile] = useState<IFanbeeProfile>(FanbeeProfile.create())

  useEffect(() => {
    if (user.uuid) {
      fanbeeProfile.load(user.uuid)
    }
  }, [])

  if (!user.uuid) {
    return <></>
  }

  return <FanbeeProfileUpdate fanbeeProfile={fanbeeProfile} />
}

export default observer(FanbeeCard)
