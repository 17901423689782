import { Instance, cast, destroy, detach, flow, types } from 'mobx-state-tree'
import { toast } from 'react-toastify'

import { changeAssetsState } from '../../../api/assets-api'
import { IChangeAssetStatesResponse } from '../../../api/types'
import {
  APPROVAL_STATES,
  ASSET_ID_REGEX,
  ASSET_TYPE,
  ASSET_UUID_REGEX,
  ASSIGNMENT_STATUSES,
  DELIVERY_PLATFORM,
  PLATFORM_STATES,
  SPLIT_STATUSES,
  STORES,
  STORE_DELIVERY_STATE,
} from '../../../constants'

import { IGetReleasesResponse, getReleases } from '../../../api/assets-api/assets/getReleases'
import { releaseFragmentWithValidationErrors } from '../../../api/assets-api/fragmentsAssets'
import downloadAssetsXLSX from '../../../api/assets-api/other/downloadAssetsXLSX'
import { IAssetFormData } from '../../../types/common'
import { IPagination, Pagination } from '../../general/Pagination.model'
import { Store } from '../StoreModel'
import { IRelease, Release } from './Release.model'

export const ReleaseList = types
  .model({
    list: types.array(Release),
  })
  .volatile(() => ({
    loading: true,
    loadingExport: false,
    loadingLatestEarnings: false,
    pagination: Pagination.create({ totalItems: 0 }),
    searchFilter: '',
    platformStatesFilter: [] as PLATFORM_STATES[],
    platformNameFilter: null as null | DELIVERY_PLATFORM,
    storeFilter: [] as STORES[],
    assetStatesFilter: [] as APPROVAL_STATES[],
    assetTypesFilter: [] as ASSET_TYPE[],
    multipleIdsFilter: [] as string[],
    textFilter: '',
    userUuidFilter: '',
    siteUuidFilter: '',
    monthFilter: '',
    assignmentStatusFilter: '',
    splitStatusFilter: '',
    compositionShareFilter: null as null | boolean,
    hasActiveReferenceIdFilter: null as null | boolean,
    hasCustomIdFilter: null as null | boolean,
    onlyForTrackGuest: null as null | boolean,
    isCoverFilter: null as null | boolean,
    isCreatedByUsFilter: null as null | boolean,
    releaseAfterDateFilter: '',
    defaultAssetsMode: true, // ignore any client filters for the be when getting assets
    hasDefaultPlatformStates: true, // if true will send All approve state filter instead of null
    importSourceFilter: '',
    upcFilter: '',
    releaseUuidFilter: '',

    selectedAssets: [],
  }))
  .actions(self => ({
    setLoading(state: boolean) {
      self.loading = state
    },
    reset() {
      self.list = cast([])
    },
    setPagination(pagination: IPagination) {
      self.pagination = pagination
    },
    setSearchFilter(filter: string) {
      self.searchFilter = filter
    },
    setReleaseUuidFilter(filter: string) {
      self.releaseUuidFilter = filter
    },
    setIsCoverFilter(isCover: boolean) {
      self.isCoverFilter = isCover
    },
    setIsCreatedByUsFilter(isCreatedByUs: boolean) {
      self.isCreatedByUsFilter = isCreatedByUs
    },
    setPlatformNameFilter(platform: null | DELIVERY_PLATFORM) {
      self.platformNameFilter = platform
    },
    setPlatformStatesFilter(states: PLATFORM_STATES[]) {
      self.platformStatesFilter = states
    },
    setAssetStatesFilter(states: APPROVAL_STATES[]) {
      self.assetStatesFilter = states
    },
    setStoresFilter(stores: STORES[]) {
      self.storeFilter = stores
    },

    setTextFilter(text: string) {
      self.textFilter = text
    },
    setUPCFilter(text: string) {
      self.upcFilter = text
    },
    setMultipleIdsFilter(ids: string[]) {
      self.multipleIdsFilter = ids
    },
    setImportSourceFilter(source: string) {
      self.importSourceFilter = source
    },
    setMonthFilter(month: string) {
      self.monthFilter = month
    },
    setReleaseAfterDateFilter(date: string) {
      self.releaseAfterDateFilter = date
    },
    setSiteFilter(site: string) {
      self.siteUuidFilter = site
    },
    setUserUuidFilter(userUuid: string | null) {
      self.userUuidFilter = userUuid || ''
    },
    setAssignmentFilter(assignment: string) {
      self.assignmentStatusFilter = assignment
    },
    setSplitFilter(split: string) {
      self.splitStatusFilter = split
    },
    setCompositionShareFilter(state: boolean | null) {
      self.compositionShareFilter = state
    },
    setHasActiveReferenceIdFilter(hasReference: boolean | null) {
      self.hasActiveReferenceIdFilter = hasReference
    },
    setHasCustomIdFilter(hasCustomId: boolean | null) {
      self.hasCustomIdFilter = hasCustomId
    },
    setHasDefaultPlatformStates(state: boolean) {
      self.hasDefaultPlatformStates = state
    },
    setOnlyForTrackGuest(state: boolean | null) {
      self.onlyForTrackGuest = state
    },
    load: flow(function* (loadFragment?: string) {
      try {
        self.loading = true

        let param = ''
        if (ASSET_UUID_REGEX.test(self.textFilter)) param = 'uuid'
        else if (ASSET_ID_REGEX.test(self.textFilter)) param = 'assetId'
        else param = 'search'

        const variables = {
          pagination: self.pagination.allQueryParams,
          filters: {
            ...(self.textFilter && { [param]: self.textFilter }),
            ...(self.multipleIdsFilter.length > 0 && { multipleIds: self.multipleIdsFilter }),
            ...(self.monthFilter && { month: self.monthFilter }),
            ...(self.releaseAfterDateFilter && { releaseAfterDate: self.releaseAfterDateFilter }),
            ...(self.isCoverFilter && { isCover: self.isCoverFilter }),
            ...(self.isCreatedByUsFilter && { isCreatedByUs: self.isCreatedByUsFilter }),
            ...(self.userUuidFilter && { userUuid: self.userUuidFilter }),
            ...(self.siteUuidFilter && { siteUuid: self.siteUuidFilter }),
            ...(self.upcFilter && { upc: self.upcFilter }),
            ...(self.importSourceFilter && { importSource: self.importSourceFilter }),
            ...(self.assignmentStatusFilter && {
              isAssigned: self.assignmentStatusFilter === ASSIGNMENT_STATUSES.ASSIGNED.value,
            }),
            ...(self.splitStatusFilter && { isSplit: self.splitStatusFilter === SPLIT_STATUSES.ASSIGNED.value }),
            ...(self.compositionShareFilter !== null && { isCompositionShare: self.compositionShareFilter }),
            ...(self.hasActiveReferenceIdFilter !== null && {
              hasActiveReferenceId: self.hasActiveReferenceIdFilter,
            }),
            ...(self.hasCustomIdFilter !== null && { hasCustomId: self.hasCustomIdFilter }),
            ...(!!self.assetStatesFilter.length && { assetStates: self.assetStatesFilter }),
            ...(!!self.storeFilter.length && { stores: self.storeFilter }),
            ...(self.releaseUuidFilter && { releaseUuid: self.releaseUuidFilter }),

            ...(self.platformStatesFilter !== null &&
              self.platformStatesFilter.length > 0 && { platformStates: self.platformStatesFilter }),

            ...(self.platformNameFilter && { platformName: self.platformNameFilter }),
            ...(self.onlyForTrackGuest && { onlyForTrackGuest: self.onlyForTrackGuest }),
          },
        }

        const resp: IGetReleasesResponse = yield getReleases(variables, loadFragment)

        if (resp && resp.data.data?.releases) {
          detach(self.list)
          self.list = cast(resp.data.data.releases.releases)
          self.pagination.setTotalItems(resp.data.data.releases.total)
        }
        self.loading = false
      } catch (err) {
        self.loading = false
        console.error(err)
      }
    }),

    downloadExport: flow(function* () {
      try {
        self.loadingExport = true

        let param = ''
        if (ASSET_UUID_REGEX.test(self.textFilter)) param = 'uuid'
        else if (ASSET_ID_REGEX.test(self.textFilter)) param = 'assetId'
        else param = 'search'

        const filters = {
          ...(self.platformNameFilter && { platformName: self.platformNameFilter }),
          ...(!!self.platformStatesFilter.length && { platformStates: self.platformStatesFilter }),

          ...(!!self.assetStatesFilter.length && { assetStates: self.assetStatesFilter }),

          ...(self.textFilter && { [param]: self.textFilter }),
          ...(self.multipleIdsFilter !== null &&
            self.multipleIdsFilter.length > 0 && { multipleIds: self.multipleIdsFilter }),
          ...(self.userUuidFilter && { userUuid: self.userUuidFilter }),
          ...(self.assignmentStatusFilter && {
            isAssigned: self.assignmentStatusFilter === ASSIGNMENT_STATUSES.ASSIGNED.value,
          }),
          ...(self.splitStatusFilter && { isSplit: self.splitStatusFilter === SPLIT_STATUSES.ASSIGNED.value }),
          ...(self.siteUuidFilter && { siteUuid: self.siteUuidFilter }),
          ...(!!self.storeFilter.length && { stores: self.storeFilter }),
          ...(self.assetTypesFilter && { assetTypes: self.assetTypesFilter }),
          ...(self.compositionShareFilter === false && { isCompositionShare: false }),
          ...(self.hasActiveReferenceIdFilter && { hasActiveReferenceId: self.hasActiveReferenceIdFilter }),
          ...(self.releaseUuidFilter && { releaseUuid: self.releaseUuidFilter }),
        }

        yield downloadAssetsXLSX(filters)

        self.loadingExport = false
      } catch (e) {
        console.error(e)
      }
    }),
  }))
  .views(self => ({
    byUuid(uuid: string | null | undefined) {
      if (!uuid) {
        return undefined
      }
      return self.list.find(el => el.uuid === uuid)
    },
    byUuids(uuids: string[]) {
      return self.list.filter(item => uuids.includes(item.uuid || ''))
    },

    get isLoading() {
      return self.loading
    },
    get uuids() {
      return self.list.map(asset => asset.uuid)
    },

    get length() {
      return self.list.length
    },

    get totalUploadProgress() {
      const assetsUploading = self.list
        .filter(asset => asset.fileUploadProgress !== null && asset.fileUploadProgress >= 0)
        .map(asset => asset.fileUploadProgress)

      const sum = assetsUploading.reduce((arr, total) => (arr || 0) + (total || 0), 0) || 0
      return sum / (assetsUploading.length || 1)
    },
  }))
  .actions(() => ({
    // ? this is not integrated/ related with this list model
    bulkChangeState: flow(function* (uuids: string[], mode: APPROVAL_STATES, comment: string, successMessage?: string) {
      try {
        const patch = uuids.map(uuid => ({
          itemId: uuid,
          targetState: mode,
          comment,
        }))
        const resp: IChangeAssetStatesResponse = yield changeAssetsState(patch)
        if (resp && resp.data.data?.changeAssetsState.length && successMessage) {
          toast.success(successMessage)
        }
      } catch (err) {
        console.error(err)
      }
    }),

    destroyAsset(asset: IRelease) {
      destroy(asset)
    },
  }))
  .actions(self => ({
    addAsset(info: IAssetFormData, addInFront?: boolean): Promise<string | null> {
      // On creation we need to provide a temporary ID, so React doesn't get a keyError
      // Those IDs are swapped to the real uuids from the Backend
      // And only then they are pushed

      const req = { ...info }

      const current = Release.create({
        ...{
          ...req,
          stores: info.stores
            ? info.stores.map(store =>
                Store.create({ deliveryPlatform: null, name: store, status: STORE_DELIVERY_STATE.PENDING })
              )
            : [],
        }, // add the store here
        cYear: info.cYear ? parseInt(info.cYear || '', 10) : undefined,
        pYear: info.pYear ? parseInt(info.pYear || '', 10) : undefined,
      })

      // we don't want send this to server as it's handled  by uploadFile function
      delete req.fileName
      delete req.fileSizeInBytes

      if (addInFront) {
        self.list.unshift(current)
      } else {
        self.list.push(current)
      }

      return current.createUpdate({
        info: req,
        successMessage: 'Release created',
        fragment: releaseFragmentWithValidationErrors,
      }) // returns uuid promise
    },
    addMSTAsset(asset: IRelease) {
      self.list.push(asset)
    },
  }))

export type IReleaseList = Instance<typeof ReleaseList>
