import { SERVICE_NAME, STORES } from '../../../../constants'
import { IGenericResponse } from '../../../api.types'
import clientWrapper from '../../../clientWrapper'

export type IRemoveReleaseFanbeeStreamingLinkResponse = IGenericResponse<'removeReleaseFanbeeStreamingLink', boolean>

export type IRemoveReleaseFanbeeStreamingLinkProps = {
  releaseUuid: string
  store: STORES
}

export async function removeReleaseFanbeeStreamingLink({
  releaseUuid,
  store,
}: IRemoveReleaseFanbeeStreamingLinkProps): Promise<IRemoveReleaseFanbeeStreamingLinkResponse> {
  const query = `
    mutation removeReleaseFanbeeStreamingLink($store: Store!, $releaseUuid: String!) {
        removeReleaseFanbeeStreamingLink(store: $store, releaseUuid: $releaseUuid)
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, {
    releaseUuid,
    store,
  })) as IRemoveReleaseFanbeeStreamingLinkResponse
}
