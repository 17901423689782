import axios from 'axios'
import { toast } from 'react-toastify'
import { URL_ASSETS_API } from '@epic-front/common/constants'
import { downloadXLSX, getLocalToken } from '../../../helpers/utils'
import { IGetCompositionsProps } from '../assets/composition/getCompositions'

const downloadCompositionsXLSX = (filters: IGetCompositionsProps['filters']): Promise<boolean> => {
  const download = async () => {
    try {
      const token = getLocalToken()
      const SITE_TOKEN = process.env.REACT_APP_SITE_TOKEN

      const res = await axios({
        url: `${URL_ASSETS_API}/export/compositionsList`,
        method: 'get',
        headers: { sitetoken: SITE_TOKEN || '', authorization: token || undefined },
        params: { ...filters },
        responseType: 'blob',
      })

      downloadXLSX(res.data, 'Compositions')
    } catch (e) {
      console.error(e)
      toast.error('There was a problem while generating the compositions XLSX.')
    }

    return true
  }

  return download()
}

export default downloadCompositionsXLSX
