import { SERVICE_NAME } from '../../../constants'
import { IUserBasic } from '../../../models/users/UserBasic.model'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { extendedUserFragment } from '../fragments'

export type IGetManagedUsersResponse = IGenericResponse<'managedUsers', [IUserBasic]>

export type IGetManagedUsersProps = {
  managerUuid: string
}

export async function getManagedUsers(
  { managerUuid }: IGetManagedUsersProps,
  fragment: string = extendedUserFragment
): Promise<IGetManagedUsersResponse> {
  const query = `query managedUsers($managerUuid:String!){
    managedUsers(managerUuid:$managerUuid) {
      ${fragment}
    }
  }`

  return (await clientWrapper(SERVICE_NAME.USERS, query, { managerUuid }, undefined, true)) as IGetManagedUsersResponse
}
