import { SERVICE_NAME } from '../../../../constants'

import { IFanbeeLinkBasic } from '../../../../models/assets/releases/FanbeeLinkBasic.model'
import { IPaginationParams } from '../../../../types'

import { IGenericResponse } from '../../../api.types'
import clientWrapper from '../../../clientWrapper'

import { fanbeeExtendedLinkFragment } from '../../fragmentsAssets'

export type IGetFanbeeLinksResponse = IGenericResponse<
  'fanbeeLinks',
  {
    total: number
    fanbeeLinks: IFanbeeLinkBasic[]
  }
>

export type IGetFanbeeLinksProps = {
  pagination?: IPaginationParams
  filters?: {
    userUuid?: string
    releaseUuid?: string
    type?: string
    uuid?: string
    releaseFormat?: string
  }
}

export async function fanbeeLinks(
  input: IGetFanbeeLinksProps,
  fragment: string = fanbeeExtendedLinkFragment
): Promise<IGetFanbeeLinksResponse> {
  const query = `query fanbeeLinks($pagination: PaginationInput, $filters: FanbeeLinksFilters) {
        fanbeeLinks(pagination: $pagination, filters: $filters) {
            total
            fanbeeLinks {
                ${fragment}
            }
        }
    }
  `

  return (await clientWrapper(SERVICE_NAME.ASSETS, query, input)) as IGetFanbeeLinksResponse
}
