import { Instance, types } from 'mobx-state-tree'

export const FanbeeUrl = types.model({
  uuid: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  userUuid: types.maybeNull(types.string),
  platformSlug: types.maybeNull(types.string),
})

export type IFanbeeUrl = Instance<typeof FanbeeUrl>
