import { PERMISSIONS } from 'e2e-utils'
import { ELITE_ALLIANCE_MUSIC_ROUTES } from '../../routes/platformRoutes/eliteAllianceMusicRoutes'
import { IMenuItem } from './menu.types'

const MENU_ITEMS_ELITE_ALLIANCE_MUSIC: IMenuItem[] = [
  { key: 'elite-alliance-music-dashboard', name: 'EAM Dashboard', isTitle: true },
  {
    ...ELITE_ALLIANCE_MUSIC_ROUTES.DASHBOARD,
    icon: 'uil-home-alt',
  },
  {
    key: 'contentId',
    name: 'Content ID',
    icon: 'uil-wallet',
    permissionsOr: [
      PERMISSIONS.ASSET.LIST_SELF,
      PERMISSIONS.ASSET.MANAGE_SELF,
      PERMISSIONS.CLAIM.LIST_SELF,
      PERMISSIONS.CLAIM.RELEASE_SELF,
    ],
    children: [
      ELITE_ALLIANCE_MUSIC_ROUTES.ASSETS_CONTENT_ID,
      ELITE_ALLIANCE_MUSIC_ROUTES.CLAIMS_CONTENT_ID,
      // ELITE_ALLIANCE_MUSIC_ROUTES.DISPUTED_CLAIMS_CONTENT_ID,
    ],
  },
  {
    key: 'upload-assets',
    name: 'Assets',
    icon: 'uil-headphones',
    permissionsOr: [PERMISSIONS.ASSET.MANAGE_ALL, PERMISSIONS.ASSET.MANAGE_SELF],
    children: [ELITE_ALLIANCE_MUSIC_ROUTES.AUDIO_UPLOAD, ELITE_ALLIANCE_MUSIC_ROUTES.AUDIO_LIST],
  },
  {
    key: 'channelWhitelisting',
    name: 'Channel Whitelisting',
    icon: 'uil-check',
    permissionsOr: [PERMISSIONS.CHANNEL_WHITELISTING.LIST_SELF, PERMISSIONS.CHANNEL_WHITELISTING.MANAGE_SELF],
    children: [ELITE_ALLIANCE_MUSIC_ROUTES.CHANNEL_WHITELISTING],
  },
  {
    key: 'payment',
    name: 'Payments',
    icon: 'uil-money-bill',
    permissionsOr: [PERMISSIONS.EARNING.REPORT_EXPORT, PERMISSIONS.EARNING.LIST_SELF],
    children: [ELITE_ALLIANCE_MUSIC_ROUTES.MONTHLY_REPORT, ELITE_ALLIANCE_MUSIC_ROUTES.TAX_FORMS],
  },
]

export default MENU_ITEMS_ELITE_ALLIANCE_MUSIC
