import classNames from 'classnames'
import { getter } from 'property-expr'
import { ReactNode, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { Required } from '../widgets'

type IFormError = {
  message?: string
}

type IFormErrors = {
  [key: string]: IFormError
}

type IFormData = {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  register?: any
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  errors?: any
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  control?: any
}

type IFormInputProps = {
  label?: string
  labelNode?: ReactNode
  type?: string
  name: string
  disabled?: boolean
  placeholder?: string
  helperText?: string
  defaultValue?: string | number
  // disabling the any for now more details on how is should look here
  // https://react-hook-form.com/api/useform/register/
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  register?: any
  required?: boolean
  errors?: IFormErrors
  className?: string
  labelClassName?: string
  containerClass?: string
  refCallback?: ((ref: HTMLInputElement | null) => void) | React.RefObject<HTMLInputElement> | null | undefined
  children?: ReactNode
  labelChild?: ReactNode
  // we need this to accept multiple form structure
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  control?: any
  form?: IFormData
  floatingLabel?: boolean
  min?: number | string
  max?: number | string
  size?: 'sm' | 'lg'
}

function createFieldGetter(name: string) {
  // based on what I saw in https://github.com/jquense/yup/blob/master/src/Reference.ts
  return getter(
    name,
    true /* true - meaning: safe - will not cause errors on intermediate nullish properties; similar to `?.` */
  )
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
function checkHasErrors(errors: IFormErrors | undefined, fieldGetter: (data: any) => any) {
  return errors && fieldGetter(errors) !== undefined
}

const FormInput = ({
  defaultValue,
  label,
  type,
  name,
  disabled,
  placeholder,
  register,
  required,
  errors,
  className,
  labelClassName,
  containerClass,
  refCallback,
  children,
  labelChild,
  helperText,
  form,
  floatingLabel,
  labelNode,
  size,
  ...otherProps
}: IFormInputProps): JSX.Element => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  // eslint-disable-next-line no-param-reassign
  //  errors = errors ?? Array.isArray(form?.errors) ? undefined : form?.errors
  // eslint-disable-next-line no-param-reassign
  errors = errors ?? form?.errors
  // eslint-disable-next-line no-param-reassign
  register = register ?? form?.register
  const fieldGetter = createFieldGetter(name)
  const hasErrors = checkHasErrors(errors, fieldGetter)

  // handle input type
  const comp = type === 'textarea' || type === 'select' ? type : 'input'

  return (
    <>
      {type === 'hidden' ? (
        <input type={type} name={name} {...(register ? register(name) : {})} {...otherProps} />
      ) : (
        <>
          {type === 'password' ? (
            <>
              <Form.Group className={classNames(containerClass)}>
                {label && !floatingLabel && (
                  <>
                    <Form.Label className={labelClassName}>
                      {label}
                      {required && <Required />}
                    </Form.Label>
                    {children}
                  </>
                )}

                <InputGroup className={classNames('mb-0')}>
                  {floatingLabel && (
                    <Form.Floating className="flex-grow-1">
                      <Form.Control
                        type={showPassword ? 'text' : 'password'}
                        placeholder={placeholder}
                        name={name}
                        id={name}
                        as="input"
                        ref={refCallback || undefined}
                        className={classNames(className, { [`form-control-${size}`]: size })}
                        isInvalid={hasErrors}
                        {...(register ? register(name) : {})}
                        autoComplete={name}
                        {...otherProps}
                      />
                      {label && floatingLabel && (
                        <Form.Label>
                          {label}
                          {required && <Required />}
                        </Form.Label>
                      )}
                    </Form.Floating>
                  )}

                  {!floatingLabel && (
                    <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      placeholder={placeholder}
                      name={name}
                      id={name}
                      as="input"
                      ref={refCallback || undefined}
                      className={classNames(className, { [`form-control-${size}`]: size })}
                      isInvalid={hasErrors}
                      {...(register ? register(name) : {})}
                      autoComplete={name}
                      {...otherProps}
                    />
                  )}
                  <div
                    className={classNames('input-group-text', 'input-group-password', {
                      'show-password': showPassword,
                    })}
                    data-password={showPassword ? 'true' : 'false'}
                  >
                    {/* disabling the jsx-a11y/click-events-have-key-events and jsx-a11y/no-static-element-interactions rules
          in order to keep the theme style */}
                    {/* eslint-disable-next-line */}
                    <span
                      className="uil-eye"
                      onClick={() => {
                        setShowPassword(!showPassword)
                      }}
                    />
                  </div>
                </InputGroup>

                {hasErrors && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {fieldGetter(errors).message}
                  </Form.Control.Feedback>
                )}
                {helperText && <div className="form-text">{helperText}</div>}
              </Form.Group>
            </>
          ) : (
            <>
              {type === 'checkbox' || type === 'radio' ? (
                <>
                  <Form.Group className={containerClass}>
                    <Form.Check
                      type={type}
                      label={labelNode ?? label}
                      name={name}
                      id={name}
                      ref={refCallback || undefined}
                      className={className}
                      isInvalid={hasErrors}
                      {...(disabled ? { disabled: true } : {})}
                      {...(register ? register(name) : {})}
                      {...otherProps}
                    />

                    {hasErrors ? (
                      <Form.Control.Feedback className="d-block" type="invalid">
                        {fieldGetter(errors).message}
                      </Form.Control.Feedback>
                    ) : null}
                    {helperText && <div className="form-text">{helperText}</div>}
                  </Form.Group>
                </>
              ) : (
                <Form.Group className={classNames(containerClass, { 'form-floating': !!floatingLabel })}>
                  {label && !floatingLabel && (
                    <Form.Label className={labelClassName}>
                      {label}
                      {required && <Required />}
                      {labelChild}
                    </Form.Label>
                  )}
                  <Form.Control
                    type={type}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    name={name}
                    id={name}
                    as={comp}
                    ref={refCallback || undefined}
                    className={classNames(className, { [`form-control-${size}`]: size })}
                    isInvalid={hasErrors}
                    {...(disabled ? { disabled: true } : {})}
                    {...(register ? register(name) : {})}
                    {...otherProps}
                    autoComplete={name}
                  >
                    {children}
                  </Form.Control>
                  {label && floatingLabel && (
                    <Form.Label className={labelClassName}>
                      {label}
                      {required && <Required />}
                      {labelChild}
                    </Form.Label>
                  )}
                  {hasErrors ? (
                    <Form.Control.Feedback type="invalid">{fieldGetter(errors).message}</Form.Control.Feedback>
                  ) : null}
                  {helperText && <div className="form-text">{helperText}</div>}
                </Form.Group>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default FormInput
