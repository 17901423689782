import { Instance, types } from 'mobx-state-tree'
import { ClaimCount } from './ClaimCount.model'
import { ClaimManager } from './ClaimManager.model'
import { User } from '../users/User.model'

export const ClaimManagerTable = types.model({
  userUuid: types.maybeNull(types.string),
  user: types.maybeNull(User),
  claimManager: types.maybeNull(ClaimManager),
  claimCount: types.maybeNull(ClaimCount),
})

export type IClaimManagerTable = Instance<typeof ClaimManagerTable>
