import { findMenuItem, findParent, getChildMenuList } from '@helpers'
import { intersectionBy } from 'lodash'
import { observer } from 'mobx-react-lite'
import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { IMenuItem } from '../../constants/menus/menu.types'
import { useStore } from '../../hooks'
import MenuItem from './MenuItem'
import MenuItemWithChildren from './MenuItemWithChildren'

/**
 * Renders the application menu
 */

interface IAppMenuProps {
  menuItems: IMenuItem[]
}

const Menu = ({ menuItems }: IAppMenuProps): JSX.Element => {
  const { auth, channels } = useStore()
  const location = useLocation()
  const menuRef = useRef<HTMLUListElement | null>(null)

  const [activeMenuItems, setActiveMenuItems] = useState<string[]>([])

  /*
   * toggle the menus
   */
  const toggleMenu = (menuItem: IMenuItem, show: boolean) => {
    if (show) setActiveMenuItems([menuItem.key, ...findParent(menuItems, menuItem)])
  }

  /**
   * activate the menuItems
   */
  const activeMenu = useCallback(() => {
    const div = document.getElementById('main-side-menu')
    let matchingMenuItem = null
    if (div) {
      const items = div.getElementsByClassName('side-nav-link-ref') as HTMLCollectionOf<HTMLAnchorElement>
      for (let i = 0; i < items.length; i += 1) {
        if (location.pathname === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }

      if (matchingMenuItem) {
        const mid = matchingMenuItem.getAttribute('data-menu-key')
        const activeMt = findMenuItem(menuItems, mid)
        if (activeMt) {
          setActiveMenuItems([activeMt.key, ...findParent(menuItems, activeMt)])
        }
      }
    }
  }, [location.pathname, menuItems])

  useEffect(() => {
    activeMenu()
  }, [activeMenu])

  return (
    <>
      <ul className="side-nav" ref={menuRef} id="main-side-menu">
        {(menuItems || []).map((item: IMenuItem) => {
          return (
            <Fragment key={item.key}>
              <>
                {((!item.roles && !item.permissionsOr && !item.permissionsAnd) ||
                  (item.roles && intersectionBy(item.roles, auth.roleNames).length > 0) ||
                  (item.permissionsOr && intersectionBy(auth.allPermissions, item.permissionsOr).length > 0) ||
                  (item.permissionsAnd &&
                    item.permissionsAnd.map(perm => auth.hasPermission(perm)).reduce((aggr, curr) => aggr && curr))) &&
                  (!item.shouldShow || (auth.actingUser && item.shouldShow(auth.actingUser))) && (
                    <>
                      {item.isTitle ? (
                        <li className="side-nav-title side-nav-item">{item.name}</li>
                      ) : (
                        <>
                          {getChildMenuList(item, auth?.actingUser, channels?.list).children ? (
                            <MenuItemWithChildren
                              item={getChildMenuList(item, auth?.actingUser, channels?.list)}
                              toggleMenu={toggleMenu}
                              subMenuClassNames="side-nav-second-level"
                              activeMenuItems={activeMenuItems}
                              linkClassName="side-nav-link"
                            />
                          ) : (
                            <MenuItem
                              item={item}
                              linkClassName="side-nav-link"
                              className={activeMenuItems.includes(item.key) ? 'menuitem-active' : ''}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
              </>
            </Fragment>
          )
        })}
      </ul>
    </>
  )
}

export default observer(Menu)
