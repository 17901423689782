import clientWrapper from '../../clientWrapper'
import { IGenericResponse } from '../../api.types'

import { SERVICE_NAME } from '../../../constants'

import { IFanbeeProfileBasic } from '../../../models/fanbee/FanbeeProfileBasic.model'

import { fanbeeProfileFragment } from '../fragments'
import { IPaginationParams } from '../../../types'

export type IFanbeeProfilesResponse = IGenericResponse<
  'fanbeeProfiles',
  { profiles: IFanbeeProfileBasic[]; total: number }
>

export type IGetFanbeeProfilesProps = {
  pagination?: IPaginationParams
  filters?: {
    userUuid?: string
  }
}

export async function fanbeeProfiles(
  props: IGetFanbeeProfilesProps,
  fragment: string = fanbeeProfileFragment
): Promise<IFanbeeProfilesResponse> {
  const query = `query fanbeeProfiles ($pagination: PaginationInput, $filters: FanbeeProfilesFiltersInput){
    fanbeeProfiles (pagination: $pagination, filters: $filters) {
      total
      profiles {
        ${fragment}
      }
    }
  }`

  return (await clientWrapper(SERVICE_NAME.USERS, query, props)) as IFanbeeProfilesResponse
}
