import clientWrapper from '../../clientWrapper'
import { IGenericResponse } from '../../api.types'

import { RELEASE_FORMATS, SERVICE_NAME } from '../../../constants'

import { IFanbeeProfileBasic } from '../../../models/fanbee/FanbeeProfileBasic.model'
import { fanbeeProfileFragment } from '../fragments'

export type IUpdateFanbeeLinkOrderProps = {
  userUuid: string
  releaseFormat: RELEASE_FORMATS
  linkOrder: string[]
}

export type IUpdateFanbeeLinkOrderResponse = IGenericResponse<'updateFanbeeLinkOrder', IFanbeeProfileBasic>

export async function updateFanbeeLinkOrder(
  data: IUpdateFanbeeLinkOrderProps,
  fragment: string = fanbeeProfileFragment
): Promise<IUpdateFanbeeLinkOrderResponse> {
  const query = `
    mutation UpdateFanbeeLinkOrder($data: FanbeeLinkOrderInput!) {
      updateFanbeeLinkOrder(data: $data) {
        ${fragment}
      }
    }
  `

  return (await clientWrapper(SERVICE_NAME.USERS, query, { data })) as IUpdateFanbeeLinkOrderResponse
}
