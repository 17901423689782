import { SERVICE_NAME } from '../../../../constants'
import { IGenericResponse } from '../../../api.types'
import clientWrapper from '../../../clientWrapper'

export type IRemoveFanbeeLinkResponse = IGenericResponse<'removeFanbeeLink', boolean>

export type IRemoveFanbeeLinkProps = {
  linkUuid: string
}

export async function removeFanbeeLink(props: IRemoveFanbeeLinkProps): Promise<IRemoveFanbeeLinkResponse> {
  const query = `
    mutation RemoveFanbeeLink($linkUuid: String!) {
      removeFanbeeLink(linkUuid: $linkUuid)
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, props)) as IRemoveFanbeeLinkResponse
}
