import { IMPORTED_RELEASES_ADMIN_URL } from '@epic-front/common'
import { PERMISSIONS } from 'e2e-utils'
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { commonPrivateRoutes } from '../commonPrivateRoutes'
import { commonPublicAccountRoutes } from '../commonPublicAccountRoutes'
import { commonPublicRoutes } from '../commonPublicRoutes'
import { ISiteRouteList, RouteType } from '../types'

/**
 * LAZY LOAD ALL THE PAGES
 */
const DashboardAdminPage = lazy(() => import('../../pages/overview/DashboardAdminPage'))
const LogListPage = lazy(() => import('../../pages/configs/LogListPage'))
const AudiosaladTokenPage = lazy(() => import('../../pages/configs/AudiosaladTokenPage'))
const DistributionConfigsPage = lazy(() => import('../../pages/configs/DistributionConfigsPage'))
const RedisFlushAllPage = lazy(() => import('../../pages/configs/RedisFlushAll'))
const VideosCompositionsCsvPage = lazy(() => import('../../pages/configs/VideosCompositionsCsv'))
const UserLabelsPage = lazy(() => import('../../pages/configs/UserLabelsPage'))
const UserGroupsPage = lazy(() => import('../../pages/configs/UserGroupsPage'))
const GenreManagePage = lazy(() => import('../../pages/configs/GenreManagePage'))
const TaxFormPage = lazy(() => import('../../pages/payments/TaxFormPage/TaxFormPage'))
const AudioUploadPage = lazy(() => import('../../pages/assets/audio/AudioUploadPage'))
const AudioUploadCombinedPage = lazy(() => import('../../pages/assets/audio/AudioUploadCombinedPage'))

const AudioUploadCSV = lazy(() => import('../../pages/assets/audio/AudioUploadCSVPage'))
const ManageAudios = lazy(() => import('../../pages/assets/audio/ManageAudiosPage'))
const DistributionAnalyticsPage = lazy(() => import('../../pages/analytics/DistributionAnalyticsPage'))
const VideoUpload = lazy(() => import('../../pages/assets/video/VideoUploadPage'))
const ManageVideosPage = lazy(() => import('../../pages/assets/video/ManageVideosPage'))
const DistributionEarnings = lazy(() => import('../../pages/release/distribution/DistributionEarningsPage'))
const AudioReleasesLogsPage = lazy(() => import('../../pages/release/AudioReleasesLogsPage'))
const ManageReleases = lazy(() => import('../../pages/release/listRelease/ManageReleasesPage'))
const ViewReleasePage = lazy(() => import('../../pages/release/viewRelease/ViewReleasePage'))
const UserProfilePage = lazy(() => import('../../pages/users/UserProfile/UserProfilePage'))
const UserListPage = lazy(() => import('../../pages/users/UserListPage/UserListPage'))
const UserListEarningsPage = lazy(() => import('../../pages/users/UserListEarningsPage/UserListEarningsPage'))
const RolesPermissionsPage = lazy(() => import('../../pages/users/RolesPermissionsPage/RolesPermissionsPage'))
const AddUser = lazy(() => import('../../pages/users/AddUser/AddUser'))
const ManageRevenueSplitInvitationsPage = lazy(
  () => import('../../pages/users/ManageRevenueSplitInvitations/ManageRevenueSplitInvitationsPage')
)
const OnboardingOverviewPage = lazy(() => import('../../pages/users/OnboardingOverview/OnboardingOverviewPage'))
const ManageInvitationsPage = lazy(() => import('../../pages/users/ManageInvitations/ManageInvitationsPage'))
const ManageContractsPage = lazy(() => import('../../pages/users/ManageContracts/ManageContractsPage'))
const ManageTaxFormsPage = lazy(() => import('../../pages/users/ManageTaxForms/ManageTaxFormsPage'))
const AnnouncementsPage = lazy(() => import('../../pages/users/AnnouncementsPage/AnnouncementsPage'))
const PaymentListPage = lazy(() => import('../../pages/payments/PaymentListPage/PaymentListPage'))
const PaymentPage = lazy(() => import('../../pages/payments/PaymentPage'))
const EarningsList = lazy(() => import('../../pages/payments/EarningListPage'))
const BulkEarningCsvUploadPage = lazy(() => import('../../pages/payments/BulkEarningCsvUploadPage'))
const CurrencySetupPage = lazy(() => import('../../pages/payments/CurrencySetupPage'))
const PaymentMethodSetupPage = lazy(() => import('../../pages/payments/PaymentMethodSetupPage'))
const SponsorshipListPage = lazy(() => import('../../pages/payments/SponsorshipListPage'))
const PaymentSelect = lazy(() => import('../../pages/payments/PaymentSelect/PaymentSelectPage'))
const MyAccountPage = lazy(() => import('../../pages/profile/MyAccountPage'))
const ChannelVideosAdminPage = lazy(() => import('../../pages/channelVideos/ChannelVideosAdminPage'))
const SoundRecordingAssetsPage = lazy(() => import('../../pages/assets/SoundRecordingAssetsListPage'))
const VideoAssetsListPage = lazy(() => import('../../pages/assets/VideoAssetsListPage'))
const AssetAdminListPage = lazy(() => import('../../pages/contentId/AssetAdminListPage'))
const ClaimListPage = lazy(() => import('../../pages/contentId/claims/ClaimListPage'))
const CompositionsPage = lazy(() => import('../../pages/contentId/CompositionsPage'))
const AppealedClaimsPage = lazy(() => import('../../pages/contentId/claims/AppealedClaimsPage'))
const ClaimManagersPage = lazy(() => import('../../pages/contentId/ClaimManagersPage'))
const ClaimEmailTemplatesPage = lazy(() => import('../../pages/contentId/ClaimEmailTemplatesPage'))
const DisputedClaimsPage = lazy(() => import('../../pages/contentId/claims/DisputedClaimsPage'))
const PotentialClaimsPage = lazy(() => import('../../pages/contentId/claims/PotentialClaimsPage'))
const ResolvedClaimsPage = lazy(() => import('../../pages/contentId/claims/ResolvedClaimsPage'))
const ChannelPage = lazy(() => import('../../pages/contentId/ChannelPage'))
const ChannelAdminListPage = lazy(() => import('../../pages/contentId/ChannelAdminListPage'))
const MonthlyAdminReportPage = lazy(() => import('../../pages/payments/MonthlyAdminReportPage'))
const MonthlyAdminHighlightsReportPage = lazy(() => import('../../pages/payments/MonthlyAdminHighlightsReportPage'))
const ManagePaymentMethodsPage = lazy(
  () => import('../../pages/payments/ManagePaymentMethodsPage/ManagePaymentMethodsPage')
)
const DDEXUploadPage = lazy(() => import('../../pages/ddex/Upload/DDEXUploadPage'))
const PayoneerSuccessEmbed = lazy(() => import('../../pages/payments/embeds/PayoneerSuccessEmbed'))
const PayoneerErrorEmbed = lazy(() => import('../../pages/payments/embeds/PayoneerErrorEmbed'))
const PitchListAdminPage = lazy(() => import('../../pages/songpitch/admin-pages/PitchListAdminPage'))
const VideosPitchedListAdminPage = lazy(() => import('../../pages/songpitch/admin-pages/VideosPitchedListAdminPage'))
const EmbedIsrcPage = lazy(() => import('../../pages/isrcEmbed/EmbedIsrcPage'))
const ImportSpotifyPage = lazy(() => import('../../pages/importAssets/ImportSpotifyPage'))
const LicensesListPage = lazy(() => import('../../pages/licenses/LicensesListPage'))
const ManageIsrcPage = lazy(() => import('../../pages/isrc/ManageIsrcPage'))
const MicrolicensingAssetsListPage = lazy(() => import('../../pages/microlicensing/MicrolicensingAssetsListPage'))
const MicrolicensingOrdersListPage = lazy(() => import('../../pages/microlicensing/MicrolicensingOrdersListPage'))
const ContactRequestListPage = lazy(() => import('../../pages/users/ContactRequests/ContactRequestListPage'))
const ManageChannelWhitelistingPage = lazy(
  () => import('../../pages/channelWhitelisting/ManageChannelWhitelistingPage')
)
const AffiliationsListPage = lazy(() => import('../../pages/users/Affiliations/AffiliationsListPage'))
const MotionArrayUrlsPage = lazy(() => import('../../pages/channelWhitelisting/MotionArrayUrlsPage'))
const FanbeeProfile = lazy(() => import('../../pages/fanbee/FanbeeProfilePage'))
const FanbeeProfileListPage = lazy(() => import('../../pages/fanbee/FanbeeProfileListPage'))
const FanbeeLinkPage = lazy(() => import('../../pages/fanbee/FanbeeLinkPage'))
const FanbeeLinkListPage = lazy(() => import('../../pages/fanbee/FanbeeLinkListPage'))

/* test pages */
const ArtTracksAdminPage = lazy(() => import('../../pages/release/ArtTracksAdminPage'))
const DesignPage = lazy(() => import('../../pages/fanbee/DesignPage'))
const AudioUploadToolPage = lazy(() => import('../../pages/aut/AutPage'))

const adminRoutesList: ISiteRouteList = {
  // root route
  ROOT: {
    url: '/',
    name: 'Dashboard',
    exact: true,
    element: <Navigate to="/dashboard" />,
    routeType: RouteType.AUTH_PROTECTED,
    key: 'ROOT',
  },

  PAYONEER_SUCCESS: {
    url: '/payoneer-embed-success',
    name: 'Payoneer success',
    element: <PayoneerSuccessEmbed />,
    routeType: RouteType.PUBLIC,
    key: 'PAYONEER_SUCCESS',
  },
  PAYONEER_ERROR: {
    url: '/payoneer-embed-error',
    name: 'Payoneer error',
    element: <PayoneerErrorEmbed />,
    routeType: RouteType.PUBLIC,
    key: 'PAYONEER_ERROR',
  },

  // AUTH_PROTECTED ROUTES
  DASHBOARD: {
    url: '/dashboard',
    name: 'Dashboard',
    element: <DashboardAdminPage />,
    routeType: RouteType.AUTH_PROTECTED,
    key: 'DASHBOARD',
  },
  MY_ACCOUNT: {
    url: '/account/my-account',
    name: 'My Account',
    element: <MyAccountPage />,
    routeType: RouteType.AUTH_PROTECTED,
    key: 'MY_ACCOUNT',
  },
  REFRESH_AUDIOSALAD_TOKEN: {
    url: '/config/audiosalad-token',
    name: 'Audiosalad Token',
    element: <AudiosaladTokenPage />,
    permissionsAnd: [PERMISSIONS.PAGES.REFRESH_AUDIOSALAD_TOKEN, PERMISSIONS.USER.RESET_PASSWORD],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'REFRESH_AUDIOSALAD_TOKEN',
  },
  DISTRIBUTION_CONFIGS: {
    url: '/config/distribution',
    name: 'Distribution Configs',
    element: <DistributionConfigsPage />,
    permissionsAnd: [PERMISSIONS.PAGES.DISTRIBUTION_CONFIGS, PERMISSIONS.USER.RESET_PASSWORD],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'DISTRIBUTION_CONFIGS',
  },
  REDIS_FLUSH_ALL: {
    url: '/config/flush-all',
    name: 'Flush All Cache',
    element: <RedisFlushAllPage />,
    permissionsAnd: [PERMISSIONS.PAGES.REDIS_FLUSH_ALL, PERMISSIONS.USER.FLUSH_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'FLUSH_ALL',
  },
  VIDEOS_COMPOSITIONS_CSV: {
    url: '/config/videos-compositions',
    name: 'Videos Compositions',
    element: <VideosCompositionsCsvPage />,
    permissionsAnd: [PERMISSIONS.PAGES.VIDEOS_COMPOSITIONS_CSV],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'VIDEOS_COMPOSITIONS_CSV',
  },
  MANAGE_USER_LABELS: {
    url: '/config/user-labels',
    name: 'User Labels',
    element: <UserLabelsPage />,
    permissionsAnd: [PERMISSIONS.PAGES.MANAGE_USER_LABELS, PERMISSIONS.LABEL.LIST],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'MANAGE_USER_LABELS',
  },
  MANAGE_USER_GROUPS: {
    url: '/config/user-groups',
    name: 'User Groups',
    element: <UserGroupsPage />,
    permissionsAnd: [PERMISSIONS.PAGES.MANAGE_USER_GROUPS, PERMISSIONS.USER_GROUP.LIST],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'MANAGE_USER_GROUPS',
  },
  MANAGE_GENRE_LIST: {
    url: '/config/genre-list',
    name: 'Manage Genres',
    element: <GenreManagePage />,
    permissionsAnd: [PERMISSIONS.PAGES.MANAGE_GENRES, PERMISSIONS.GENRE.MANAGE],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'MANAGE_GENRE_LIST',
  },
  LOG_LIST: {
    url: '/config/logs',
    name: 'List logs',
    element: <LogListPage />,
    permissionsAnd: [PERMISSIONS.PAGES.LOGS, PERMISSIONS.LOG.LIST],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'LOG_LIST',
  },

  ASSETS_ALL: {
    url: '/assets/sound-recordings',
    name: 'All SR Assets',
    element: <SoundRecordingAssetsPage />,
    key: 'SOUND_RECORDING_ASSETS',
    routeType: RouteType.AUTH_PROTECTED,
    permissionsAnd: [PERMISSIONS.ASSET.MANAGE_ALL],
  },

  VIDEO_ASSETS: {
    url: '/video-assets',
    name: 'Video Assets',
    element: <VideoAssetsListPage />,
    key: 'VIDEO_ASSETS',
    routeType: RouteType.AUTH_PROTECTED,
    permissionsAnd: [PERMISSIONS.ASSET.MANAGE_ALL],
  },

  ART_TRACKS_ADMIN_LIST: {
    url: '/art-tracks/list',
    name: 'Art Tracks',
    element: <ArtTracksAdminPage />,
    key: 'ART_TRACKS_ADMIN_LIST',
    routeType: RouteType.AUTH_PROTECTED,
    permissionsAnd: [PERMISSIONS.ASSET.MANAGE_ALL, PERMISSIONS.PAGES.ART_TRACKS],
  },

  AUDIO_UPLOAD_COMBINED: {
    url: '/assets/audios-add',
    name: 'Upload Audios (COMBINED)',
    element: <AudioUploadCombinedPage />,
    permissionsAnd: [PERMISSIONS.PAGES.UPLOAD_AUDIO, PERMISSIONS.ASSET.MANAGE_ALL],
    key: 'AUDIO_UPLOAD_COMBINED',
    routeType: RouteType.AUTH_PROTECTED,
  },
  AUDIO_UPLOAD: {
    url: '/assets/audios/add',
    name: 'Upload Audios (EAM)',
    element: <AudioUploadPage />,
    permissionsAnd: [PERMISSIONS.PAGES.UPLOAD_AUDIO, PERMISSIONS.ASSET.MANAGE_ALL],
    key: 'AUDIO_UPLOAD',
    routeType: RouteType.AUTH_PROTECTED,
  },
  AUDIO_UPLOAD_CSV: {
    url: '/assets/audios/add/csv',
    name: 'Upload Audios (EAM) - CSV',
    element: <AudioUploadCSV />,
    permissionsAnd: [PERMISSIONS.PAGES.UPLOAD_AUDIO, PERMISSIONS.ASSET.MANAGE_ALL],
    key: 'AUDIO_UPLOAD_CSV',
    routeType: RouteType.AUTH_PROTECTED,
  },
  MANAGE_ADDED_AUDIOS: {
    url: '/assets/audios/list',
    name: 'Audio Asset Approval (EAM)',
    element: <ManageAudios />,
    permissionsAnd: [PERMISSIONS.PAGES.MANAGE_AUDIOS, PERMISSIONS.ASSET.MANAGE_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'MANAGE_ADDED_AUDIOS',
  },
  VIDEO_UPLOAD: {
    url: '/assets/videos/add',
    name: 'Upload Videos (EA)',
    element: <VideoUpload />,
    permissionsAnd: [PERMISSIONS.PAGES.UPLOAD_VIDEO, PERMISSIONS.ASSET.MANAGE_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'VIDEO_UPLOAD',
  },
  MANAGE_ADDED_VIDEOS: {
    url: '/assets/videos/list',
    name: 'Video Asset Approval (EA)',
    element: <ManageVideosPage />,
    permissionsAnd: [PERMISSIONS.PAGES.MANAGE_VIDEOS, PERMISSIONS.ASSET.MANAGE_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'MANAGE_ADDED_VIDEOS',
  },
  COMPOSITIONS: {
    key: 'COMPOSITIONS',
    url: '/compositions',
    name: 'Compositions',
    element: <CompositionsPage />,
    permissionsAnd: [PERMISSIONS.PAGES.COMPOSITIONS, PERMISSIONS.ASSET.MANAGE_ALL],
    routeType: RouteType.AUTH_PROTECTED,
  },
  IMPORT_ASSETS_SPOTIFY: {
    url: '/assets/import',
    name: 'Import from Spotify',
    element: <ImportSpotifyPage />,
    permissionsAnd: [PERMISSIONS.PAGES.IMPORT_AUDIO_RELEASE, PERMISSIONS.ASSET.MANAGE_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'IMPORT_ASSETS_SPOTIFY',
  },
  LICENSES_LIST: {
    url: '/licenses',
    name: 'Licenses',
    element: <LicensesListPage />,
    permissionsAnd: [PERMISSIONS.PAGES.LICENSES, PERMISSIONS.LICENSE.MANAGE_ALL, PERMISSIONS.LICENSE.LIST_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'LICENSES_LIST',
  },

  AUT_EDIT: {
    url: '/aut/:id',
    name: 'Audio Upload Tool',
    element: <AudioUploadToolPage />,
    permissionsAnd: [PERMISSIONS.PAGES.CREATE_AUDIO_RELEASE, PERMISSIONS.ASSET.MANAGE_ALL],

    routeType: RouteType.AUTH_PROTECTED,
    key: 'AUT_EDIT',
  },
  AUT_CREATE: {
    url: '/aut',
    name: 'Audio Upload Tool',
    key: 'AUT_CREATE',
    element: <AudioUploadToolPage />,
    permissionsAnd: [PERMISSIONS.PAGES.CREATE_AUDIO_RELEASE, PERMISSIONS.ASSET.MANAGE_ALL],
    routeType: RouteType.AUTH_PROTECTED,
  },
  MANAGE_AUDIO_RELEASE: {
    url: '/release/manage/',
    name: 'Audio Releases',
    element: <ManageReleases />,
    permissionsAnd: [PERMISSIONS.PAGES.MANAGE_RELEASES, PERMISSIONS.ASSET.LIST_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'MANAGE_AUDIO_RELEASE',
  },
  MANAGE_ANALYTICS: {
    url: '/assets/analytics',
    name: 'Distribution Analytics',
    element: <DistributionAnalyticsPage />,
    permissionsAnd: [PERMISSIONS.PAGES.MANAGE_ANALYTICS, PERMISSIONS.ASSET.LIST_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'MANAGE_ANALYTICS',
  },
  MANAGE_IMPORTED_AUDIO_RELEASE: {
    url: IMPORTED_RELEASES_ADMIN_URL,
    name: 'Imported Releases',
    element: <ManageReleases />,
    permissionsAnd: [PERMISSIONS.PAGES.MANAGE_IMPORTED_RELEASES, PERMISSIONS.ASSET.LIST_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'MANAGE_IMPORTED_AUDIO_RELEASE',
  },
  DISTRIBUTION_EARNINGS: {
    url: '/release/distribution',
    name: 'Distribution Earnings',
    element: <DistributionEarnings />,
    permissionsAnd: [PERMISSIONS.PAGES.DISTRIBUTION_EARNINGS, PERMISSIONS.ASSET.EARNING_LIST_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'DISTRIBUTION_EARNINGS',
  },
  AUDIO_RELEASES_LOGS: {
    url: '/release/logs',
    name: 'Audio Releases Logs',
    element: <AudioReleasesLogsPage />,
    permissionsAnd: [PERMISSIONS.PAGES.AUDIO_RELEASE_LOGS],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'AUDIO_RELEASES_LOGS',
  },
  USER_PROFILE: {
    url: '/user/:id',
    name: 'User title',
    element: <UserProfilePage />,
    permissionsAnd: [PERMISSIONS.USER.LIST_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'USER_PROFILE',
  },

  USER_LIST: {
    url: '/users/list',
    name: 'List users',
    element: <UserListPage />,
    permissionsAnd: [PERMISSIONS.PAGES.LIST_USERS, PERMISSIONS.USER.LIST_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'USER_LIST',
  },
  USER_EARNINGS_OVERVIEW: {
    url: '/users/earnings-overview',
    name: 'User Earnings Overview',
    element: <UserListEarningsPage />,
    permissionsAnd: [PERMISSIONS.PAGES.LIST_USERS_EARNINGS, PERMISSIONS.USER.LIST_ALL, PERMISSIONS.EARNING.REPORT_LIST],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'USER_EARNINGS_OVERVIEW',
  },
  USER_ADD: {
    url: '/users/add',
    name: 'Add user',
    element: <AddUser />,
    permissionsAnd: [PERMISSIONS.PAGES.ADD_USERS, PERMISSIONS.USER.REGISTER],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'USER_ADD',
  },

  ANNOUNCEMENTS_LIST: {
    url: '/users/announcements',
    name: 'Announcements',
    element: <AnnouncementsPage />,
    permissionsAnd: [PERMISSIONS.ANNOUNCEMENT.MANAGE, PERMISSIONS.PAGES.MANAGE_ANNOUNCEMENTS],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'ANNOUNCEMENTS_LIST',
  },
  CONTACT_REQUESTS_LIST: {
    url: '/contact-requests/list',
    name: 'Contact Requests',
    element: <ContactRequestListPage />,
    permissionsAnd: [PERMISSIONS.SIGNUP_REQUEST.LIST, PERMISSIONS.PAGES.MANAGE_SIGNUP_REQUESTS],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'CONTACT_REQUESTS_LIST',
  },

  MANAGE_CONTRACTS: {
    url: '/users/contracts',
    name: 'Client Contracts',
    element: <ManageContractsPage />,
    permissionsAnd: [PERMISSIONS.DOCUMENT.LIST_ALL, PERMISSIONS.PAGES.DOCUMENTS],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'MANAGE_CONTRACTS',
  },

  AFFILIATIONS_LIST: {
    url: '/users/affiliations',
    name: 'Affiliations',
    element: <AffiliationsListPage />,
    permissionsAnd: [PERMISSIONS.AFFILIATE.LIST, PERMISSIONS.PAGES.AFFILIATIONS],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'AFFILIATIONS_LIST',
  },

  MANAGE_TAX_FORMS: {
    url: '/users/manage-tax-forms',
    name: 'Tax Form Approvals',
    element: <ManageTaxFormsPage />,
    permissionsAnd: [PERMISSIONS.DOCUMENT.LIST_ALL, PERMISSIONS.PAGES.DOCUMENTS],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'MANAGE_TAX_FORMS',
  },

  TAX_FORMS: {
    url: '/tax-forms',
    name: 'Tax Forms',
    element: <TaxFormPage />,
    permissionsAnd: [PERMISSIONS.PAYMENT_METHOD.LIST_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'TAX_FORMS',
  },

  ONBOARDING_OVERVIEW: {
    url: '/users/onboarding-overview',
    name: 'Overview',
    element: <OnboardingOverviewPage />,
    permissionsAnd: [
      PERMISSIONS.PAGES.ONBOARDING_OVERVIEW,
      PERMISSIONS.INVITATION.LIST,
      PERMISSIONS.DOCUMENT.LIST_ALL,
      PERMISSIONS.REVENUE_SPLIT.LIST_ALL,
    ],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'ONBOARDING_OVERVIEW',
  },
  MANAGE_INVITATIONS: {
    url: '/users/invitations',
    name: 'Invitations',
    element: <ManageInvitationsPage />,
    permissionsAnd: [PERMISSIONS.INVITATION.LIST, PERMISSIONS.PAGES.INVITATIONS],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'MANAGE_INVITATIONS',
  },
  MANAGE_REVENUE_SPLIT_INVITATIONS: {
    url: '/users/revenue-split-invitations',
    name: 'Revenue Split Invitations',
    element: <ManageRevenueSplitInvitationsPage />,
    permissionsAnd: [PERMISSIONS.REVENUE_SPLIT.LIST_ALL, PERMISSIONS.PAGES.REVENUE_SPLIT_INVITATIONS],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'MANAGE_REVENUE_SPLIT_INVITATIONS',
  },
  MANAGE_PERMISSIONS: {
    url: '/users/permissions',
    name: 'Roles & Permissions',
    element: <RolesPermissionsPage />,
    permissionsAnd: [PERMISSIONS.PAGES.PERMISSIONS, PERMISSIONS.PERMISSION.MANAGE_PERMISSIONS],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'MANAGE_PERMISSIONS',
  },
  PAYMENT_SELECT: {
    url: '/payment-select',
    name: 'Payment Select',
    element: <PaymentSelect />,
    permissionsAnd: [PERMISSIONS.PAYMENT_METHOD.MANAGE_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'PAYMENT_SELECT',
  },
  EARNINGS_LIST: {
    url: '/earnings/list',
    name: 'Earnings',
    element: <EarningsList />,
    permissionsAnd: [PERMISSIONS.PAGES.EARNINGS, PERMISSIONS.EARNING.LIST_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'EARNINGS_LIST',
  },
  BULK_EARNING_CSV_UPLOAD: {
    url: '/earnings/bulk-csv',
    name: 'Bulk Earning Csv Upload',
    element: <BulkEarningCsvUploadPage />,
    permissionsAnd: [PERMISSIONS.PAGES.BULK_EARNING_CSV_UPLOAD, PERMISSIONS.EARNING.MANAGE],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'BULK_EARNING_CSV_UPLOAD',
  },
  CURRENCY_SETUP: {
    url: '/currencies/setup',
    name: 'Manage Currency',
    element: <CurrencySetupPage />,
    permissionsAnd: [PERMISSIONS.PAGES.CURRENCY_SETUP, PERMISSIONS.PAYMENT_METHOD.MANAGE_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'CURRENCY_SETUP',
  },
  PAYMENT_METHOD_SETUP: {
    url: '/payment-method/setup',
    name: 'Manage Payment Methods',
    element: <PaymentMethodSetupPage />,
    permissionsAnd: [PERMISSIONS.PAGES.PAYMENT_METHOD_SETUP, PERMISSIONS.PAYMENT_METHOD.MANAGE_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'PAYMENT_METHOD_SETUP',
  },
  PAYMENT_LIST: {
    url: '/payments/list',
    name: 'Payments',
    element: <PaymentListPage />,
    permissionsAnd: [PERMISSIONS.PAGES.PAYMENTS, PERMISSIONS.PAYMENT.LIST_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'PAYMENT_LIST',
  },
  SPONSORSHIPS_LIST: {
    url: '/sponsorships/list',
    name: 'Sponsorships',
    element: <SponsorshipListPage />,
    permissionsAnd: [PERMISSIONS.PAGES.SPONSORSHIPS, PERMISSIONS.EARNING.LIST_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'SPONSORSHIPS_LIST',
  },
  PAYMENT_ADMIN_REPORT: {
    url: '/payments/monthly-admin-report',
    name: 'Monthly Report',
    element: <MonthlyAdminReportPage />,
    permissionsAnd: [PERMISSIONS.PAGES.MONTHLY_REPORT, PERMISSIONS.EARNING.REPORT_LIST],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'PAYMENT_ADMIN_REPORT',
  },
  PAYMENT_ADMIN_HIGHLIGHT_REPORT: {
    url: '/payments/monthly-admin-highlight-report',
    name: 'Highlights Report',
    element: <MonthlyAdminHighlightsReportPage />,
    permissionsAnd: [PERMISSIONS.PAGES.MONTHLY_HIGHLIGHT_REPORT, PERMISSIONS.EARNING.REPORT_LIST],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'PAYMENT_ADMIN_HIGHLIGHT_REPORT',
  },
  MANAGE_USER_PAYMENT_METHODS: {
    url: '/payments/manage-payment-methods',
    name: 'Payment Method Approvals',
    element: <ManagePaymentMethodsPage />,
    permissionsAnd: [PERMISSIONS.PAGES.MANAGE_USER_PAYMENT_METHODS, PERMISSIONS.PAYMENT_METHOD.LIST_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'PAYMENT_METHOD_LIST',
  },
  PAYMENT_DETAILS: {
    url: '/payment/:id',
    name: 'Payment',
    element: <PaymentPage />,
    permissionsAnd: [PERMISSIONS.PAYMENT.LIST_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'PAYMENT_DETAILS',
  },
  PITCHES_LIST: {
    url: '/songpitch/list-pitches',
    name: 'List Pitches',
    element: <PitchListAdminPage />,
    permissionsAnd: [PERMISSIONS.SONGPITCH.PERFORM_ADMIN, PERMISSIONS.PAGES.SONGPITCH_PITCHES],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'PITCHES_LIST',
  },
  PITCHES_VIDEO_LIST: {
    url: '/songpitch/list-videos-pitch',
    name: 'List Videos for Pitches',
    element: <VideosPitchedListAdminPage />,
    permissionsAnd: [PERMISSIONS.SONGPITCH.PERFORM_ADMIN, PERMISSIONS.PAGES.SONGPITCH_VIDEOS],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'PITCHES_VIDEO_LIST',
  },
  ISRC_EMBED: {
    url: '/embed-isrc',
    name: 'ISRC Embedding',
    element: <EmbedIsrcPage />,
    permissionsAnd: [PERMISSIONS.PAGES.ISRC_EMBEDDING, PERMISSIONS.ISRC.LIST],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'ISRC_EMBED',
  },
  ASSETS_CONTENT_ID: {
    url: '/content-id/assets',
    name: 'Assets (Music)',
    permissionsAnd: [PERMISSIONS.PAGES.CONTENT_ID_ASSETS, PERMISSIONS.ASSET.LIST_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    element: <AssetAdminListPage />,
    key: 'ASSETS_CONTENT_ID',
  },

  CLAIMS_CONTENT_ID: {
    url: '/content-id/claims',
    name: 'All Claims',
    element: <ClaimListPage />,
    permissionsAnd: [PERMISSIONS.PAGES.CLAIMS],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'CLAIMS_CONTENT_ID',
  },
  CLAIM_MANAGERS: {
    url: '/issues/claim-managers',
    name: 'Claim Managers',
    element: <ClaimManagersPage />,
    permissionsAnd: [PERMISSIONS.PAGES.CLAIM_MANAGERS, PERMISSIONS.CLAIM.MANAGER_LIST],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'CLAIM_MANAGERS',
  },
  CLAIM_EMAIL_TEMPLATES: {
    url: '/issues/claim-email-templates',
    name: 'Claim Email Templates',
    element: <ClaimEmailTemplatesPage />,
    permissionsAnd: [PERMISSIONS.PAGES.CLAIM_EMAIL_TEMPLATES, PERMISSIONS.CLAIM.EMAIL_TEMPLATE_LIST],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'CLAIM_EMAIL_TEMPLATES',
  },
  APPEALED_CLAIMS_CONTENT_ID: {
    url: '/issues/appealed-claims',
    name: 'Appealed Claims',
    element: <AppealedClaimsPage />,
    permissionsAnd: [PERMISSIONS.PAGES.CLAIM_ISSUES_APPEALED],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'APPEALED_CLAIMS_CONTENT_ID',
  },
  DISPUTED_CLAIMS_CONTENT_ID: {
    url: '/issues/disputed-claims',
    name: 'Disputed Claims',
    element: <DisputedClaimsPage />,
    permissionsAnd: [PERMISSIONS.PAGES.CLAIM_ISSUES_DISPUTED],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'DISPUTED_CLAIMS_CONTENT_ID',
  },
  POTENTIAL_CLAIMS_CONTENT_ID: {
    url: '/issues/potential-claims',
    name: 'Potential Claims',
    element: <PotentialClaimsPage />,
    permissionsAnd: [PERMISSIONS.PAGES.CLAIM_ISSUES_POTENTIAL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'POTENTIAL_CLAIMS_CONTENT_ID',
  },
  RESOLVED_CLAIMS_CONTENT_ID: {
    url: '/issues/resolved-claims',
    name: 'Resolved Claims',
    element: <ResolvedClaimsPage />,
    permissionsAnd: [PERMISSIONS.PAGES.CLAIM_ISSUES_RESOLVED],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'RESOLVED_CLAIMS_CONTENT_ID',
  },
  CHANNEL_DETAILS: {
    url: '/channel/:id',
    name: 'Channel',
    element: <ChannelPage />,
    permissionsAnd: [PERMISSIONS.CHANNEL.LIST_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'CHANNEL_DETAILS',
  },
  CHANNEL_LIST: {
    url: '/channels',
    name: 'Channels',
    element: <ChannelAdminListPage />,
    permissionsAnd: [PERMISSIONS.PAGES.CHANNELS, PERMISSIONS.CHANNEL.LIST_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'CHANNEL_LIST',
  },
  DDEX_UPLOAD: {
    url: '/ddex/upload',
    name: 'DDEX Upload (EE) (AT & SR)',
    element: <DDEXUploadPage />,
    permissionsAnd: [PERMISSIONS.PAGES.DDEX, PERMISSIONS.ASSET.DDEX_MANAGE],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'DDEX_UPLOAD',
  },
  CHANNEL_VIDEOS: {
    url: '/channel-videos',
    name: 'Channel Videos',
    element: <ChannelVideosAdminPage />,
    permissionsAnd: [PERMISSIONS.PAGES.CHANNEL_VIDEOS, PERMISSIONS.ASSET.LIST_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'CHANNEL_VIDEOS',
  },
  VIEW_RELEASE: {
    url: '/release/view/:id',
    name: 'View Release',
    element: <ViewReleasePage />,
    permissionsAnd: [PERMISSIONS.ASSET.LIST_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'VIEW_RELEASE',
  },
  MANAGE_ISRC_UPC: {
    url: '/isrc-upc',
    name: 'ISRC & UPC Manager',
    element: <ManageIsrcPage />,
    permissionsAnd: [PERMISSIONS.ISRC.LIST, PERMISSIONS.PAGES.ISRC_MANAGER],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'MANAGE_ISRC_UPC',
  },
  ASSETS_MICROLICENSING: {
    url: '/microlicensing/assets',
    name: 'Assets',
    element: <MicrolicensingAssetsListPage />,
    permissionsAnd: [PERMISSIONS.PAGES.MICROLICENSING_ASSETS, PERMISSIONS.MICROLICENSING.LIST_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'ASSETS_MICROLICENSING',
  },
  ORDERS_MICROLICENSING: {
    url: '/microlicensing/orders',
    name: 'Orders',
    element: <MicrolicensingOrdersListPage />,
    permissionsAnd: [PERMISSIONS.PAGES.MICROLICENSING_ORDERS, PERMISSIONS.MICROLICENSING.ORDER_LIST_ALL],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'ORDERS_MICROLICENSING',
  },
  CHANNEL_WHITELISTING: {
    key: 'CHANNEL_WHITELISTING',
    url: '/channel-whitelisting',
    name: 'Channel Whitelisting',
    element: <ManageChannelWhitelistingPage />,
    permissionsOr: [PERMISSIONS.CHANNEL_WHITELISTING.LIST_ALL, PERMISSIONS.CHANNEL_WHITELISTING.MANAGE_ALL],
    routeType: RouteType.AUTH_PROTECTED,
  },
  MOTION_ARRAY: {
    key: 'MOTION_ARRAY',
    url: '/motion-array',
    name: 'Motion Array Urls',
    element: <MotionArrayUrlsPage />,
    permissionsOr: [PERMISSIONS.PAGES.MOTIONARRAY],
    routeType: RouteType.AUTH_PROTECTED,
  },
  FANBEE_PROFILE: {
    url: '/fanbee/profile/:id',
    name: 'Profile',
    key: 'FANBEE_PROFILE',
    element: <FanbeeProfile />,
    routeType: RouteType.AUTH_PROTECTED,
    permissionsOr: [PERMISSIONS.FANBEE.LIST_ALL],
  },
  FANBEE_PROFILES: {
    url: '/fanbee/profiles',
    name: 'Profiles',
    key: 'FANBEE_PROFILE',
    element: <FanbeeProfileListPage />,
    routeType: RouteType.AUTH_PROTECTED,
    permissionsOr: [PERMISSIONS.FANBEE.LIST_ALL],
  },
  FANBEE_RELEASES: {
    url: '/fanbee/releases',
    name: 'Links',
    key: 'FANBEE_RELEASES',
    element: <FanbeeLinkListPage />,
    routeType: RouteType.AUTH_PROTECTED,
    permissionsOr: [PERMISSIONS.FANBEE.LIST_ALL],
  },
  FANBEE_RELEASE: {
    url: '/fanbee/release/:id',
    name: 'Fanbee Release',
    key: 'FANBEE_RELEASE',
    element: <FanbeeLinkPage />,
    routeType: RouteType.AUTH_PROTECTED,
    permissionsOr: [PERMISSIONS.FANBEE.LIST_ALL],
  },
  /* test pages */
  DESIGN: {
    url: '/design',
    name: 'DesignPage',
    key: 'DESIGN',
    element: <DesignPage />,
    routeType: RouteType.AUTH_PROTECTED,
  },
}

export const ADMIN_ROUTES = {
  ...commonPublicRoutes,
  ...adminRoutesList,
  ...commonPrivateRoutes(adminRoutesList),
  ...commonPublicAccountRoutes,
}
