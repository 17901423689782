import { PLATFORM_NAME, VERSION } from '@epic-front/common/constants'
import { observer } from 'mobx-react-lite'
import { Col, Row } from 'react-bootstrap'

const Footer = (): JSX.Element => {
  const currentYear = new Date().getFullYear()
  return (
    <>
      <footer className="footer">
        <div className="container-fluid">
          <Row>
            <Col md={6}>
              {currentYear} © {PLATFORM_NAME} - v{VERSION}
            </Col>
          </Row>
        </div>
      </footer>
    </>
  )
}

export default observer(Footer)
