import { SERVICE_NAME } from '../../../constants'
import { IVideoAsset } from '../../../models'
import { IPaginationParams } from '../../../types'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'
import { videoAssetFragment } from '../fragmentsAssets'

export type IVideoAssetsResponse = IGenericResponse<
  'videos',
  {
    videos: IVideoAsset[]
    total: number
  }
>

export type IGetVideoAssetsProps = {
  pagination?: IPaginationParams
  filters?: {
    uuid?: string
    videoId?: string
    userUuid?: string
    customId?: string
    isAssigned?: boolean
    isSplit?: boolean
    isYoutubeActive?: boolean
    multipleIds?: string[]
    siteUuid?: string
    search?: string
  }
}

export async function getVideoAssets(
  { pagination, filters }: IGetVideoAssetsProps,
  fragment: string = videoAssetFragment
): Promise<IVideoAssetsResponse> {
  const query = `
    query Videos($filters: VideoFiltersInput, $pagination: PaginationInput) {
    videos(filters: $filters, pagination:$pagination) {
      total
      videos {
        ${fragment}
      }
    }
  }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, { pagination, filters })) as IVideoAssetsResponse
}
