import { SERVICE_NAME, STORES } from '../../../../constants'
import { IGenericResponse } from '../../../api.types'
import clientWrapper from '../../../clientWrapper'

export type IAddTrackFanbeeStreamingLinkResponse = IGenericResponse<'addTrackFanbeeStreamingLink', boolean>

export type IAddTrackFanbeeStreamingLinkProps = {
  trackUuid: string
  input: {
    link: string
    store: STORES
  }
}

export async function addTrackFanbeeStreamingLink({
  trackUuid,
  input,
}: IAddTrackFanbeeStreamingLinkProps): Promise<IAddTrackFanbeeStreamingLinkResponse> {
  const query = `
    mutation addTrackFanbeeStreamingLink($input: StreamingLinkInput!, $trackUuid: String!) {
        addTrackFanbeeStreamingLink(input: $input, trackUuid: $trackUuid)
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, {
    trackUuid,
    input,
  })) as IAddTrackFanbeeStreamingLinkResponse
}
