import { Instance, types } from 'mobx-state-tree'

import { CLIENT_TIER } from '../../constants'
import { Label } from './Label.model'
import { Permission } from './Permission.model'
import { Role } from './Role.model'
import { UserGroup } from './UserGroup.model'
import { UserSite } from './UserSite.model'

export const UserBasic = types
  .model({
    uuid: types.identifier,
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    parentClientUuid: types.maybeNull(types.string),
    children: types.array(
      types.model({
        uuid: types.string,
      })
    ),
    userImage: types.maybeNull(types.string),
    referenceNo: types.maybeNull(types.string),
    displayName: types.maybeNull(types.string),
    cityName: types.maybeNull(types.string),
    gender: types.maybeNull(types.string),
    clientTier: types.maybeNull(types.enumeration(Object.values(CLIENT_TIER))),
    contactNumber: types.maybeNull(types.string),
    billingName: types.maybeNull(types.string),
    addressLine1: types.maybeNull(types.string),
    addressLine2: types.maybeNull(types.string),
    postCode: types.maybeNull(types.string),
    countryCode: types.maybeNull(types.string),
    paypalEmail: types.maybeNull(types.string),
    revPercentage: types.maybeNull(types.string),
    futureRevPercentage: types.maybeNull(types.string),
    effectMonth: types.maybeNull(types.string),
    isAffiliate: types.maybeNull(types.boolean),
    joiningDate: types.maybeNull(types.string),
    deletedAt: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    userSites: types.array(UserSite),
    roles: types.array(Role),
    permissions: types.array(Permission),
    doNotPay: types.maybe(types.boolean),
    isEarly: types.maybe(types.boolean),
    isEarlyPercentage: types.maybeNull(types.number),
    labels: types.array(Label),
    userGroups: types.array(UserGroup),
    shouldChangePassword: types.maybeNull(types.boolean),
    taxFormCountryCode: types.maybeNull(types.string),
    managerUuid: types.maybeNull(types.string),
    inboundSponsorshipPercentage: types.maybeNull(types.number),
    outboundSponsorshipPercentage: types.maybeNull(types.number),
    totpActive: types.maybeNull(types.boolean),
  })
  .views(self => ({
    get isParent() {
      return self.children.length > 0
    },
    get isChild() {
      return !!self.parentClientUuid
    },
    hasSite(slug: string) {
      // this works only if we get sites
      return self.userSites.map(site => site.site?.slug || '').includes(slug)
    },
    hasSiteByUuid(siteUuid: string) {
      return self.userSites.map(site => site.siteUuid || '').includes(siteUuid)
    },
  }))

export type IUserBasic = Instance<typeof UserBasic>
