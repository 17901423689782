import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export interface IAssignUserToVideoAssetsProps {
  userUuid: string
  videoUuids: string[]
}

export type IAssignUserToVideoAssetsResponse = IGenericResponse<'assignUserToVideos', boolean>

export async function assignUserToVideoAssets(
  props: IAssignUserToVideoAssetsProps
): Promise<IAssignUserToVideoAssetsResponse> {
  const query = `
    mutation AssignUserToVideos($userUuid: String!, $videoUuids: [String!]!) {
      assignUserToVideos(userUuid: $userUuid, videoUuids: $videoUuids)
  }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, props) as Promise<IAssignUserToVideoAssetsResponse>
}
