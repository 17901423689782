import { cast, flow, Instance, types } from 'mobx-state-tree'
import {
  editPaymentMethodThreshold,
  IEditPaymentMethodThresholdResponse,
} from '@epic-front/common/api/payments-api/payments/editPaymentMethodThreshold'
import { toast } from 'react-toastify'
import { getPaymentMethods, IGetPaymentMethodsResponse } from '../../api/payments-api/payments/getPaymentMethods'
import { PaymentMethod } from './PaymentMethod.model'

export const PaymentMethodList = types
  .model({
    list: types.array(PaymentMethod),
  })
  .volatile(() => ({
    loading: false,
  }))
  .views(self => ({
    methodBySlug(slug: string) {
      return self.list.find(el => el.slug === slug)
    },
    getByUuid(uuid: string) {
      return self.list.find(item => item.uuid === uuid)
    },
  }))
  .actions(self => ({
    load: flow(function* () {
      try {
        self.loading = true
        const resp: IGetPaymentMethodsResponse = yield getPaymentMethods()
        if (resp && resp.data.data?.paymentMethods) {
          self.list = cast(resp.data.data.paymentMethods)
        }
        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),
    editPaymentMethodThreshold: flow(function* (paymentMethodUuid: string, inputThreshold: number) {
      try {
        self.loading = true

        const resp: IEditPaymentMethodThresholdResponse = yield editPaymentMethodThreshold({
          paymentMethodUuid,
          inputThreshold,
        })

        if (resp && resp.data.data?.editPaymentMethodThreshold) {
          const updatedList = self.list.map(pm => {
            if (pm.uuid !== paymentMethodUuid) {
              return pm
            }

            return {
              ...pm,
              threshold: resp.data.data?.editPaymentMethodThreshold.threshold || 0,
            }
          })

          self.list = cast(updatedList)

          toast.success('Threshold successfully set!')
        }
        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),
  }))

export type IPaymentMethodList = Instance<typeof PaymentMethodList>
