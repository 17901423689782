import { Instance, cast, destroy, detach, flow, types } from 'mobx-state-tree'
import { toast } from 'react-toastify'

import { IChangeAssetStatesResponse } from '../../api/types'
import {
  APPROVAL_STATES,
  ASSET_ID_REGEX,
  ASSET_TYPE,
  ASSET_UUID_REGEX,
  ASSIGNMENT_STATUSES,
  DELIVERY_PLATFORM,
  PLATFORM_STATES,
  SPLIT_STATUSES,
  STORES,
} from '../../constants'

import { changeAssetsState } from '../../api/assets-api'
import { IDeleteAssetsResponse, deleteAssets } from '../../api/assets-api/assets/deleteAssets'
import { IGetAssetsResponse, getAssets } from '../../api/assets-api/assets/getAssets'
import { IGetAssetsRawResponse, getAssetsRaw } from '../../api/assets-api/assets/getAssetsRaw'
import {
  ILatestEstimatedEarningsResponse,
  latestEstimatedEarnings,
} from '../../api/assets-api/assets/latestEstimatedEarnings'
import downloadAssetsXLSX from '../../api/assets-api/other/downloadAssetsXLSX'
import { IAssetFormData } from '../../types/common'
import { IPagination, Pagination } from '../general/Pagination.model'
import { Asset, IAsset } from './Asset.model'

export const AssetList = types
  .model({
    list: types.array(Asset),
  })
  .volatile(() => ({
    loading: true,
    loadingExport: false,
    loadingLatestEarnings: false,
    pagination: Pagination.create({ totalItems: 0 }),
    searchFilter: '',
    platformStatesFilter: [] as PLATFORM_STATES[],
    platformNameFilter: null as null | DELIVERY_PLATFORM,
    storeFilter: [] as STORES[],
    assetStatesFilter: [] as APPROVAL_STATES[],
    assetTypesFilter: [] as ASSET_TYPE[],
    multipleIdsFilter: [] as string[],
    textFilter: '',
    userUuidFilter: '',
    siteUuidFilter: '',
    monthFilter: '',
    assignmentStatusFilter: '',
    splitStatusFilter: '',
    compositionShareFilter: null as null | boolean,
    hasActiveReferenceIdFilter: null as null | boolean,
    hasCustomIdFilter: null as null | boolean,

    isYoutubeActiveFilter: null as null | boolean,
    isCoverFilter: null as null | boolean,
    isCreatedByUsFilter: null as null | boolean,
    releaseAfterDateFilter: '',
    defaultAssetsMode: true, // ignore any client filters for the be when getting assets
    hasDefaultPlatformStates: true, // if true will send All approve state filter instead of null
    upcFilter: '',
    releaseUuidFilter: '',
    selectedAssets: [],
  }))
  .actions(self => ({
    setLoading(state: boolean) {
      self.loading = state
    },
    reset() {
      self.list = cast([])
    },
    setPagination(pagination: IPagination) {
      self.pagination = pagination
    },
    setSearchFilter(filter: string) {
      self.searchFilter = filter
    },
    setReleaseUuidFilter(filter: string) {
      self.releaseUuidFilter = filter
    },
    setIsCoverFilter(isCover: boolean) {
      self.isCoverFilter = isCover
    },
    setIsYoutubeActiveFilter(isActive: boolean | null) {
      self.isYoutubeActiveFilter = isActive
    },
    setIsCreatedByUsFilter(isCreatedByUs: boolean) {
      self.isCreatedByUsFilter = isCreatedByUs
    },
    setPlatformNameFilter(platform: null | DELIVERY_PLATFORM) {
      self.platformNameFilter = platform
    },
    setPlatformStatesFilter(states: PLATFORM_STATES[]) {
      self.platformStatesFilter = states
    },
    setAssetStatesFilter(states: APPROVAL_STATES[]) {
      self.assetStatesFilter = states
    },
    setStoresFilter(stores: STORES[]) {
      self.storeFilter = stores
    },

    setAssetTypesFilter(assetTypes: ASSET_TYPE[]) {
      self.assetTypesFilter = assetTypes
    },
    setTextFilter(text: string) {
      self.textFilter = text
    },
    setUPCFilter(text: string) {
      self.upcFilter = text
    },
    setMultipleIdsFilter(ids: string[]) {
      self.multipleIdsFilter = ids
    },

    setMonthFilter(month: string) {
      self.monthFilter = month
    },
    setReleaseAfterDateFilter(date: string) {
      self.releaseAfterDateFilter = date
    },
    setSiteFilter(site: string) {
      self.siteUuidFilter = site
    },
    setUserUuidFilter(userUuid: string | null) {
      self.userUuidFilter = userUuid || ''
    },
    setAssignmentFilter(assignment: string) {
      self.assignmentStatusFilter = assignment
    },
    setSplitFilter(split: string) {
      self.splitStatusFilter = split
    },
    setCompositionShareFilter(state: boolean | null) {
      self.compositionShareFilter = state
    },
    setHasActiveReferenceIdFilter(hasReference: boolean | null) {
      self.hasActiveReferenceIdFilter = hasReference
    },
    setHasCustomIdFilter(hasCustomId: boolean | null) {
      self.hasCustomIdFilter = hasCustomId
    },
    setHasDefaultPlatformStates(state: boolean) {
      self.hasDefaultPlatformStates = state
    },
    load: flow(function* (loadFragment?: string) {
      try {
        self.loading = true

        let param = ''
        if (ASSET_UUID_REGEX.test(self.textFilter)) param = 'uuid'
        else if (ASSET_ID_REGEX.test(self.textFilter)) param = 'assetId'
        else param = 'search'

        const variables = {
          pagination: self.pagination.allQueryParams,
          filters: {
            ...(self.textFilter && { [param]: self.textFilter }),
            ...(self.multipleIdsFilter.length > 0 && { multipleIds: self.multipleIdsFilter }),
            ...(self.monthFilter && { month: self.monthFilter }),
            ...(self.releaseAfterDateFilter && { releaseAfterDate: self.releaseAfterDateFilter }),
            ...(self.isYoutubeActiveFilter !== null && { isYoutubeActive: self.isYoutubeActiveFilter }),
            ...(self.isCoverFilter && { isCover: self.isCoverFilter }),
            ...(self.isCreatedByUsFilter && { isCreatedByUs: self.isCreatedByUsFilter }),
            ...(self.userUuidFilter && { userUuid: self.userUuidFilter }),
            ...(self.siteUuidFilter && { siteUuid: self.siteUuidFilter }),
            ...(self.upcFilter && { upc: self.upcFilter }),
            ...(self.assignmentStatusFilter && {
              isAssigned: self.assignmentStatusFilter === ASSIGNMENT_STATUSES.ASSIGNED.value,
            }),
            ...(self.splitStatusFilter && { isSplit: self.splitStatusFilter === SPLIT_STATUSES.ASSIGNED.value }),
            ...(self.compositionShareFilter !== null && { isCompositionShare: self.compositionShareFilter }),
            ...(self.hasActiveReferenceIdFilter !== null && {
              hasActiveReferenceId: self.hasActiveReferenceIdFilter,
            }),
            ...(self.hasCustomIdFilter !== null && { hasCustomId: self.hasCustomIdFilter }),
            ...(self.assetTypesFilter.length > 0 && { assetTypes: self.assetTypesFilter }),
            ...(!!self.assetStatesFilter.length && { assetStates: self.assetStatesFilter }),
            ...(!!self.storeFilter.length && { stores: self.storeFilter }),
            ...(self.releaseUuidFilter && { releaseUuid: self.releaseUuidFilter }),

            ...(self.platformStatesFilter !== null &&
              self.platformStatesFilter.length > 0 && { platformStates: self.platformStatesFilter }),

            ...(self.platformNameFilter && { platformName: self.platformNameFilter }),
            //  default: defaultMode,
          },
        }

        const resp: IGetAssetsResponse = yield getAssets(variables, loadFragment)

        if (resp && resp.data.data?.assets) {
          detach(self.list)
          self.list = cast(resp.data.data.assets.assets)
          self.pagination.setTotalItems(resp.data.data.assets.total)
        }
        self.loading = false
      } catch (err) {
        self.loading = false
        console.error(err)
      }
    }),

    getLatestEstimatedEarnings: flow(function* () {
      try {
        self.loadingLatestEarnings = true

        const assetIds = self.list.filter(el => el.assetId !== null).map(el => el.assetId) as string[]
        const resp: ILatestEstimatedEarningsResponse = yield latestEstimatedEarnings({ filters: { assetIds } })

        if (resp && resp.data.data?.latestEstimatedEarnings) {
          self.list.forEach(item => {
            const revenue = resp.data.data?.latestEstimatedEarnings.find(
              el => el.assetId === item.assetId
            )?.totalRevenue

            if (revenue) {
              item.setTotalRevenue(revenue)
            }
          })
        }
        self.loadingLatestEarnings = false
      } catch (e) {
        self.loadingLatestEarnings = false
        console.error(e)
      }
    }),

    loadRaw: flow(function* () {
      try {
        self.loading = true

        let param = ''
        if (ASSET_UUID_REGEX.test(self.textFilter)) param = 'uuid'
        else if (ASSET_ID_REGEX.test(self.textFilter)) param = 'assetId'
        else param = 'search'

        const variables = {
          pagination: self.pagination.allQueryParams,
          filters: {
            ...(self.textFilter && { [param]: self.textFilter }),
            ...(self.multipleIdsFilter.length > 0 && { multipleIds: self.multipleIdsFilter }),
            ...(self.monthFilter && { month: self.monthFilter }),
            ...(self.releaseAfterDateFilter && { releaseAfterDate: self.releaseAfterDateFilter }),
            ...(self.isCoverFilter && { isCover: self.isCoverFilter }),
            ...(self.isYoutubeActiveFilter !== null && { isYoutubeActive: self.isYoutubeActiveFilter }),

            ...(self.userUuidFilter && { userUuid: self.userUuidFilter }),
            ...(self.siteUuidFilter && { siteUuid: self.siteUuidFilter }),
            ...(self.assignmentStatusFilter && {
              isAssigned: self.assignmentStatusFilter === ASSIGNMENT_STATUSES.ASSIGNED.value,
            }),
            ...(self.splitStatusFilter && { isSplit: self.splitStatusFilter === SPLIT_STATUSES.ASSIGNED.value }),
            ...(self.compositionShareFilter !== null && { isCompositionShare: self.compositionShareFilter }),
            ...(self.hasCustomIdFilter !== null && { hasCustomId: self.hasCustomIdFilter }),
            ...(self.hasActiveReferenceIdFilter !== null && {
              hasActiveReferenceId: self.hasActiveReferenceIdFilter,
            }),
            ...(self.assetTypesFilter.length > 0 && { assetTypes: self.assetTypesFilter }),
            ...(self.platformNameFilter && { platformName: self.platformNameFilter }),
            ...(!!self.assetStatesFilter.length && { assetStates: self.assetStatesFilter }),
            ...(!!self.storeFilter.length && { stores: self.storeFilter }),
            ...(self.releaseUuidFilter && { releaseUuid: self.releaseUuidFilter }),
            ...(self.platformStatesFilter !== null &&
              self.platformStatesFilter.length > 0 && { platformStates: self.platformStatesFilter }),
          },
        }

        const resp: IGetAssetsRawResponse = yield getAssetsRaw(variables)

        if (resp && resp.data.data?.assetsRaw) {
          detach(self.list)
          self.list = cast(resp.data.data.assetsRaw.assets)
          self.pagination.setTotalItems(resp.data.data.assetsRaw.total)
        }
        self.loading = false
      } catch (e) {
        console.error(e)
        self.loading = false
      }
    }),

    downloadExport: flow(function* () {
      try {
        self.loadingExport = true

        let param = ''
        if (ASSET_UUID_REGEX.test(self.textFilter)) param = 'uuid'
        else if (ASSET_ID_REGEX.test(self.textFilter)) param = 'assetId'
        else param = 'search'

        const filters = {
          ...(self.platformNameFilter && { platformName: self.platformNameFilter }),
          ...(!!self.platformStatesFilter.length && { platformStates: self.platformStatesFilter }),

          ...(!!self.assetStatesFilter.length && { assetStates: self.assetStatesFilter }),

          ...(self.textFilter && { [param]: self.textFilter }),
          ...(self.multipleIdsFilter !== null &&
            self.multipleIdsFilter.length > 0 && { multipleIds: self.multipleIdsFilter }),
          ...(self.userUuidFilter && { userUuid: self.userUuidFilter }),
          ...(self.assignmentStatusFilter && {
            isAssigned: self.assignmentStatusFilter === ASSIGNMENT_STATUSES.ASSIGNED.value,
          }),
          ...(self.splitStatusFilter && { isSplit: self.splitStatusFilter === SPLIT_STATUSES.ASSIGNED.value }),
          ...(self.siteUuidFilter && { siteUuid: self.siteUuidFilter }),
          ...(!!self.storeFilter.length && { stores: self.storeFilter }),
          ...(self.assetTypesFilter && { assetTypes: self.assetTypesFilter }),
          ...(self.compositionShareFilter === false && { isCompositionShare: false }),
          ...(self.hasActiveReferenceIdFilter && { hasActiveReferenceId: self.hasActiveReferenceIdFilter }),
          ...(self.releaseUuidFilter && { releaseUuid: self.releaseUuidFilter }),
        }

        yield downloadAssetsXLSX(filters)

        self.loadingExport = false
      } catch (e) {
        console.error(e)
      }
    }),
  }))
  .views(self => ({
    byUuid(uuid: string | null | undefined) {
      if (!uuid) {
        return undefined
      }
      return self.list.find(el => el.uuid === uuid)
    },
    byUuids(uuids: string[]) {
      return self.list.filter(item => uuids.includes(item.uuid || ''))
    },

    get isLoading() {
      return self.loading
    },
    get uuids() {
      return self.list.map(asset => asset.uuid)
    },

    get length() {
      return self.list.length
    },

    get haveISRCs() {
      return self.list.map(track => !!track.isrc).reduce((curr, aggr) => curr && aggr, true) || false
    },

    get totalUploadProgress() {
      const assetsUploading = self.list
        .filter(asset => asset.fileUploadProgress !== null && asset.fileUploadProgress >= 0)
        .map(asset => asset.fileUploadProgress)

      const sum = assetsUploading.reduce((arr, total) => (arr || 0) + (total || 0), 0) || 0
      return sum / (assetsUploading.length || 1)
    },
    get hasDuplicateISRCs() {
      const isrcList = self.list.map(track => track.isrc).filter(isrc => !!isrc)

      return isrcList.some((val, i) => isrcList.indexOf(val) !== i)
    },
  }))
  .actions(self => ({
    // ? this is not integrated/ related with this list model
    bulkChangeState: flow(function* (uuids: string[], mode: APPROVAL_STATES, comment: string, successMessage?: string) {
      try {
        const patch = uuids.map(uuid => ({
          itemId: uuid,
          targetState: mode,
          comment,
        }))
        const resp: IChangeAssetStatesResponse = yield changeAssetsState(patch)
        if (resp && resp.data.data?.changeAssetsState.length && successMessage) {
          toast.success(successMessage)
        }
      } catch (err) {
        console.error(err)
      }
    }),

    bulkDelete: flow(function* (uuids: string[], successMessage?: string) {
      self.loading = true
      try {
        const resp: IDeleteAssetsResponse = yield deleteAssets(uuids)
        if (resp && resp.data.data?.deleteAssets && successMessage) {
          toast.success(successMessage)
        }
        self.loading = false
      } catch (err) {
        self.loading = false
        console.error(err)
      }
    }),

    destroyAsset(asset: IAsset) {
      destroy(asset)
    },
  }))
  .actions(self => ({
    addAsset(info: IAssetFormData, addInFront?: boolean): Promise<string | null> {
      // On creation we need to provide a temporary ID, so React doesn't get a keyError
      // Those IDs are swapped to the real uuids from the Backend
      // And only then they are pushed

      const req = { ...info }

      const current = Asset.create({
        ...{
          ...req,
        }, // add the store here
      })

      // we don't want send this to server as it's handled  by uploadFile function
      delete req.fileName
      delete req.fileSizeInBytes

      if (addInFront) {
        self.list.unshift(current)
      } else {
        self.list.push(current)
      }

      return current.createUpdate({ info: req, successMessage: 'Asset created' }) // returns uuid promise
    },
    addMSTAsset(asset: IAsset) {
      self.list.push(asset)
    },

    removeAssets: flow(function* (uuids: string[]) {
      try {
        const selectedAssets: IAsset[] = self.list.filter(asset => uuids.includes(asset.uuid || ''))

        // remove from list
        self.list = cast([...self.list.filter(item => !uuids.includes(item.uuid || ''))])

        yield self.bulkDelete(uuids)

        selectedAssets.forEach(asset => {
          self.destroyAsset(asset)
        })
      } catch (err) {
        console.error(err)
      }
    }),
  }))

export type IAssetList = Instance<typeof AssetList>
