import { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'

type ProfileMenuItem = {
  label: string
  icon: string
  redirectTo: string
}

type ProfileDropdownProps = {
  menuItems: Array<ProfileMenuItem>
  profilePic?: string
  username: string
  userTitle?: string
  showClientSelect?: boolean
}

type ProfileDropdownState = {
  dropdownOpen?: boolean
}

const ProfileDropdown = (props: ProfileDropdownProps, state: ProfileDropdownState): JSX.Element => {
  const profilePic = props.profilePic || null
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(state.dropdownOpen || false)
  const showClientSelect = props.showClientSelect || false

  /*
   * toggle profile-dropdown
   */
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  return (
    <Dropdown show={dropdownOpen} onToggle={toggleDropdown}>
      <Dropdown.Toggle
        data-cy="dropdown-profile"
        variant="link"
        id="dropdown-profile"
        onClick={toggleDropdown}
        className="nav-link dropdown-toggle nav-user arrow-none me-0 d-flex shadow-none font-sans"
      >
        <span className="account-user-avatar">
          <img src={profilePic || ''} className="rounded-circle d-none" alt="user" />
          <i className="uil uil-user-circle lh-1" style={{ fontSize: '32px' }} />
        </span>
        <span className="d-flex flex-column justify-content-center" style={{ minHeight: '37px' }}>
          <span className="account-user-name align-self-center">{props.username}</span>
          <span className="account-position">{props.userTitle}</span>
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
        <div>
          {/* <div className="dropdown-header noti-title">
            <h6 className="text-overflow m-0 text-uppercase">{props.userTitle}</h6>
          </div> */}
          {showClientSelect && (
            <Link to="/client-select" onClick={toggleDropdown} className="dropdown-item notify-item bg-gray-50">
              <i className="uil-user me-1" />
              <span>Select Client View</span>
            </Link>
          )}
          {props.menuItems.map(item => {
            return (
              <Link
                to={item.redirectTo}
                onClick={toggleDropdown}
                className="dropdown-item notify-item"
                key={item.label}
              >
                <i className={`${item.icon} me-1`} />
                <span>{item.label}</span>
              </Link>
            )
          })}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ProfileDropdown
