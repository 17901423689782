import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

import { SERVICE_NAME } from '../../../constants'
import { IUserBasic } from '../../../models/users/UserBasic.model'
import { extendedUserFragment } from '../fragments'

export type ILoginTotpProps = {
  token: string
  totp: string
  rememberMe?: boolean
}

export type ILoginTotpResponse = IGenericResponse<
  'loginTotp',
  { token: string; user: IUserBasic; withTotpToken?: boolean }
>

export async function loginTotp(data: ILoginTotpProps): Promise<ILoginTotpResponse> {
  const query = `
  mutation loginTotp($token: String!,$totp: String!,$rememberMe: Boolean) {
      loginTotp( token: $token,totp: $totp,rememberMe: $rememberMe){
        token
        user {
           ${extendedUserFragment}
        }
        withTotpToken
      } 
    }
  `

  return (await clientWrapper(SERVICE_NAME.USERS, query, data)) as ILoginTotpResponse
}
