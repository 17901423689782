import { DeleteModal, ImageUpload } from '@blocks'
import { IMAGE_TYPE } from '@epic-front/common'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { Card } from 'react-bootstrap'

import { IFanbeeProfile } from '@epic-front/common/models/fanbee/FanbeeProfile.model'
import { IImageBasic } from '@epic-front/common/models/general/ImageBasic.model'

import WidgetTitle from '../titles/WidgetTitle'

interface IFanbeeProfileImagesProps {
  fanbeeProfile: IFanbeeProfile
}

const FanbeeProfileImages = ({ fanbeeProfile }: IFanbeeProfileImagesProps) => {
  const [showDeleteProfilePictureModal, setShowDeleteProfilePictureModal] = useState<boolean>(false)
  const [showDeleteBannerImageModal, setShowDeleteBannerImageModal] = useState<boolean>(false)

  const handleProfilePictureUpload = async (imageData: IImageBasic) => {
    await fanbeeProfile.uploadProfileImage(imageData)
  }

  const handleBannerPictureUpload = async (imageData: IImageBasic) => {
    await fanbeeProfile.uploadBannerPicture(imageData)
  }

  const handleProfilePictureDelete = async () => {
    await fanbeeProfile.deleteProfilePicture()
    setShowDeleteProfilePictureModal(false)
  }

  const handleBannerPictureDelete = async () => {
    await fanbeeProfile.deleteBannerPicture()
    setShowDeleteBannerImageModal(false)
  }

  return (
    <div className="col-12 col-xxl-4 col-xxxl-3">
      <Card>
        <Card.Header>
          <WidgetTitle title="Avatar image" supportingText="The image shown at the top of your page." reverseOrder />
        </Card.Header>
        <Card.Body>
          <ImageUpload
            type={IMAGE_TYPE.FANBEE_PROFILE}
            previewUrl={fanbeeProfile.profileImage?.downloadUrl ?? undefined}
            minWidth={250}
            minHeight={250}
            onDelete={() => {
              setShowDeleteProfilePictureModal(true)
            }}
            onUploadComplete={handleProfilePictureUpload}
          />
          <div className="alert alert-info mt-3 mb-0 small text-center">
            Square proportions & minimum size: <b>250 x 250px</b>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <WidgetTitle
            title="Background image"
            supportingText="This image will appear in the top part of the page."
            reverseOrder
          />
        </Card.Header>
        <Card.Body>
          <ImageUpload
            type={IMAGE_TYPE.FANBEE_BANNER}
            previewUrl={fanbeeProfile.bannerImage?.downloadUrl ?? undefined}
            minWidth={1280}
            minHeight={640}
            onDelete={() => {
              setShowDeleteBannerImageModal(true)
            }}
            onUploadComplete={handleBannerPictureUpload}
          />
          <div className="alert alert-info mt-3 mb-0 small text-center">
            Landscape proportions & minimum size: <b>1280 x 640px</b>
          </div>
        </Card.Body>
      </Card>

      {/* Delete Profile Picture Modal */}
      <DeleteModal
        title="Delete Profile Picture"
        open={showDeleteProfilePictureModal}
        closeModal={() => {
          setShowDeleteProfilePictureModal(false)
        }}
        submit={handleProfilePictureDelete}
      >
        <p> Are you sure you want to delete the Avatar Image ?</p>
      </DeleteModal>

      {/* Delete Banner Image Modal */}
      <DeleteModal
        title="Delete Banner Image"
        open={showDeleteBannerImageModal}
        closeModal={() => {
          setShowDeleteBannerImageModal(false)
        }}
        submit={handleBannerPictureDelete}
      >
        <p> Are you sure you want to delete the Background Image ?</p>
      </DeleteModal>
    </div>
  )
}

export default observer(FanbeeProfileImages)
