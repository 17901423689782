import moment from 'moment'

export enum PLATFORM_STATES {
  PENDING = 'PENDING',
  QUEUED_FOR_DELIVERY = 'QUEUED_FOR_DELIVERY',
  DELIVERY_FINISHED = 'DELIVERY_FINISHED',
  DELIVERY_STARTED = 'DELIVERY_STARTED',
  DELIVERY_ERROR = 'DELIVERY_ERROR',
  LIVE = 'LIVE',
  UPCOMING = 'UPCOMING',
  TAKEN_DOWN = 'TAKEN_DOWN',
  UNKNOWN = 'UNKNOWN',
}

export enum APPROVAL_STATES {
  IMPORTED = 'IMPORTED',
  EMPTY = 'EMPTY',
  DRAFT = 'DRAFT',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export const CLIENT_EDITABLE_STATES = [APPROVAL_STATES.DRAFT, APPROVAL_STATES.REJECTED, APPROVAL_STATES.IMPORTED]

export const ASSIGNMENT_STATUSES = {
  ASSIGNED: { label: 'Assigned', value: 'assigned' },
  UNASSIGNED: { label: 'Unassigned', value: 'unassigned' },
}

export const YOUTUBE_STATUS_FILTER_OPTIONS = [
  {
    label: 'Active',
    value: true,
  },
  {
    label: 'Inactive',
    value: false,
  },
]

export const SPLIT_STATUSES = {
  ASSIGNED: { label: 'Split', value: 'split' },
  UNASSIGNED: { label: 'Unsplit', value: 'unsplit' },
}

export enum ASSET_TYPE {
  MUSIC_VIDEO = 'MusicVideo',
  SOUND_RECORDING = 'SoundRecording',
  ART_TRACK_VIDEO = 'ArtTrackVideo', // Remove this type after the db is cleaned
  ART_TRACK = 'ArtTrack', // Remove this type after the db is cleaned
  WEB = 'Web',
  MOVIE = 'Movie',
  TV_EPISODE = 'TVEpisode',
  SONGMATE = 'Songmate',
  COMPOSITION = 'Composition',
}

export const ART_TRACK_TYPE = 'ArtTrack'
export enum DELIVERY_PLATFORM {
  YOUTUBE = 'Youtube',
  AUDIO_SALAD = 'Audiosalad',
  DDEX = 'DDEX',
  SONGPITCH = 'Songpitch',
  SPOTIFY = 'Spotify',
  MICROLICENSING = 'Microlicensing',
}

export enum ANALYTICS_ASSET_TYPE {
  RELEASE = 'Release',
  TRACK = 'Track',
  CONTENT_ID = 'ContentIdAsset',
  CHANNEL_VIDEO = 'ChannelVideo',
}

export const PLATFORM_FOR_RELEASES = [DELIVERY_PLATFORM.AUDIO_SALAD, DELIVERY_PLATFORM.DDEX]

export type FileStruct = {
  file: File
  uuid?: string
  userUuid?: string
  upload: boolean
  uploadUrl?: string
  storagePath?: string
  downloadUrl?: string
}

export type DDEXUpload = {
  userUuid: string
  csv: File
  wavs: File[]
  jpegs: File[]
  uploadUrl?: string
}

export type TASFileStructContent = {
  uuid?: string
  file: File
  userUuid?: string
  uploadUrl?: string
  storagePath?: string
  downloadUrl?: string
  index?: number
}

export type TASFileStruct = {
  [uuid: string]: TASFileStructContent
}

export type AudioUploadCSVRow = {
  title: string
  artist: string
  album: string
  isrc: string
  upc: string
  label: string
  customId: string
  iswc: string
  isCover: string
  filename: string
}

export enum CLAIM_STATUSES {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
  TAKEDOWN = 'takedown',
  TAKEN_DOWN = 'takendown',
  POTENTIAL = 'potential',
  APPEALED = 'appealed',
  DISPUTED = 'disputed',
}

export enum CLAIM_STATUS_LABEL {
  REINSTATE = 'reinstate',
  RELEASE = 'release',
  TAKEDOWN = 'takedown',
}

export enum DISPUTE_REASON {
  AUTHORIZED = 'Authorized',
  FAIR_USE = 'FairUse',
  PUBLIC_DOMAIN = 'PublicDomain',
  ORIGINAL = 'Original',
}

export enum HISTORY_EVENT_TYPE {
  CLAIM_CREATE = 'ClaimCreate',
  DISPUTE_CREATE = 'DisputeCreate',
  CLAIM_CREATE_PENDING = 'ClaimCreatePending',
  CLAIM_REINSTATE = 'ClaimReinstate',
  CLAIM_UPDATE = 'ClaimUpdate',
  DISPUTE_APPEALED = 'DisputeAppealed',
  CLAIM_RELEASE = 'ClaimRelease',
  CLAIM_CLOSE = 'ClaimClose',
  APPEALED_CANCELLED = 'AppealedCancelled',
}

export enum CLAIM_TAKE_ACTION_ROLE {
  CLIENT = 'Client',
  ADMIN = 'Admin',
}

export enum AssetEarningCurveMonthlyType {
  RELEASE = 'Release',
  TRACK = 'Track',
}

export enum STORE_DELIVERY_STATE {
  LIVE = 'LIVE',
  PENDING = 'PENDING',
  ERROR = 'ERROR',
  TAKEDOWN = 'TAKEDOWN',
}
export enum LABEL_VALIDITY {
  NOT_CHECKED = 'NOT_CHECKED',
  EMPTY = 'EMPTY',
  VALID = 'VALID',
  INVALID = 'INVALID',
}

export enum UPLOAD_FILE_STATUS {
  UPLOADED = 'File Uploaded',
  MISSING = 'Missing File',
  UPLOADING = 'Loading...',
}

export enum RELEASE_FORMATS {
  ALBUM = 'Album',
  EP = 'EP',
  SINGLE = 'Single',
}

export enum PARTICIPANT_ROLES {
  ADDITIONAL_VOCALS = 'AdditionalVocals',
  ADAPTER = 'Adapter',
  ARRANGER = 'Arranger',
  BACKGROUND_VOCALS = 'BackgroundVocals',
  BASS = 'Bass',
  BRASS = 'Brass',
  CELLO = 'Cello',
  CHOIR = 'Choir',
  COMPOSER = 'Composer',
  CONDUCTOR = 'Conductor',
  ENGINEER = 'Engineer',
  MASTERING_ENGINEER = 'MasteringEngineer',
  DRUMS = 'Drums',
  ENSEMBLE = 'Ensemble',
  FEATURED_ARTIST = 'FeaturedArtist',
  GUITAR = 'Guitar',
  HORN = 'Horn',
  KEYBOARDS = 'Keyboards',
  LYRICIST = 'Lyricist',
  MAIN_ARTIST = 'MainArtist',
  MIXER = 'Mixer',
  MUSICIAN = 'Musician',
  ORCHESTRA = 'Orchestra',
  OTHER = 'Other',
  PERCUSSION = 'Percussion',
  PERFORMER = 'Performer',
  PIANO = 'Piano',
  PRODUCER = 'Producer',
  PROGRAMMING = 'Programming',
  PUBLISHER = 'Publisher',
  REMIXER = 'Remixer',
  SYNTHESIZER = 'Synthesizer',
  SONG_WRITER = 'Songwriter',
  TROMBONE = 'Trombone',
  TRUMPET = 'Trumpet',
  TUBA = 'Tuba',
  VIOLA = 'Viola',
  VIOLIN = 'Violin',
  VOCALS = 'Vocals',
  WOODWINDS = 'Woodwinds',
}

export const ARTIST_ROLES = [PARTICIPANT_ROLES.MAIN_ARTIST, PARTICIPANT_ROLES.FEATURED_ARTIST]
export const WRITER_ROLES = [
  PARTICIPANT_ROLES.ADAPTER,
  PARTICIPANT_ROLES.ARRANGER,
  PARTICIPANT_ROLES.PUBLISHER,
  PARTICIPANT_ROLES.LYRICIST,
  PARTICIPANT_ROLES.COMPOSER,
  PARTICIPANT_ROLES.SONG_WRITER,
]
export const PERFORMER_ROLES = [
  PARTICIPANT_ROLES.ADDITIONAL_VOCALS,
  PARTICIPANT_ROLES.BACKGROUND_VOCALS,
  PARTICIPANT_ROLES.BASS,
  PARTICIPANT_ROLES.BRASS,
  PARTICIPANT_ROLES.CELLO,
  PARTICIPANT_ROLES.CHOIR,
  PARTICIPANT_ROLES.CONDUCTOR,
  PARTICIPANT_ROLES.DRUMS,
  PARTICIPANT_ROLES.ENSEMBLE,
  PARTICIPANT_ROLES.GUITAR,
  PARTICIPANT_ROLES.HORN,
  PARTICIPANT_ROLES.KEYBOARDS,
  PARTICIPANT_ROLES.MUSICIAN,
  PARTICIPANT_ROLES.ORCHESTRA,
  PARTICIPANT_ROLES.PERCUSSION,
  PARTICIPANT_ROLES.PERFORMER,
  PARTICIPANT_ROLES.PIANO,
  PARTICIPANT_ROLES.PROGRAMMING,
  PARTICIPANT_ROLES.SYNTHESIZER,
  PARTICIPANT_ROLES.TROMBONE,
  PARTICIPANT_ROLES.TRUMPET,
  PARTICIPANT_ROLES.TUBA,
  PARTICIPANT_ROLES.VIOLA,
  PARTICIPANT_ROLES.VIOLIN,
  PARTICIPANT_ROLES.VOCALS,
  PARTICIPANT_ROLES.WOODWINDS,
]
export const ADDITIONAL_ROLES = Object.values(PARTICIPANT_ROLES).filter(
  role => ![...ARTIST_ROLES, ...WRITER_ROLES, ...PERFORMER_ROLES, PARTICIPANT_ROLES.OTHER].includes(role)
)

export const SPOTIFY_CLAIM = 'https://artists.spotify.com/claim'

export enum STORES {
  Youtube = 'Youtube',
  YoutubeMusic = 'YoutubeMusic',
  Spotify = 'Spotify',
  Apple = 'Apple',
  iTunes = 'iTunes',
  Audiomack = 'Audiomack',
  Deezer = 'Deezer',
  Amazon = 'Amazon',
  AmazonMusic = 'AmazonMusic',
  Tencent = 'Tencent',
  Mixcloud = 'Mixcloud',
  Napster = 'Napster',
  AudibleMagic = 'AudibleMagic',
  iHeartRadio = 'iHeartRadio',
  TikTok = 'TikTok',
  QOBUZ = 'QOBUZ',
  Anghami = 'Anghami',
  iMusica = 'iMusica',
  Slacker = 'Slacker',
  Pandora = 'Pandora',
  TouchTunes = 'TouchTunes',
  Curve = 'Curve', // check if has images
  NetEase = 'NetEase',
  Boomplay = 'Boomplay',
  Facebook = 'Facebook',
  FacebookVideo = 'FacebookVideo',
  AWA = 'AWA',
  SoundCloud = 'SoundCloud',
  SevenDigital = 'SevenDigital',
  JioSaavn = 'JioSaavn',
  Gracenote = 'Gracenote',
  KKBOX = 'KKBOX',
  Tidal = 'Tidal',
  TidalVideo = 'TidalVideo',
  APITest = 'APITest',
  Songpitch = 'Songpitch',
  Microlicensing = 'Microlicensing',
  AcrCloud = 'AcrCloud',
  Adaptr = 'Adaptr',
  Ami = 'Ami',
  Flo = 'Flo',
  // iMusicaAsDistro = 'iMusicaAsDistro',
  Jaxsta = 'Jaxsta',
  JOOX = 'JOOX',
  Leaplay = 'Leaplay',
  Lickd = 'Lickd',
  Peloton = 'Peloton',
  Trebel = 'Trebel',
  VevoVideo = 'VevoVideo',
  Alibaba = 'Alibaba',
  TunedGlobal = 'TunedGlobal',
}

export const STORES_IN_DISTRIBUTION_ANALYTICS = [
  STORES.Spotify,
  STORES.Amazon,
  STORES.Apple,
  STORES.Deezer,
  STORES.SoundCloud,
  STORES.Pandora,
]

export enum LICENSE_TYPE {
  CHANNEL_WHITELIST = 'ChannelWhitelist',
  MICROLICENSING = 'Microlicensing',
  GENERATED_CODE = 'GeneratedCode',
  THIRD_PARTY = 'ThirdParty',
}

export enum MICROLICENSING_MOODS {
  AGGRESSIVE = 'Aggressive',
  BLUESY = 'Bluesy',
  CLASSICAL = 'Classical',
  ENERGETIC = 'Energetic',
  INTENSE = 'Intense',
  RELAXED = 'Relaxed',
  SOULFUL = 'Soulful',
  UPBEAT = 'Upbeat',
}

export enum MICROLICENSING_AUDIO_TYPE {
  ALL = 'All',
  INSTRUMENTAL = 'Instrumental',
  VOCAL = 'Vocal',
}

export enum MICROLICENSING_KEY {
  A = 'A',
  AHash = 'AHash',
  B = 'B',
  C = 'C',
  CHash = 'CHash',
  D = 'D',
  DHash = 'DHash',
  E = 'E',
  F = 'F',
  FHash = 'FHash',
  G = 'G',
  GHash = 'GHash',
}

export enum MICROLICENSING_FILE_EXTENSION {
  WAV = 'wav',
  AIFF = 'aiff',
  MP3 = 'mp3',
}

export enum TOP_ASSETS_TYPE {
  EARNINGS = 'earnings',
  CLAIMS = 'claims',
  VIEWS = 'views',
}

export enum AUT_STEPS {
  PLATFORM_SELECT = 'PLATFORM_SELECT',
  RELEASE_DETAILS = 'RELEASE_DETAILS',
  TRACK_UPLOAD = 'TRACK_UPLOAD',
  AVAILABILITY = 'AVAILABILITY',
  CONFIRMATION = 'CONFIRMATION',
  CONGRATULATIONS = 'CONGRATULATIONS',
}

export enum RELEASE_DETAILS_SUBSECTIONS {
  TYPE = 'TYPE',
  DETAILS = 'DETAILS',
  ART_WORK = 'ART_WORK',
}
export const FIRST_DATE_WITH_DISTRIBUTION_ANALYTICS_DATA = '2024-01-01'
export const DEFAULT_ANALYTICS_START_DATE = moment().subtract(33, 'days')
export const DEFAULT_ANALYTICS_END_DATE = moment().subtract(3, 'days')

export const MIN_ART_IMG_WIDTH = 3000
export const MIN_ART_IMG_HEIGHT = 3000

export const ADVISORY_OPTIONS = {
  NONE: 'None',
  CLEAN: 'Clean',
  EXPLICIT: 'Explicit',
}

export enum PARTICIPANT_MODAL_TYPE {
  'ARTIST',
  'WRITER',
  'PERFORMER',
  'ADDITIONAL',
}
export enum CM_PLAYLIST_PLATFORM_FILTER {
  SPOTIFY = 'SPOTIFY',
  APPLE_MUSIC = 'APPLE_MUSIC',
  DEEZER = 'DEEZER',
  AMAZON = 'AMAZON',
  YOUTUBE = 'YOUTUBE',
}

export enum CM_PLAYLIST_PLATFORM_DATA {
  SPOTIFY = 'spotify',
  APPLE_MUSIC = 'applemusic',
  DEEZER = 'deezer',
  AMAZON = 'amazon',
  YOUTUBE = 'youtube',
}

export const CM_STORES_MAPPING = [
  {
    cmPlatformFilter: CM_PLAYLIST_PLATFORM_FILTER.AMAZON,
    cmPlatformData: CM_PLAYLIST_PLATFORM_DATA.AMAZON,
    name: STORES.Amazon,
  },
  {
    cmPlatformFilter: CM_PLAYLIST_PLATFORM_FILTER.APPLE_MUSIC,
    cmPlatformData: CM_PLAYLIST_PLATFORM_DATA.APPLE_MUSIC,
    name: STORES.Apple,
  },
  {
    cmPlatformFilter: CM_PLAYLIST_PLATFORM_FILTER.DEEZER,
    cmPlatformData: CM_PLAYLIST_PLATFORM_DATA.DEEZER,
    name: STORES.Deezer,
  },
  {
    cmPlatformFilter: CM_PLAYLIST_PLATFORM_FILTER.SPOTIFY,
    cmPlatformData: CM_PLAYLIST_PLATFORM_DATA.SPOTIFY,
    name: STORES.Spotify,
  },
  {
    cmPlatformFilter: CM_PLAYLIST_PLATFORM_FILTER.YOUTUBE,
    cmPlatformData: CM_PLAYLIST_PLATFORM_DATA.YOUTUBE,
    name: STORES.Youtube,
  },
]
