import { SERVICE_NAME } from '../../../../constants'
import { IReleaseFanbeeBasic } from '../../../../models/assets/releases/ReleaseFanbeeBasic.model'
import { IGenericResponse } from '../../../api.types'
import clientWrapper from '../../../clientWrapper'
import { releaseFanbeeFragment } from '../../fragmentsAssets'

export type IGetFanbeeLinkByReleaseUuidResponse = IGenericResponse<'fanbeeLinkByReleaseUuid', IReleaseFanbeeBasic>

export async function getFanbeeLinkByReleaseUuid(
  releaseUuid: string,
  fragment: string = releaseFanbeeFragment
): Promise<IGetFanbeeLinkByReleaseUuidResponse> {
  const query = `
    query fanbeeLinkByReleaseUuid ($releaseUuid: String!) {
      fanbeeLinkByReleaseUuid (releaseUuid: $releaseUuid) {
        ${fragment}
      }
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, { releaseUuid })) as IGetFanbeeLinkByReleaseUuidResponse
}
