import axios from 'axios'
import { toast } from 'react-toastify'
import { downloadXLSX, getLocalToken } from '../../../helpers/utils'
import { PLATFORM_STATES, APPROVAL_STATES, URL_ASSETS_API } from '../../../constants'

interface IDownloadArtTracksProps {
  userUuid?: string
  siteUuid?: string
  platformName?: string
  states?: PLATFORM_STATES[]
  assetState?: APPROVAL_STATES[]
  isAssigned?: boolean
  assetTypes?: string[]
  search?: string
  multipleIds?: string[]
  assetId?: string
  isCompositionShare?: boolean
  uuid?: string
  hasActiveReferenceId?: boolean
}

const downloadArtTracksXLSX = (filters: IDownloadArtTracksProps): Promise<boolean> => {
  const download = async () => {
    try {
      const token = getLocalToken()
      const SITE_TOKEN = process.env.REACT_APP_SITE_TOKEN

      const res = await axios({
        url: `${URL_ASSETS_API}/export/tracksList`,
        method: 'get',
        headers: { sitetoken: SITE_TOKEN || '', authorization: token || undefined },
        params: { ...filters },
        responseType: 'blob',
      })

      downloadXLSX(res.data, 'Art Tracks')
    } catch (e) {
      console.error(e)
      toast.error('There was a problem while generating the art tracks XLSX.')
    }

    return true
  }

  return download()
}

export default downloadArtTracksXLSX
