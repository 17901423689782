import clientWrapper from '../../clientWrapper'
import { IGenericResponse } from '../../api.types'

import { SERVICE_NAME } from '../../../constants'

import { IFanbeeProfileBasic } from '../../../models/fanbee/FanbeeProfileBasic.model'
import { fanbeeProfileFragment } from '../fragments'

export type IUpdateFanbeePlatformOrderProps = {
  platformOrder: string[]
  userUuid: string
}

export type IUpdateFanbeePlatformOrderResponse = IGenericResponse<'updateFanbeePlatformOrder', IFanbeeProfileBasic>

export async function updateFanbeePlatformOrder(
  { platformOrder, userUuid }: IUpdateFanbeePlatformOrderProps,
  fragment: string = fanbeeProfileFragment
): Promise<IUpdateFanbeePlatformOrderResponse> {
  const query = `

    mutation UpdateFanbeePlatformOrder($platformOrder: [String!]!, $userUuid: String!) {
      updateFanbeePlatformOrder(platformOrder: $platformOrder, userUuid: $userUuid) {
        ${fragment}
      }
    }
  `

  return (await clientWrapper(SERVICE_NAME.USERS, query, {
    platformOrder,
    userUuid,
  })) as IUpdateFanbeePlatformOrderResponse
}
