import axios, { AxiosProgressEvent } from 'axios'
import { useEffect, useState } from 'react'
import { Alert, Button, ProgressBar } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import Lightbox from 'react-image-lightbox'
import { toast } from 'react-toastify'

import { IGetImageUploadUrlResponse, getImageUploadUrl } from '@epic-front/common/api/users-api/other/getImageUploadUrl'
import { IMAGE_TYPE, IMG_MIME_TYPES } from '@epic-front/common/constants'
import { IImageBasic } from '@epic-front/common/models/general/ImageBasic.model'

const MIN_IMG_WIDTH = 3000
const MIN_IMG_HEIGHT = 3000

interface IImageUploadProps {
  type: IMAGE_TYPE
  showButtons?: boolean
  showTopDeleteButton?: boolean
  previewUrl?: string
  minWidth?: number
  minHeight?: number
  onDelete: () => void
  onUploadComplete?: (imageData: IImageBasic, previewSrc?: string) => void
}

const UserImageUpload = ({
  type,
  showButtons = true,
  showTopDeleteButton = false,
  previewUrl,
  minWidth = MIN_IMG_WIDTH,
  minHeight = MIN_IMG_HEIGHT,
  onDelete,
  onUploadComplete,
}: IImageUploadProps): JSX.Element => {
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)

  const [previewSrc, setPreviewSrc] = useState<string>('')

  useEffect(() => {
    if (!previewUrl) {
      setPreviewSrc('')
      return
    }

    setPreviewSrc(previewUrl)
  }, [previewUrl])

  const upload = async (file: File) => {
    try {
      setLoading(true)

      const resp: IGetImageUploadUrlResponse = await getImageUploadUrl({
        fileName: file.name,
        type,
      })

      if (resp?.data.data?.getImageUploadUrl) {
        const imageData = resp?.data.data?.getImageUploadUrl
        if (imageData.uploadUrl) {
          const uploadResponse = await axios.put(imageData.uploadUrl, file, {
            headers: {
              'Content-Type': file.type,
            },
            onUploadProgress: (e: AxiosProgressEvent) => {
              if (e.total) setProgress(Math.round((100 * e.loaded) / e.total))
            },
          })

          if (uploadResponse && onUploadComplete) {
            const imagePreview = URL.createObjectURL(file)

            onUploadComplete(imageData, imagePreview)
            setPreviewSrc(imagePreview)
          }
        }
      }
    } catch (err) {
      toast.error('There was a problem while trying to upload the picture...')
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const onDrop = (droppedFiles: File[]) => {
    const file = droppedFiles[0]

    const img: HTMLImageElement = new Image()
    img.src = URL.createObjectURL(file)
    img.onload = () => {
      if (img.width < minWidth || img.height < minHeight) {
        toast.error("Image doesn't have the required dimensions!")
        return
      }
      upload(file)
    }
  }

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    accept: IMG_MIME_TYPES,
    maxFiles: 1,
    disabled: loading,
  })

  return (
    <>
      {!previewSrc && (
        <div className="dropzone m-0" {...getRootProps()}>
          <div className="dz-message">
            <input id="" {...getInputProps()} />
            <i className="uil-cloud-upload fs-1" />
            <h4>Drop image here or click to upload</h4>
          </div>
        </div>
      )}
      {loading && <ProgressBar className="mt-2 progress-bar-striped" now={progress} />}
      {!!fileRejections.length && (
        <Alert variant="danger mt-2">
          The file has invalid format: {fileRejections.map(rejection => rejection.file.name).join(', ')}
        </Alert>
      )}
      {previewSrc && (
        <div className="image-card border border-gray-300 rounded-2 primary p-2 justify-content-between bg-gray-200 position-relative">
          {showTopDeleteButton && (
            <div
              className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-0 d-flex justify-content-center align-items-center"
              style={{ zIndex: 10, width: '3em', height: '3em' }}
            >
              <Button variant="outline-danger p-0" onClick={onDelete}>
                <i className="uil uil-trash text-white" />
              </Button>{' '}
            </div>
          )}

          {showPreview && <Lightbox mainSrc={previewSrc} onCloseRequest={() => setShowPreview(false)} />}
          <button
            className="w-100 border-0 rounded-0 p-0 bg-transparent"
            onClick={() => setShowPreview(true)}
            type="button"
          >
            <img src={previewSrc} alt="cover" />
          </button>

          {showButtons && (
            <div className="d-flex flex-row mt-2 gap-2">
              <Button className="btn flex-grow-1" onClick={() => setShowPreview(true)}>
                <i className="uil uil-search-plus" />
                <span>Zoom</span>
              </Button>
              <Button variant="danger" className="btn flex-grow-1" onClick={onDelete}>
                <i className="uil uil-trash" />
                <span>Delete</span>
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default UserImageUpload
