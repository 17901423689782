import { Instance, types } from 'mobx-state-tree'
import { CLAIM_STATUSES } from '../../constants'

export const ClaimCount = types
  .model({
    userUuid: types.maybeNull(types.string),
    total: types.maybeNull(types.number),
    byStatus: types.maybeNull(
      types.array(
        types.model({
          status: types.maybeNull(types.string),
          total: types.maybeNull(types.number),
        })
      )
    ),
  })
  .views(self => ({
    get potentialClaims() {
      return self.byStatus?.filter(count => count.status === CLAIM_STATUSES.POTENTIAL)?.[0]?.total
    },
    get appealedClaims() {
      return self.byStatus?.filter(count => count.status === CLAIM_STATUSES.APPEALED)?.[0]?.total
    },
    get disputedClaims() {
      return self.byStatus?.filter(count => count.status === CLAIM_STATUSES.DISPUTED)?.[0]?.total
    },
  }))
export type IClaimCount = Instance<typeof ClaimCount>
