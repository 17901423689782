import { Instance, types } from 'mobx-state-tree'
import { BANKTRANSFER_MANUAL_TYPE } from '@epic-front/common/constants'
import { BankTransferDetails } from './methods/BankTransferDetails.model'
import { OfxDetails } from './methods/ofx/OfxDetails.model'
import { PayoneerDetails } from './methods/PayoneerDetails.model'
import { PaypalDetails } from './methods/PaypalDetails.model'
import { TransferWiseDetails } from './methods/transferWise/TransferWiseDetails.model'
import { PaymentMethod } from './PaymentMethod.model'

export const UserPaymentMethodBasic = types.model('userPaymentMethod', {
  uuid: types.optional(types.string, ''),
  userUuid: types.maybeNull(types.string),
  userHash: types.maybeNull(types.string),
  sessionUuid: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  internalStatus: types.maybeNull(types.string),
  paymentMethod: types.maybeNull(PaymentMethod),
  internalStatusRejectionReason: types.maybeNull(types.string),
  details: types.maybeNull(
    types.union(OfxDetails, PaypalDetails, BankTransferDetails, TransferWiseDetails, PayoneerDetails)
  ),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  bankTransferManualType: types.maybeNull(types.enumeration(Object.values(BANKTRANSFER_MANUAL_TYPE))),
})

export type IUserPaymentMethodBasic = Instance<typeof UserPaymentMethodBasic>
