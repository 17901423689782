import { ADMIN, ELITE_ALLIANCE_MUSIC, EPIC_ELITE, PLATFORM, SONGMATE } from '@epic-front/common/constants'
import { Suspense, lazy, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'

import * as layoutConstants from '@constants'
import { changeBodyAttribute } from '@epic-front/common/helpers/utils'
import AutoReleaseClaimConfirmModal from './AutoReleaseClaimConfirmModal'
import VersionModal from './VersionModal'

const Topbar = lazy(() => import('./Topbar'))
const LeftSidebar = lazy(() => import('./LeftSidebar'))
const Footer = lazy(() => import('./Footer'))

const loading = () => <div />

type VerticalLayoutState = {
  isMenuOpened?: boolean
}

const maxWidth = PLATFORM === ADMIN ? '1980px' : '1980px'

const VerticalLayout = (state: VerticalLayoutState): JSX.Element => {
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(state.isMenuOpened || false)

  useEffect(() => {
    changeBodyAttribute('data-leftbar-theme', layoutConstants.LEFT_SIDEBAR_THEME_DARK)
  }, [])
  /**
   * Open the menu when having mobile screen
   */
  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened)

    if (document.body) {
      if (isMenuOpened) {
        document.body.classList.remove('sidebar-enable')
      } else {
        document.body.classList.add('sidebar-enable')
      }
    }
  }

  return (
    <>
      <div className="wrapper">
        <Suspense fallback={loading()}>
          <LeftSidebar setMenuState={setIsMenuOpened} />
        </Suspense>
        <div className="content-page">
          <div className="content">
            <Suspense fallback={loading()}>
              <Topbar openLeftMenuCallBack={openMenu} hideLogo />
            </Suspense>
            <Container fluid style={{ maxWidth }}>
              <Suspense fallback={loading()}>
                <Outlet />
              </Suspense>
            </Container>
          </div>

          <Suspense fallback={loading()}>
            <Footer />
          </Suspense>
          <Suspense fallback={loading()}>
            <VersionModal />
            {(PLATFORM === SONGMATE || PLATFORM === EPIC_ELITE || PLATFORM === ELITE_ALLIANCE_MUSIC) && (
              <AutoReleaseClaimConfirmModal />
            )}
          </Suspense>
        </div>
      </div>

      {/* <Suspense fallback={loading()}>
        <RightSidebar title="Notifications">
          <div className="list-group">
            <a href="/#" className="list-group-item list-group-item-action" aria-current="true">
              <h6 className="badge bg-info float-end">11/12/2021</h6>
              <h5 className="mb-1">Notification title</h5>
              <small className="text-gray-500">Autem eum iusto dolore repudiandae adipisicing turpis</small>
            </a>
            <a href="/#" className="list-group-item list-group-item-action" aria-current="true">
              <h6 className="badge bg-info float-end">11/12/2021</h6>
              <h5 className="mb-1">A long notification title can also go here</h5>
              <small className="text-gray-500">Autem eum iusto dolore repudiandae adipisicing turpis</small>
            </a>
            <a href="/#" className="list-group-item list-group-item-action" aria-current="true">
              <h6 className="badge bg-info float-end">11/12/2021</h6>
              <h5 className="mb-1">Notification title goes here</h5>
              <small className="text-gray-500">Autem eum iusto dolore repudiandae adipisicing turpis</small>
            </a>
            <a href="/#" className="list-group-item list-group-item-action" aria-current="true">
              <h6 className="badge bg-info float-end">11/12/2021</h6>
              <h5 className="mb-1">Notification title goes here</h5>
              <small className="text-gray-500">Autem eum iusto dolore repudiandae adipisicing turpis</small>
            </a>
          </div>
        </RightSidebar>
      </Suspense> */}
    </>
  )
}
export default VerticalLayout
