import { Instance, types } from 'mobx-state-tree'

import { RELEASE_FORMATS } from '../../../constants'

import { StreamingLink } from './StreamingLink.model'
import { TrackFanbeeBasic } from './TrackFanbee.model'
import { FanbeeLinkBasic } from './FanbeeLinkBasic.model'

export const ReleaseFanbeeBasic = types.model({
  uuid: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  artist: types.maybeNull(types.string),
  imageUrl: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  fanbeeLink: types.maybeNull(FanbeeLinkBasic),
  releaseFormat: types.maybeNull(types.enumeration(Object.values(RELEASE_FORMATS))),
  streamingLinks: types.array(StreamingLink),
  tracks: types.array(TrackFanbeeBasic),
})

export type IReleaseFanbeeBasic = Instance<typeof ReleaseFanbeeBasic>
