import { Instance, cast, destroy, detach, flow, types } from 'mobx-state-tree'
import { toast } from 'react-toastify'

import {
  APPROVAL_STATES,
  ASSET_ID_REGEX,
  ASSET_UUID_REGEX,
  ASSIGNMENT_STATUSES,
  DELIVERY_PLATFORM,
  PLATFORM_STATES,
  SPLIT_STATUSES,
  STORES,
  STORE_DELIVERY_STATE,
} from '../../../constants'

import { IDeleteTracksResponse, deleteTracks } from '../../../api/assets-api/assets/deleteTracks'
import { IGetArtTracksResponse, getArtTracks } from '../../../api/assets-api/assets/getArtTracks'
import {
  ILatestEstimatedEarningsResponse,
  latestEstimatedEarnings,
} from '../../../api/assets-api/assets/latestEstimatedEarnings'
import { IFindReleaseByUuidResponse, findReleaseByUuid } from '../../../api/assets-api/findReleaseByUuid'
import { loadReleaseTracksFragment } from '../../../api/assets-api/fragmentsAssets'
import { IAssetFormData } from '../../../types/common'
import { IPagination, Pagination } from '../../general/Pagination.model'
import { Store } from '../StoreModel'
import { ArtTrack, IArtTrack } from './ArtTrack.model'
import downloadArtTracksXLSX from '../../../api/assets-api/other/downloadArtTracksXLSX'

export const ArtTrackList = types
  .model({
    list: types.array(ArtTrack),
  })
  .volatile(() => ({
    loading: true,
    loadingExport: false,
    loadingLatestEarnings: false,
    pagination: Pagination.create({ totalItems: 0 }),
    searchFilter: '',
    platformStatesFilter: [] as PLATFORM_STATES[],
    platformNameFilter: null as null | DELIVERY_PLATFORM,
    storeFilter: [] as STORES[],
    assetStatesFilter: [] as APPROVAL_STATES[],
    multipleIdsFilter: [] as string[],
    textFilter: '',
    userUuidFilter: '',
    siteUuidFilter: '',
    monthFilter: '',
    assignmentStatusFilter: '',
    splitStatusFilter: '',
    compositionShareFilter: null as null | boolean,
    hasActiveReferenceIdFilter: null as null | boolean,
    hasCustomIdFilter: null as null | boolean,

    isCoverFilter: null as null | boolean,
    isCreatedByUsFilter: null as null | boolean,
    releaseAfterDateFilter: '',
    defaultAssetsMode: true, // ignore any client filters for the be when getting assets
    hasDefaultPlatformStates: true, // if true will send All approve state filter instead of null
    importSourceFilter: '',
    upcFilter: '',
    releaseUuidFilter: '',

    selectedAssets: [],
  }))
  .actions(self => ({
    setLoading(state: boolean) {
      self.loading = state
    },
    reset() {
      self.list = cast([])
    },
    setPagination(pagination: IPagination) {
      self.pagination = pagination
    },
    setSearchFilter(filter: string) {
      self.searchFilter = filter
    },
    setReleaseUuidFilter(filter: string) {
      self.releaseUuidFilter = filter
    },
    setIsCoverFilter(isCover: boolean) {
      self.isCoverFilter = isCover
    },
    setIsCreatedByUsFilter(isCreatedByUs: boolean) {
      self.isCreatedByUsFilter = isCreatedByUs
    },
    setPlatformNameFilter(platform: null | DELIVERY_PLATFORM) {
      self.platformNameFilter = platform
    },
    setPlatformStatesFilter(states: PLATFORM_STATES[]) {
      self.platformStatesFilter = states
    },
    setAssetStatesFilter(states: APPROVAL_STATES[]) {
      self.assetStatesFilter = states
    },
    setStoresFilter(stores: STORES[]) {
      self.storeFilter = stores
    },

    setTextFilter(text: string) {
      self.textFilter = text
    },
    setUPCFilter(text: string) {
      self.upcFilter = text
    },
    setMultipleIdsFilter(ids: string[]) {
      self.multipleIdsFilter = ids
    },
    setImportSourceFilter(source: string) {
      self.importSourceFilter = source
    },
    setMonthFilter(month: string) {
      self.monthFilter = month
    },
    setReleaseAfterDateFilter(date: string) {
      self.releaseAfterDateFilter = date
    },
    setSiteFilter(site: string) {
      self.siteUuidFilter = site
    },
    setUserUuidFilter(userUuid: string | null) {
      self.userUuidFilter = userUuid || ''
    },
    setAssignmentFilter(assignment: string) {
      self.assignmentStatusFilter = assignment
    },
    setSplitFilter(split: string) {
      self.splitStatusFilter = split
    },
    setCompositionShareFilter(state: boolean | null) {
      self.compositionShareFilter = state
    },
    setHasActiveReferenceIdFilter(hasReference: boolean | null) {
      self.hasActiveReferenceIdFilter = hasReference
    },
    setHasCustomIdFilter(hasCustomId: boolean | null) {
      self.hasCustomIdFilter = hasCustomId
    },
    setHasDefaultPlatformStates(state: boolean) {
      self.hasDefaultPlatformStates = state
    },
    load: flow(function* (loadFragment?: string) {
      try {
        self.loading = true

        let param = ''
        if (ASSET_UUID_REGEX.test(self.textFilter)) param = 'uuid'
        else if (ASSET_ID_REGEX.test(self.textFilter)) param = 'assetId'
        else param = 'search'

        const variables = {
          pagination: self.pagination.allQueryParams,
          filters: {
            ...(self.textFilter && { [param]: self.textFilter }),
            ...(self.multipleIdsFilter.length > 0 && { multipleIds: self.multipleIdsFilter }),
            ...(self.isCoverFilter && { isCover: self.isCoverFilter }),
            ...(self.isCreatedByUsFilter && { isCreatedByUs: self.isCreatedByUsFilter }),
            ...(self.userUuidFilter && { userUuid: self.userUuidFilter }),
            ...(self.siteUuidFilter && { siteUuid: self.siteUuidFilter }),
            ...(self.upcFilter && { upc: self.upcFilter }),
            ...(self.importSourceFilter && { importSource: self.importSourceFilter }),
            ...(self.assignmentStatusFilter && {
              isAssigned: self.assignmentStatusFilter === ASSIGNMENT_STATUSES.ASSIGNED.value,
            }),
            ...(self.splitStatusFilter && { isSplit: self.splitStatusFilter === SPLIT_STATUSES.ASSIGNED.value }),
            ...(self.compositionShareFilter !== null && { isCompositionShare: self.compositionShareFilter }),
            ...(self.hasActiveReferenceIdFilter !== null && {
              hasActiveReferenceId: self.hasActiveReferenceIdFilter,
            }),
            ...(self.hasCustomIdFilter !== null && { hasCustomId: self.hasCustomIdFilter }),
            ...(!!self.assetStatesFilter.length && { assetStates: self.assetStatesFilter }),
            ...(!!self.storeFilter.length && { stores: self.storeFilter }),
            ...(self.releaseUuidFilter && { releaseUuid: self.releaseUuidFilter }),

            ...(self.platformStatesFilter !== null &&
              self.platformStatesFilter.length > 0 && { platformStates: self.platformStatesFilter }),

            ...(self.platformNameFilter && { platformName: self.platformNameFilter }),
            //  default: defaultMode,
          },
        }

        const resp: IGetArtTracksResponse = yield getArtTracks(variables, loadFragment)

        if (resp && resp.data.data?.tracks) {
          detach(self.list)
          self.list = cast(resp.data.data.tracks.tracks)
          self.pagination.setTotalItems(resp.data.data.tracks.total)
        }
        self.loading = false
      } catch (err) {
        self.loading = false
        console.error(err)
      }
    }),

    getLatestEstimatedEarnings: flow(function* () {
      try {
        self.loadingLatestEarnings = true

        const assetIds = self.list.filter(el => el.assetId !== null).map(el => el.assetId) as string[]
        const resp: ILatestEstimatedEarningsResponse = yield latestEstimatedEarnings({ filters: { assetIds } })

        if (resp && resp.data.data?.latestEstimatedEarnings) {
          self.list.forEach(item => {
            const revenue = resp.data.data?.latestEstimatedEarnings.find(
              el => el.assetId === item.assetId
            )?.totalRevenue

            if (revenue) {
              item.setTotalRevenue(revenue)
            }
          })
        }
        self.loadingLatestEarnings = false
      } catch (e) {
        self.loadingLatestEarnings = false
        console.error(e)
      }
    }),

    downloadExport: flow(function* () {
      try {
        self.loadingExport = true

        let param = ''
        if (ASSET_UUID_REGEX.test(self.textFilter)) param = 'uuid'
        else if (ASSET_ID_REGEX.test(self.textFilter)) param = 'assetId'
        else param = 'search'

        const filters = {
          ...(self.platformNameFilter && { platformName: self.platformNameFilter }),
          ...(!!self.platformStatesFilter.length && { platformStates: self.platformStatesFilter }),

          ...(!!self.assetStatesFilter.length && { assetStates: self.assetStatesFilter }),

          ...(self.textFilter && { [param]: self.textFilter }),
          ...(self.multipleIdsFilter !== null &&
            self.multipleIdsFilter.length > 0 && { multipleIds: self.multipleIdsFilter }),
          ...(self.userUuidFilter && { userUuid: self.userUuidFilter }),
          ...(self.assignmentStatusFilter && {
            isAssigned: self.assignmentStatusFilter === ASSIGNMENT_STATUSES.ASSIGNED.value,
          }),
          ...(self.splitStatusFilter && { isSplit: self.splitStatusFilter === SPLIT_STATUSES.ASSIGNED.value }),
          ...(self.siteUuidFilter && { siteUuid: self.siteUuidFilter }),
          ...(!!self.storeFilter.length && { stores: self.storeFilter }),
          ...(self.compositionShareFilter === false && { isCompositionShare: false }),
          ...(self.hasActiveReferenceIdFilter && { hasActiveReferenceId: self.hasActiveReferenceIdFilter }),
          ...(self.releaseUuidFilter && { releaseUuid: self.releaseUuidFilter }),
        }

        yield downloadArtTracksXLSX(filters)

        self.loadingExport = false
      } catch (e) {
        console.error(e)
      }
    }),
  }))
  .views(self => ({
    byUuid(uuid: string | null | undefined) {
      if (!uuid) {
        return undefined
      }
      return self.list.find(el => el.uuid === uuid)
    },
    byUuids(uuids: string[]) {
      return self.list.filter(item => uuids.includes(item.uuid || ''))
    },

    get isLoading() {
      return self.loading
    },

    get uuids() {
      return self.list.map(asset => asset.uuid ?? '')
    },

    get length() {
      return self.list.length
    },

    get haveISRCs() {
      return self.list.map(track => !!track.isrc).reduce((curr, aggr) => curr && aggr, true) || false
    },

    get totalUploadProgress() {
      const assetsUploading = self.list
        .filter(asset => asset.fileUploadProgress !== null && asset.fileUploadProgress >= 0)
        .map(asset => asset.fileUploadProgress)

      const sum = assetsUploading.reduce((arr, total) => (arr || 0) + (total || 0), 0) || 0
      return sum / (assetsUploading.length || 1)
    },
    get hasDuplicateISRCs() {
      const isrcList = self.list.map(track => track.isrc).filter(isrc => !!isrc)

      return isrcList.some((val, i) => isrcList.indexOf(val) !== i)
    },
  }))
  .actions(self => ({
    bulkDelete: flow(function* (uuids: string[], successMessage?: string) {
      self.loading = true
      try {
        const resp: IDeleteTracksResponse = yield deleteTracks(uuids)
        if (resp && resp.data.data?.deleteTracks && successMessage) {
          toast.success(successMessage)
        }
        self.loading = false
      } catch (err) {
        self.loading = false
        console.error(err)
      }
    }),

    destroyAsset(asset: IArtTrack) {
      destroy(asset)
    },
    sortByTrackOrder: (trackOrderUuids: string[]) => {
      self.list.sort((a, b) => trackOrderUuids.indexOf(a.uuid || '') - trackOrderUuids.indexOf(b.uuid || ''))
    },
  }))
  .actions(self => ({
    addTrack: flow(function* (info: IAssetFormData, addInFront?: boolean) {
      // On creation we need to provide a temporary ID, so React doesn't get a keyError
      // Those IDs are swapped to the real uuids from the Backend
      // And only then they are pushed

      const req = { ...info }

      const current = ArtTrack.create({
        ...{
          ...req,
          stores: info.stores
            ? info.stores.map(store =>
                Store.create({ deliveryPlatform: null, name: store, status: STORE_DELIVERY_STATE.PENDING })
              )
            : [],
        }, // add the store here
      })

      // we don't want send this to server as it's handled  by uploadFile function
      delete req.fileName
      delete req.fileSizeInBytes

      const resp: string = yield current.createUpdate({ info: req, successMessage: 'Track created' }) // returns uuid promise

      if (addInFront) {
        self.list.unshift(current)
      }

      if (!addInFront) {
        self.list.push(current)
      }

      return resp
    }),
    addMSTAsset(asset: IArtTrack) {
      self.list.push(asset)
    },

    removeTracks: flow(function* (uuids: string[]) {
      try {
        const selectedAssets: IArtTrack[] = self.list.filter(asset => uuids.includes(asset.uuid || ''))

        // remove from list
        self.list = cast([...self.list.filter(item => !uuids.includes(item.uuid || ''))])

        yield self.bulkDelete(uuids)

        selectedAssets.forEach(asset => {
          self.destroyAsset(asset)
        })
      } catch (err) {
        console.error(err)
      }
    }),

    releaseLoad: flow(function* (uuid: string, fragment: string = loadReleaseTracksFragment) {
      try {
        self.loading = true

        const resp: IFindReleaseByUuidResponse = yield findReleaseByUuid(uuid, fragment)

        if (resp && resp.data.data?.findReleaseByUuid) {
          const audioResp = resp.data.data?.findReleaseByUuid.tracks
          if (audioResp) {
            self.list = cast(audioResp)
          }
        }
        self.loading = false
      } catch (err) {
        self.loading = false
        console.error(err)
      }
    }),
  }))

export type IArtTrackList = Instance<typeof ArtTrackList>
