import { SITE_SLUG } from '../general/general'

export const ASSETS_SITE_BADGE_COLOR: {
  [key: string]: string
} = {
  ds: 'site-ds',
  ea: 'site-ea',
  ee: 'site-ee',
  mea: 'site-mea',
  adm: 'site-admin',
  sm: 'site-sm',
  sp: 'site-sp',
  ml: 'site-ml',
  sl: 'site-sl',
  fb: 'site-fb',
}

export const CMSES_BADGE_COLOR: {
  [key: string]: string
} = {
  ea: 'cms-ea',
  ee: 'cms-ee',
  mea: 'cms-mea',
  pub: 'cms-pub',
}

export const SITES_WITH_ASSETS_SLUGS = [SITE_SLUG.ELITE_ALLIANCE, SITE_SLUG.EPIC_ELITE, SITE_SLUG.ELITE_ALLIANCE_MUSIC]
