import { cast, flow, Instance, types } from 'mobx-state-tree'
import { Pagination } from '../general/Pagination.model'
import { FanbeeProfileBasic } from './FanbeeProfileBasic.model'
import { fanbeeProfiles, IFanbeeProfilesResponse } from '../../api/users-api/fanbee/fanbeeProfiles'

export const FanbeeProfileList = types
  .model({
    list: types.array(FanbeeProfileBasic),
  })
  .volatile(() => ({
    loading: false,
    pagination: Pagination.create({ totalItems: 1 }),
    userUuidFilter: '',
  }))
  .actions(self => ({
    setUserUuidFilter(userUuid: string) {
      self.userUuidFilter = userUuid
    },
    load: flow(function* () {
      try {
        self.loading = true

        const resp: IFanbeeProfilesResponse = yield fanbeeProfiles({
          pagination: self.pagination.allQueryParams,
          filters: { ...(self.userUuidFilter && { userUuid: self.userUuidFilter }) },
        })

        if (resp && resp.data.data?.fanbeeProfiles.profiles) {
          self.list = cast(resp.data.data.fanbeeProfiles.profiles)
          self.pagination.setTotalItems(resp.data.data.fanbeeProfiles.total)
        }

        self.loading = false
      } catch (e) {
        console.error(e)
        self.loading = false
      }
    }),
  }))

export type IFanbeeProfileList = Instance<typeof FanbeeProfileList>
