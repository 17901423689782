import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export interface IUpdateMotionArrayTCProps {
  accepted: boolean
}

export type IUpdateMotionArrayTCResponse = IGenericResponse<'updateMotionArrayTC', { uuid: string }>

export async function updateMotionArrayTC({
  accepted,
}: IUpdateMotionArrayTCProps): Promise<IUpdateMotionArrayTCResponse> {
  const query = `
    mutation UpdateMotionArrayTC($accepted: Boolean!) {
        updateMotionArrayTC(accepted: $accepted) {
            uuid
        }
    }
  `

  return clientWrapper(SERVICE_NAME.USERS, query, { accepted }) as Promise<IUpdateMotionArrayTCResponse>
}
