import { SubmissionEvent } from '@epic-front/common/constants'
import { createContext, useState } from 'react'

interface ISubmitContext {
  submission: SubmissionEvent
  setSubmission: (value: SubmissionEvent) => void
  selectedUUID: string
  setUUID: (value: string) => void
}

export const SubmitAssetContext = createContext({} as ISubmitContext)

export const useSubmitContext = (): ISubmitContext => {
  const [submission, setSubmission] = useState<SubmissionEvent>(SubmissionEvent.IDLE)
  const [selectedUUID, setUUID] = useState<string>('')

  return { submission, setSubmission, selectedUUID, setUUID }
}

interface IChangeContext {
  changeUuid: string
  setChangeUuid: (vlaue: string) => void
}

export const ChangeFileContext = createContext({} as IChangeContext)

export const useChangeContext = (): IChangeContext => {
  const [changeUuid, setChangeUuid] = useState<string>('')

  return {
    changeUuid,
    setChangeUuid,
  }
}
