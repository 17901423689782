import { numberFormat } from '@epic-front/common/helpers/utils'
import classNames from 'classnames'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'

type StatisticsWidgetProps = {
  textClass?: string
  icon?: string
  title: string
  description?: string
  stats?: number | null
  detailsText?: string
  trend?: {
    textClass?: string
    icon?: string
    value?: string
    time?: string
  }
  link?: string
}

/**
 * Statistics widget
 */
const StatisticsWidget = (props: StatisticsWidgetProps): JSX.Element => {
  const textClass = props.textClass || ''

  return (
    <Card className={classNames('widget-flat h-100 mb-0')}>
      <Card.Header className="flex-grow-1 d-flex gap-3 py-3 justify-content-between">
        <div className="d-flex flex-column">
          <h4 className={classNames('m-0', textClass)} title={props.description || props.title}>
            {props.title}
          </h4>
          {props.link ? (
            <>
              <Link to={props.link} className="btn-link p-0 align-self-start">
                Show More
              </Link>
            </>
          ) : (
            <></>
          )}
        </div>
        {props.icon && (
          <div className="float-end">
            <i className={classNames(props.icon, 'd-block  widget-icon bg-primary text-white')} />
          </div>
        )}
      </Card.Header>
      <Card.Body className="flex-grow-0 d-flex gap-2 align-items-center">
        <h3 className={classNames('m-0', props.textClass ? props.textClass : null)}>
          {props.stats ? numberFormat(props.stats, undefined, undefined, ',') : '-'}
        </h3>
        <div>{props.detailsText && <div className="badge badge-status status-info fs-6">{props.detailsText}</div>}</div>
        {props.trend && (
          <p className={classNames('mb-0', textClass)}>
            <span className={classNames(props.trend.textClass, 'me-2')}>
              <i className={classNames(props.trend.icon)} /> {props.trend.value} {props.link}
            </span>
            <span className="text-nowrap">{props.trend.time}</span>
          </p>
        )}
      </Card.Body>
    </Card>
  )
}

export default StatisticsWidget
